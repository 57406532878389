import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  getItem,
  validateEmail,
  validateOnBlur,
  validatePhoneNumber,
} from '../../util/functions'
// import tableTick from '../../buildAssets/compare-table/table-tick.svg'
import Tick from '../../buildAssets/compliance/tick.svg'
import Button from '../shared/Button/Button'
import NumberInput from '../shared/NumberInput'
import Input from '../shared/Input'
import queryString from 'query-string'
import theme from '../styling/theme'
import { EmailInput } from './FormInputs/EmailInput'
import { PhoneNumberInput } from './FormInputs/PhoneNumberInput'
import { useLocalStorage } from '../../util/useLocalStorage'
import ErrorMessage from '../shared/ErrorMessage'

export const LandingPageHeaderFormBookDemo = () => {
  const [landingPageUrl, setLandingPageUrl] = useLocalStorage(
    'landing-page-url',
    ''
  )
  const [formId, setFormId] = useState('')
  const [fullName, setfullName] = useState('')
  const [fullNameError, togglefullNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [ipAddress, setIpAddress] = useState('');
  const { REACT_APP_HUBSPOT_KEY } = process.env;

  const [success, setSuccess] = useState(false)
  const [isEmailValidated, setIsEmailValidated] = useState(false)
  const [isPhoneNumberValidated, setIsPhoneNumberValidated] = useState(false)

  const [numberOfEmployees, setNumberOfEmployees] = useState<number>()
  const [numberOfEmployeesError, toggleNumberOfEmployeesError] = useState<boolean>()



  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (!isBrowser) {
      return
    }
    const { full_name } = queryString.parse(location.search)
    if (full_name !== undefined) {
      setSuccess(true)
    }
  }, [])


  useEffect(() => {
    setFormId(landingPageUrl.replaceAll('/', '-'));

    fetch("https://geolocation-db.com/json/")
      .then(response => response.json())
      .then(data => {setIpAddress(data.IPv4);});
  }, []);

  const submitToHubspot = async () => {
    const hubspotCookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const nameSplit = fullName.split(' ');
    const firstName = nameSplit[0];
    const lastName = nameSplit[nameSplit.length - 1];
    const pageName = "LPHF " + landingPageUrl;

    var hsForm = "";

    if (window.location.href.includes("bobsbusiness.co.uk")){
      hsForm = "f037c231-3349-475c-a4a9-a8e6e5f951c5";
      //hsForm = "20d0a59a-0ebc-4f04-9d70-3bab8fe255f9";
    } else {
      hsForm = "20d0a59a-0ebc-4f04-9d70-3bab8fe255f9";
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + REACT_APP_HUBSPOT_KEY);
    myHeaders.append("Content-Type", "application/json");

    const pre = {
        "fields": [
          {
            "objectTypeId": "0-1",
            "name": "email",
            "value": email
          },
          {
            "objectTypeId": "0-1",
            "name": "firstname",
            "value": firstName
          },
          {
            "objectTypeId": "0-1",
            "name": "lastname",
            "value": lastName
          },
          {
            "objectTypeId": "0-1",
            "name": "phone",
            "value": phoneNumber
          },
          {
            "objectTypeId": "0-1",
            "name": "number_of_users",
            "value": numberOfEmployees
          },
          {
            "objectTypeId": "0-1",
            "name": "bb_website_form_identifier",
            "value": pageName
          }
        ],
        "context": {
          "hutk": hubspotCookieValue,
          "ipAddress": ipAddress,
          "pageUri": landingPageUrl,
          "pageName": pageName
        },
        "legalConsentOptions": {
          "consent": {
            "consentToProcess": true,
            "text": "I agree to allow Bob's Business to store and process my personal data.",
            "communications": [
              {
                "value": true,
                "subscriptionTypeId": 999,
                "text": "I agree to receive marketing communications from Bob's Business."
              }
            ]
          }
        }
    }
    
    const raw = JSON.stringify(pre);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
    };
    const endpoint = "https://api.hsforms.com/submissions/v3/integration/submit/5625015/" + hsForm;

    fetch(endpoint, requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(result);
          const newuri = window.location.href + "?full_name=" + encodeURIComponent(fullName);
          window.location.href = newuri;
        })
        .catch(error => console.log('error', error));
    }

  const validateFullName = () => {
    if (fullName.length < 1 || !containsWhitespace(fullName)) {
      togglefullNameError(true)
      // setDisabled(true)
    } else {
      togglefullNameError(false)
    }
  }

  const checkValidateEmployees = () => {
    if (numberOfEmployees > 0 && numberOfEmployees < 2300000) {
      toggleNumberOfEmployeesError(false);
    } else {
      toggleNumberOfEmployeesError(true);
      return
    }
  }

  function containsWhitespace(str : string) {
    return /\s/.test(str);
  }

  return (
    <Form
      name={'header-four-book-demo'}
      method="GET"
      id={`header-component-book-demo--${landingPageUrl && formId}`}
      // id="header-four-book-demo"
    >
      {success ? (
        <div>
          <TickImage src={Tick} />
          <br />
          <ThankYouTitle>
            Thank you! We've received your demo request.
          </ThankYouTitle>
          <br />
          <p>You'll receive an email confirmation in your inbox in a few moments, followed by a message from one of our experts. Enquiries submitted outside of working hours will be answered on the next working day.</p>
        </div>
      ) : (
        <>
          <FormTitle>Get a personalised demo</FormTitle>
          <FormMessage>Discover what Bob's Business can do for your business.</FormMessage>
          <Input
            value={fullName}
            onChange={setfullName}
            label={'Full name*'}
            isValidated={fullName.length > 0}
            hasError={fullNameError}
            errorTitle="Please enter your full name."
            onBlur={() =>
              validateFullName()
            }
            id="fullName"
            name="fullName"
          />
          <EmailInput
            validated={isEmailValidated}
            setValidated={setIsEmailValidated}
            onEmailChange={(e) => setEmail(e)}
          />

          <PhoneNumberInput
            validated={isPhoneNumberValidated}
            setValidated={setIsPhoneNumberValidated}
            onPhoneNumberChange={(e) => setPhoneNumber(e)}
          />

          <NumberInput
            onChange={setNumberOfEmployees}
            label={'Number of Employees*'}
            isValidated={!numberOfEmployees}
            hasError={numberOfEmployeesError}
            errorTitle="Please enter a valid number of employees."
            onBlur={() =>
              checkValidateEmployees()
            }
            id="employees"
            name="employees"
            type="number"
          />

          <br />
          <div onClick={(e) => {
            if (isEmailValidated && isPhoneNumberValidated && !fullNameError && !numberOfEmployeesError && numberOfEmployees){
              submitToHubspot();
            }
          }}>
            <Button
              inactive={
                !isEmailValidated || !isPhoneNumberValidated || fullNameError || !(fullName.length>0) || !numberOfEmployees || numberOfEmployeesError
              }
            >Submit</Button>
          </div>

          <Tag>
            We need to collect your information to respond to your request. You
            can read more about what we do with the information we collect and
            how we use it in our
            <Link to={''}> privacy policy</Link>
          </Tag>
        </>
      )}
    </Form>
  )
}

const Form = styled.form`
  background: ${theme.background.grey};
  width: 100%;
  padding: 50px 20px;
  border-radius: 5px;
  z-index: 50;
  top: -120px;
  @media (min-width: 1200px) {
    position: absolute;
    padding: 50px 50px;
    top: 50px;
  }
`
const FormTitle = styled.h4``

const FormMessage = styled.p``

const BtnContainer = styled.div`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const Tag = styled.p`
  margin-top: 26px;
  color: #5e7177;
`

const ThankYouTitle = styled.h4``

const TickImage = styled.img`
  width: 125px;
  height: 125px;
`
