import React from 'react'
import styled from 'styled-components'
import SwiperCore, { Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import MainGrid from './styling/MainGrid'
import theme from './styling/theme'
import arr from '../images/link-arrow.svg'
import arrHovered from '../images/link-arrow-red.svg'

const IsolatedArticlesGrid = ({ data }: any) => {
  const articles = [
    {
      articleTitle: data.attrs['article-one-title'],
      articleUrl: data.attrs['article-one-link'],
    },
    {
      articleTitle: data.attrs['article-two-title'],
      articleUrl: data.attrs['article-two-link'],
    },
    {
      articleTitle: data.attrs['article-three-title'],
      articleUrl: data.attrs['article-three-link'],
    },
    {
      articleTitle: data.attrs['article-four-title'],
      articleUrl: data.attrs['article-four-link'],
    },
  ]

  return (
    <ContainerArticles>
      <MainGrid>
        <Grid>
          {articles.map(
            (article) =>
              article.articleTitle && (
                <ArticleCard>
                  <Title href={article.articleUrl}>
                    {article.articleTitle}
                  </Title>
                </ArticleCard>
              )
          )}
        </Grid>
        <SwiperContainer id="articles">
          <Swiper
            className="swiper-container"
            slidesPerView={2}
            navigation
            scrollbar={
              ({ draggable: true },
              { el: '.swiper-scrollbar' },
              { snapOnRelease: true })
            }
          >
            <SwipperWrapper
              id="carousel-drag-container"
              className="swiper-wrapper"
            >
              {articles.map((article, i) => {
                return (
                  <SwiperSlide
                    key={i+"article-card-2"}
                    className="carousel-drag-slide"
                    style={{ margin: '0 15px' }}
                  >
                    <ArticleCard>
                      <Title href={article.articleUrl}>
                        {article.articleTitle}
                      </Title>
                    </ArticleCard>
                  </SwiperSlide>
                )
              })}
            </SwipperWrapper>
          </Swiper>
        </SwiperContainer>
      </MainGrid>
    </ContainerArticles>
  )
}

export default IsolatedArticlesGrid

const ContainerArticles = styled.section`
  padding: 50px 0;

  .swiper-container {
    overflow: hidden;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    padding: 43px 40px 30px;
    background: white;
    width: 360px !important;
    height: auto;
    transition: transform 1s ease;
    margin-right: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      width: 320px !important;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: 70%;
    background: ${theme.grey.scroll};
    left: 100px;
    bottom: 15px !important;
    @media screen and (min-width: 768px) {
      width: 85%;
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${theme.brand.blue};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 18px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 12px !important;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 40px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: url(${arr});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 30px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background: url(${arrHovered});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 30px;
  }
  .swiper-button-disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
`

const Grid = styled.div`
  color: white;
  display: none;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    display: grid;
  }
`

const ArticleCard = styled.div`
  border-top: 3px solid black;
  padding: 30px 0;
`

const Title = styled.a`
  color: black !important;
  font-weight: 700;
`
const SwiperContainer = styled.div`
  cursor: grab;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  display: block;

  @media (min-width: 1200px) {
    display: none;
  }
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
`
