import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import { appendUrlPartnerTracking } from '../../util/url'
import Button from '../shared/Button/Button'

import check from '../../buildAssets/compliance/tick.svg'
import logo1 from '../../buildAssets/logos/bespoke/logo-bespoke.png'

import { graphql,useStaticQuery } from 'gatsby'



interface BespokePricingProps {
  ctaUrl1: string
  ctaText1: string
  ctaUrl2: string
  ctaText2: string
  heading: string
  body: string
  item1: string
  item2: string
  item3: string
}

const BespokePricing: FunctionComponent<BespokePricingProps> = ({
  ctaUrl1,
  ctaText1,
  ctaUrl2,
  ctaText2,
  heading,
  body,
  item1,
  item2,
  item3,
}) => {
  return (
    <MainGrid>
      <Wrapper>
        <LeftGrid>
          <LogoImg src={logo1} alt="logo"></LogoImg>
          <h4>{heading}</h4>
          <p>{body}</p>
        </LeftGrid>
        <RightGrid>
          <RightContent>
            <Icon src={check} alt="check"></Icon>
            <Text>{item1}</Text>
          </RightContent>

          <RightContent>
            <Icon src={check} alt="check"></Icon>
            <Text>{item2}</Text>
          </RightContent>

          <RightContent>
            <Icon src={check} alt="check"></Icon>
            <Text>{item3}</Text>
          </RightContent>

          <ButtonContainer>
           <Button href={appendUrlPartnerTracking(ctaUrl1)}>{ctaText1}</Button>
            <Gap/>
            {ctaUrl2 && (
              <Button href={appendUrlPartnerTracking(ctaUrl2)} secondary>
                {ctaText2}
              </Button>
            )}
          </ButtonContainer>
        </RightGrid>
      </Wrapper>
    </MainGrid>
  )
}

export default BespokePricing

const Wrapper = styled.div`
  display: flex;
  margin-top: 100px;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 55px 65px;
  margin-bottom: 54px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 55px 20px;
  }
`

const LeftGrid = styled.div`
  width: 50%;
  padding-right: 20px;

  & p {
    margin: 10px 0;
  }
  & h4 {
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RightGrid = styled.div`
  width: 50%;
  flex-direction: column;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const LogoImg = styled.img`
  width: 100px;
  height: auto;
`

const RightContent = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`

const Icon = styled.img`
  min-width: 40px;
  height: auto;
  @media (max-width: 768px) {
    min-width: 30px;
  }
`

const Text = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  
  & Button {
    margin: 10px;
  }
`
const Gap = styled.div`

width:10px;
`
