import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import SmallGrid from '../styling/SmallGrid'
import VerticalPadding from '../styling/VerticalPadding'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
interface Card {
  icon: string
  sections: Element[]
}

interface Item {
  tag: string
  heading: string
  body: string
  cards: Array<Card>
}
interface IWhiteCards {
  background: boolean
  heading: string
  tag: string
  subheading: string
  contentLeft: string
  contentRight: string
}

const WhiteCardsLazyBlock = ({
  background,
  heading,
  tag,
  subheading,
  contentLeft,
  contentRight,
}: IWhiteCards) => {
  // const parser = new jsdom.JSDOM(contentLeft)
  // const htmlDoc = parser.parseFromString(contentLeft, 'text/html')
  return (
    <Container>
      <VerticalPadding>
        <MainGrid>
          <SmallGrid
            centerText
            noPaddingMobile
            noPaddingTablet
            noPaddingDesktop
          >
            {tag && <Tag className="small-p">{tag}</Tag>}
            {heading && <Heading>{heading}</Heading>}
            {subheading && <Body>{subheading}</Body>}
          </SmallGrid>
          <Grid>
            {contentLeft && (
              <Card>
                <RichTextContainer richText={contentLeft} />
              </Card>
            )}
            {contentRight && (
              <Card>
                <RichTextContainer richText={contentRight} />
              </Card>
            )}
          </Grid>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default WhiteCardsLazyBlock

const Container = styled.section`
  background: ${theme.background.product};
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  font-weight: 300;
  margin-bottom: 17px;
`
const Heading = styled.h2`
  margin-bottom: 9px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`
const Body = styled.p`
  font-weight: 300;
`
const Grid = styled.div`
  margin-top: 43px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  @media (min-width: 768px) {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: white;
  border-radius: 4px;
  padding: 47px 25px 40px;
  @media (min-width: 1024px) {
    padding: 85px 64px 70px;
  }
`

const Icon = styled.img`
  height: 75px;
`

const Section = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.grey.product};
  }
  padding: 26px 0 6px;

  @media (min-width: 1024px) {
    &:not(:last-of-type) {
      padding: 34px 0 14px;
    }

    &:last-of-type {
      padding: 34px 0 0;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 16px;
  }

  & p {
    font-weight: 300;
    padding-bottom: 20px;
  }
`
