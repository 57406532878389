import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import { Link } from 'gatsby'
import arr from '../../images/link-arrow.svg'
import { appendUrlPartnerTracking } from '../../util/url'

interface ICard {
  title?: string
  body?: string
  tag?: string
  image?: any
  alt?: string
  ctaUrl?: string
  icon?: any
}

interface SmallCardsGridProps {
  cardsContent: Array<ICard>
  columns?: number
  leftAlign?: boolean
}

const SmallCardsGrid: FunctionComponent<SmallCardsGridProps> = ({
  cardsContent,
  columns,
  leftAlign,
}) => {
  // console.log(cardsContent)

  return (
    <Container>
      <MainGrid>
        {cardsContent.length > 0 && (
          <Grid columns={columns ? columns : 3}>
            {cardsContent.map((item, key) => {
              let imgJson = null
              let icJson = null
              if (item.image) {
                const img = decodeURIComponent(item.image)
                imgJson = JSON.parse(img)
              }
              if (item.icon) {
                const ic = decodeURIComponent(item.icon)
                icJson = JSON.parse(ic)
              }
              // console.log(imgJson)
              return (
                <div key={key}>
                  {item.title && (
                    <Card key={key} leftAlign={leftAlign}>
                      {imgJson && (
                        <CardImg src={imgJson.url} alt={imgJson.alt} />
                      )}
                      {item.title && <CardTitle>{item.title}</CardTitle>}
                      {item.tag && <CardTitle>{item.tag}</CardTitle>}
                      {item.body && <CardBody>{item.body}</CardBody>}
                      {icJson && (
                        <SupportIcon src={icJson.url} alt={icJson.alt} />
                      )}
                      {item.ctaUrl && (
                        <LinkContainer
                          to={appendUrlPartnerTracking(item.ctaUrl)}
                        >
                          <b>Learn more </b>
                          <Arrow src={arr} alt="Arrow link" />
                        </LinkContainer>
                      )}
                    </Card>
                  )}
                </div>
              )
            })}
          </Grid>
        )}
      </MainGrid>
    </Container>
  )
}

export default SmallCardsGrid

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`

interface IGrid {
  columns: number
}

const Grid = styled.div<IGrid>`
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    height: 100%;
    margin-bottom: 7.4rem;
  }

  @media (min-width: 1120px) {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }
`
interface ICardProps {
  leftAlign?: boolean
}
const Card = styled.div<ICardProps>`
  padding: 30px 20px 20px;

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.leftAlign ? 'left' : 'center')};
  justify-content: flex-start;

  width: 100%;
  text-align: ${(props) => (props.leftAlign ? 'left' : 'center')};
  background: ${theme.background.grey};
  border-bottom: 3px solid ${theme.text.main};

  @media (min-width: 768px) {
    padding: 50px 30px 30px;
    height: 100%;
  }
`

const CardTitle = styled.h6`
  margin-bottom: 8px !important;
  @media (min-width: 768px) {
    margin-bottom: 15px !important;
  }
`

const CardBody = styled.p`
  font-weight: 300;

  color: ${theme.grey.inputHover};
`

const CardImg = styled.img`
  max-width: 200px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
`

const TopText = styled.div`
  text-align: center;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin-bottom: 4.7rem;
  }
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 17px;
  }
`

const LinkContainer = styled(Link)`
  margin-bottom: 0px;
  &,
  &:link,
  &:visited {
    width: 100%;
    padding: 20px 0px 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 18px;
    color: ${theme.brand.blue};
    display: flex;
    align-items: right;
    justify-content: center;
    transition: background 0.2s ease;
    @media (min-width: 768px) {
    }
  }

  /* &:hover,
  &:active {
    background: ${theme.brand.blue10};
    color: ${theme.brand.blue};
    text-decoration: none;
  } */
`
const Arrow = styled.img`
  width: 25px;
  height: 20px;
  margin-left: 10px;
`
const SupportIcon = styled.img`
  display: none;
  margin-bottom: 12px;
  max-width: 60%;
  @media (min-width: 768px) {
    display: flex;
    max-height: 37px;
    margin: 22px auto 0;
    margin-bottom: 14px;
  }
`
