import ringDot from '../../../images/cyber-security-circles/ca-ring-dot.svg'
import ring1 from '../../../images/cyber-security-circles/ca-ring1.svg'
import ring2 from '../../../images/cyber-security-circles/ca-ring2.svg'
import ring2a from '../../../images/cyber-security-circles/ca-ring2a.svg'
import ring3 from '../../../images/cyber-security-circles/ca-ring3.svg'
import ring3a from '../../../images/cyber-security-circles/ca-ring3a.svg'
import ring3b from '../../../images/cyber-security-circles/ca-ring3b.svg'
import ring4 from '../../../images/cyber-security-circles/ca-ring4.svg'
import ring4a from '../../../images/cyber-security-circles/ca-ring4a.svg'
import ring4b from '../../../images/cyber-security-circles/ca-ring4b.svg'
import ring5 from '../../../images/cyber-security-circles/ca-ring5.svg'
import ring5a from '../../../images/cyber-security-circles/ca-ring5a.svg'
import ring5b from '../../../images/cyber-security-circles/ca-ring5b.svg'
import ring5c from '../../../images/cyber-security-circles/ca-ring5c.svg'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export const RotatingRingsAnimation: FunctionComponent = ({ children }) => {
  return (
    <ImageContainer>
      <ImgDot src={ringDot} animate alt={'ring'}/>
      <ImgDot src={ring1} alt={'ring'} />
      <Img src={ring2} alt={'ring'}/>
      <Img src={ring2a}alt={'ring'} animate delay="1.2s" timing="2s" />
      <Img src={ring3} alt={'ring'}/>
      <Img src={ring3a}alt={'ring'} animate delay="0.5s" timing="2.6s" />

      <Img src={ring3b}alt={'ring'} animate delay="0.5s" timing="3s" />
      <Img src={ring4} alt={'ring'}/>
      <Img src={ring4a}alt={'ring'} animate delay="0.8s" timing="3.6s" />
      <Img src={ring4b} alt={'ring'}animate delay="0.8s" timing="3s" />
      <Img src={ring5} alt={'ring'}/>
      <Img src={ring5a}alt={'ring'} animate delay="1.8s" timing="4.2s" />
      <Img src={ring5b} alt={'ring'}animate delay="1.8s" timing="4s" />
      <Img src={ring5c}alt={'ring'} animate delay="1.8s" timing="4.6s" />
      <ImgHiddenOnlyForHeight src={ring5c} />
    </ImageContainer>
  )
}
const ImageContainer = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto 24px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 50%;
    margin: 0 auto;
  }
`

const ImgHiddenOnlyForHeight = styled.img`
  overflow: hidden;
  visibility: hidden;
`

const Img = styled.img<IImage>`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${(props) =>
    props.animate
      ? `animateBorderCyberAwarenessHeader ${props.timing} ease infinite alternate ${props.delay}`
      : 'none'};
`
const ImgDot = styled(Img)<IImage>`
  backface-visibility: hidden;
  animation: ${(props) =>
    props.animate ? 'pulsate 1s ease-in-out infinite alternate' : 'none'};
`

interface IImage {
  animate?: boolean
  delay?: string
  timing?: string
}
