import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
interface ImageTextProps {
  Image?: any
  Content: string
  LeftImage?: boolean
  blueText?: string
}

const ImageText: FunctionComponent<ImageTextProps> = ({
  Content,
  Image,

  LeftImage,
}) => {
  var imageJson;

  if (Image != undefined){
    const imageDecoded = decodeURIComponent(Image)
      imageJson = JSON.parse(imageDecoded)

  }
  return (
    <MainGrid>
      <Container LeftImage={LeftImage}>
        <ImageContainer>
          {Image &&(
            <MainImage src={imageJson.url} alt={"img"}></MainImage>
          )}
        </ImageContainer>

        <Col>
          <RichTextContainer richText={Content} />
        </Col>
      </Container>
    </MainGrid>
  )
}

export default ImageText

interface ImageProps {
  LeftImage?: boolean
}

const Container = styled.div<ImageProps>`
  background: linear-gradient(
    to ${(props) => (props.LeftImage ? 'left' : 'right')},
    ${theme.background.grey} 75%,
    white 25%
  );
  padding: 50px 0 50px;
  display: flex;
  flex-direction: ${(props) => (props.LeftImage ? 'row' : 'row-reverse')};
  margin: 0 0 50px;

  @media (max-width: 768px) {
    padding: 1rem 0 1rem;
    margin: 0 0 20px;
    flex-direction: ${(props) =>
      props.LeftImage ? 'column' : 'column-reverse'};
    background: linear-gradient(
      to ${(props) => (props.LeftImage ? 'top' : 'bottom')},
      ${theme.background.product} 75%,
      white 25%
    );
  }
`

const MainImage = styled.img`
  height: auto;
  width: auto;

  margin: 0 auto;
`
const Col = styled.div`
  width: 50%;
  padding: 80px;
  flex-direction: column;
  //padding-right: 40px;
  align-items: center;
  vertical-align: center;
  @media (max-width: 1024px) {
    padding: 40px 40px 0px 50px;
  }
  @media (max-width: 768px) {
    padding: 40px 10% 20px 10%;
    margin-bottom: 24px;
    width: 100%;
  }
`
const ImageContainer = styled.div<ImageProps>`
  display: flex;
  width: 50%;

  flex-direction: column;
  //padding-right: 40px;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  @media (max-width: 768px) {
    width: 75%;
    align-items: ${(props) => (props.LeftImage ? 'left' : 'right')};
    justify-items: ${(props) => (props.LeftImage ? 'left' : 'right')};
  }
  @media (max-width: 576px) {
    margin-bottom: 24px;
    width: 100%;
    align-items: ${(props) => (props.LeftImage ? 'left' : 'right')};
  }
`
