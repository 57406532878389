import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'

const TrainingCards = ({ data }: any) => {
  const image1 = data.attrs['card-one-image']
  const image2 = data.attrs['card-two-image']
  const image3 = data.attrs['card-three-image']

  const image1Raw = decodeURIComponent(image1)
  const image1Json = JSON.parse(image1Raw)

  const image2Raw = decodeURIComponent(image2)
  const image2Json = JSON.parse(image2Raw)

  const image3Raw = decodeURIComponent(image3)
  const image3Json = JSON.parse(image3Raw)

  const cardsContent = [
    {
      image: image1Json,
      title: data.attrs['card-one-title'],
      content: data.attrs['card-one-content'],
    },
    {
      image: image2Json,
      title: data.attrs['card-two-title'],
      content: data.attrs['card-two-content'],
    },
    {
      image: image3Json,
      title: data.attrs['card-three-title'],
      content: data.attrs['card-three-content'],
    },
  ]

  return (
    <MainGrid>
      <Grid>
        {cardsContent.map((card,index) => (
          <Card key={card.image.url + index}>
            <ContentImgWrapper>
              <ContentImg src={card.image.url} alt={card.image.alt} />
            </ContentImgWrapper>
            <ContentWrapper>
              <ContentTitle>{card.title}</ContentTitle>
              <p>{card.content}</p>
            </ContentWrapper>
          </Card>
        ))}
      </Grid>
    </MainGrid>
  )
}

export default TrainingCards

const Grid = styled.div`
  display: grid;
  justify-items: stretch;
  align-items: stretch;
  padding: 50px 0;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 30px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const Card = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
`
const ContentImgWrapper = styled.div`
  width: 100%;
  margin: 0;
`
const ContentImg = styled.img`
  width: 100%;
  margin-bottom: -8px;
`

const ContentWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 40px 30px 75px;
  text-align: center;
  flex: 1;
`
const ContentTitle = styled.h6`
  margin-bottom: 10px;
`
