import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import LargeGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import { appendUrlPartnerTracking } from '../../util/url'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'

interface ITabColumnsProps {
  title1?: string
  title2?: string
  title3?: string
  title4?: string
  leftColumn1?: string
  leftColumn2?: string
  leftColumn3?: string
  leftColumn4?: string
  rightColumn1: string
  rightColumn2: string
  rightColumn3: string
  rightColumn4: string
  image1?: any
  image2?: any
  image3?: any
  image4?: any
  ctaText1?: string
  ctaUrl1?: string
  ctaText2?: string
  ctaUrl2?: string
}

type AvailableTabs = 'GDPR' | 'ISO1' | 'ISO2' | 'PCI'

type ViewTabs = {
  displayName: string
  code: AvailableTabs
}

const TabbedInfo: FunctionComponent<ITabColumnsProps> = ({
  title1,
  title2,
  title3,
  title4,
  leftColumn1,
  leftColumn2,
  leftColumn3,
  leftColumn4,
  rightColumn1,
  rightColumn2,
  rightColumn3,
  rightColumn4,
  image1,
  image2,
  image3,
  image4,
  ctaText1,
  ctaUrl1,
  ctaText2,
  ctaUrl2,
}) => {
  const [selectedView, setSelectedView] = useState<AvailableTabs>('GDPR')
  TabbedInfo
  const availableTabs: ViewTabs[] = [
    { displayName: title1, code: 'GDPR' },
    { displayName: title2, code: 'ISO1' },
    { displayName: title3, code: 'ISO2' },
    { displayName: title4, code: 'PCI' },
  ]

  function renderSelectedRightView(): ReactNode | null {
    switch (selectedView) {
      case 'GDPR':
        return <RichTextContainer richText={rightColumn1} />
      case 'ISO1':
        return <RichTextContainer richText={rightColumn2} />
      case 'ISO2':
        return <RichTextContainer richText={rightColumn3} />
      case 'PCI':
        return <RichTextContainer richText={rightColumn4} />
      default:
        return <RichTextContainer richText={rightColumn1} />
    }
  }
  let image1Json = null
  let image2Json = null
  let image3Json = null
  let image4Json = null
  if (image1) {
    const image1Decoded = decodeURIComponent(image1)
    image1Json = JSON.parse(image1Decoded)
  }

  if (image2) {
    const image2Decoded = decodeURIComponent(image2)
    image2Json = JSON.parse(image2Decoded)
  }

  if (image3) {
    const image3Decoded = decodeURIComponent(image3)
    image3Json = JSON.parse(image3Decoded)
  }

  if (image4) {
    const image4Decoded = decodeURIComponent(image4)
    image4Json = JSON.parse(image4Decoded)
  }

  function renderSelectedLeftView(): ReactNode | null {
    switch (selectedView) {
      case 'GDPR':
        return (
          <LeftInfo>
            <SubText>{leftColumn1}</SubText>
            {image1 && <GovImg src={image1Json.url} alt="hmrc image" />}
          </LeftInfo>
        )
      case 'ISO1':
        return (
          <LeftInfo>
            <SubText>{leftColumn2}</SubText>
            {image2 && <GovImg src={image2Json.url} alt="hmrc image" />}
          </LeftInfo>
        )
      case 'ISO2':
        return (
          <LeftInfo>
            <SubText>{leftColumn3}</SubText>
            {image3 && <GovImg src={image3Json.url} alt="hmrc image" />}
          </LeftInfo>
        )
      case 'PCI':
        return (
          <LeftInfo>
            <SubText>{leftColumn4}</SubText>
            {image4 && <GovImg src={image4Json.url} alt="hmrc image" />}
          </LeftInfo>
        )
      default:
        return null
    }
  }

  return (
    <LargeGrid>
      <Container>
        <TabBarContainer>
          <TabBar>
            {availableTabs.map((tab) => {
              return selectedView === tab.code ? (
                <TabActive
                  key={tab.code}
                  onClick={() => setSelectedView(tab.code)}
                >
                  {tab.displayName}
                </TabActive>
              ) : (
                <Tab key={tab.code} onClick={() => setSelectedView(tab.code)}>
                  {tab.displayName}
                </Tab>
              )
            })}
          </TabBar>
        </TabBarContainer>

        <Grid>
          <ColLeft>
            {renderSelectedLeftView()}

            {ctaUrl1 && (
              <ButtonContainer>
                <Button href={appendUrlPartnerTracking(ctaUrl1)}>
                  {ctaText1}
                </Button>
               
                {ctaUrl2 && (
                <Button href={appendUrlPartnerTracking(ctaUrl2)} secondary>
                  {ctaText2}
                </Button>
            )}
              </ButtonContainer>
             )}
          </ColLeft>

          <ColRight>{renderSelectedRightView()}</ColRight>
        </Grid>
      </Container>
    </LargeGrid>
  )
}

export default TabbedInfo

const Container = styled.div`
  padding: 40px 0px 50px;

  @media (min-width: 768px) {
    padding: 8rem 6rem 6.2rem;
  }
`
const Grid = styled.div`
  margin-top: 24px;
  @media (min-width: 768px) {
    display: flex;
  }
`

const Tab = styled.h5`
  color: ${theme.grey.inputHover};
  width: 100%;
  min-width: 70px;
  cursor: pointer;
  font-weight: 300;
  // margin-bottom: 14px;
  text-align: center;
  padding-bottom: 6px;
`

const TabActive = styled(Tab)`
  color: ${theme.brand.blue};
  // font-size: 1.6rem;

  font-weight: Bold;
  border-bottom: 2px solid ${theme.brand.blue};
`

const LeftInfo = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: left;
  text-align: left;
  @media (min-width: 764px) {
    padding-left: 40px;
  }
`
const SubText = styled.p`
  font-size: 1.6rem;
  color: ${theme.text.main};
`

const GovImg = styled.img`
  margin: 18px 0;
  max-width: 80%;
`

const ColLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const ColRight = styled(ColLeft)`
  width: 60%;
  padding-left: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  align-items: left;
  width: 100%;
  padding-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  & a:first-of-type {
    margin-bottom: 10px;
  }
  @media (min-width: 768px) {
    padding-left: 40px;
    width: 70%;
    flex-direction: column;
  }
`

const TabBarContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  @media (min-width: 768px) {
    overflow-x: hidden;
  }
`
const TabBar = styled.div`
  margin: 0 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 1px solid #9b9b9b;
  @media (max-width: 768px) {
    margin: 0 auto 10px;
    min-width: 400px;
    text-align: left;
  }
`
