import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface VerticalPaddingProps {
  paddingTopDesktop?: number
}
const VerticalPadding: FunctionComponent<VerticalPaddingProps> = ({
  children,
  paddingTopDesktop,
}) => {
  return <Container paddingTopDesktop={paddingTopDesktop}>{children}</Container>
}

export default VerticalPadding

const Container = styled.div<VerticalPaddingProps>`
  padding: 50px 0;

  @media screen and (min-width: 1024px) {
    padding: 10rem 0;
    padding: ${(props) =>
      props.paddingTopDesktop
        ? `${props.paddingTopDesktop}rem 0 10rem`
        : '10rem 0'};
  }
`
