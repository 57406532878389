import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

interface WireLineInterface {
  direction: 'up' | 'down'
}

const WireLineSVG = ({ direction }: WireLineInterface) => {
  const downwards = useSpring({
    from: { strokeDashoffset: 700, strokeDasharray: 70 },
    to: { strokeDashoffset: 0 },
    loop: true,
    config: { duration: 4000 },
  })

  const upwards = useSpring({
    from: { strokeDashoffset: 0, strokeDasharray: 70 },
    to: { strokeDashoffset: 700 },
    loop: true,
    config: { duration: 5000 },
  })

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={5}
    >
      {/* background path */}
      <path d="M 3,0 V 350" stroke="#ececec" strokeWidth={3} />
      {/* foreground path */}
      <animated.path
        d="M 3, 0 V 350"
        stroke="#00A3C7"
        strokeWidth={3}
        style={
          direction === 'up'
            ? upwards
            : direction === 'down'
            ? downwards
            : upwards
        }
      />
    </SVG>
  )
}

export default WireLineSVG

const SVG = styled.svg`
  // height: 200px;
  @media (min-width: 1200px) {
    height: 350px;
  }
`
