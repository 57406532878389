import React, { useState } from 'react'
import styled from 'styled-components'
import TrustBox from '../Header/Trustpilot'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import NCSClogo from '../../images/national-cyber-security-centre-alt.png'
import CCSSlogo from '../../images/crown-commercial-service-supplier-alt.png'
import Input from '../shared/Input'
import Button from '../shared/Button/Button'
import { Link } from 'gatsby'
import {
  getItem,
  validateEmail,
  validateOnBlur,
  validatePhoneNumber,
} from '../../util/functions'
import { LandingPageHeaderFormBookDemo } from './LandingPageHeaderFormBookDemo'

const LandingPageHeaderVTwo = ({ data }: any) => {
  const [firstName, setFirstName] = useState(getItem('firstName'))
  const [firstNameError, togglefirstNameError] = useState(false)
  const [phone, setPhone] = useState(getItem('phone'))
  const [phoneError, togglePhoneError] = useState(false)
  const [email, setEmail] = useState(getItem('email'))
  const [emailError, toggleEmailError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [anythingElse, setAnythingElse] = useState('')

  const submitForm = () => {
    setSuccess(!success)
    // const goToNextScreen = () => {
    //   trackButtonClick(`General Enquiry Form Submission`)
    //   getAuthToken(setToken)
    // }

    let idToScroll = ''

    let firstNameError = false
    let phoneError = false
    let emailError = false

    if (firstName.length < 1) {
      firstNameError = true
      togglefirstNameError(true)
      if (!idToScroll) {
        idToScroll = 'firstName'
      }
    }

    if (!validatePhoneNumber(phone)) {
      phoneError = true
      togglePhoneError(true)
      if (!idToScroll) {
        idToScroll = 'phone'
      }
    }
    if (!validateEmail(email)) {
      emailError = true
      toggleEmailError(true)
      if (!idToScroll) {
        idToScroll = 'email'
      }
    }

    if (!firstNameError && !phoneError && !emailError) {
      const params = {
        firstName,
        phone,
        email,
        // callBackTimePreference,
        anythingElse,
      }

      emailjs
        .send(
          process.env.SERVICE_ID,
          process.env.CLAIM_TEMPLATE_ID,
          params,
          process.env.USER_ID
        )
        .then(
          (result) => {
            window.location.pathname = '/confirmation'
          },
          (error) => {
            console.log(error.text)
          }
        )
    } else {
      if (idToScroll) {
        console.log(idToScroll)
        //scrollPage(idToScroll)
      }
    }
  }

  const headerData = {
    header: data.attrs.header,
    content: data.attrs.content,
    button: {
      label: data.attrs['button-text'],
      link: data.attrs['button-link'],
    },
  }

  const logos = [
    {
      img: NCSClogo,
      alt: 'National Cyber Security Centre',
      url: '#',
    },
    {
      img: CCSSlogo,
      alt: 'Crown Commercial Service Supplier',
      url: '#',
    },
  ]

  return (
    <Container>
      <MainGrid>
        <Grid>
          <ContentWrapper>
            {headerData.header && <Heading>{headerData.header}</Heading>}
            {headerData.content && (
              <div className="LPv2_content">
                <Content
                  dangerouslySetInnerHTML={{
                    __html: headerData.content,
                  }}
                />
              </div>
            )}
            <LogosContainer>
              {logos.map((logo) => (
                <LogoMask href={logo.url}>
                  <Logo src={logo.img} alt={logo.alt} />
                </LogoMask>
              ))}
              <TrustBox darkMode={true} />
            </LogosContainer>
          </ContentWrapper>
          <FormWrapper>
            <FormContainer>
              <Form>
                <LandingPageHeaderFormBookDemo />
              </Form>
            </FormContainer>
          </FormWrapper>
        </Grid>
      </MainGrid>
    </Container>
  )
}

export default LandingPageHeaderVTwo

const Container = styled.div`
  background: ${theme.text.main};
  margin-bottom: 750px;
  @media (min-width: 1200px) {
    margin-bottom: 0px;
  }
`

const Grid = styled.div`
  color: white;
  display: grid;
  grid-template-rows: repeat(2 1fr);
  @media (min-width: 1200px) {
    grid-template-rows: 1;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
  }
`
const ContentWrapper = styled.div``

const Heading = styled.h1`
  margin-top: 50px;
  margin-bottom: 30px;
  color: ${theme.text.white};

  @media (min-width: 1200px) {
    margin-top: 150px;
  }
`
const Content = styled.div`
  color: ${theme.text.white};

  & ul li {
    color: ${theme.text.white};
  }
`

const LogosContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 150px;

  @media (min-width: 1200px) {
    margin-bottom: 220px;
  }
`

const LogoMask = styled.a`
  width: 30%;
  margin-right: 20px
  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    // border: 1px solid #4a4a4a;
    // border-radius: 3px;
    justify-content: center;
  }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`

const FormWrapper = styled.div`
  color: white;
`
const FormContainer = styled.div`
  position: relative;
`

const Form = styled.div`
  // background: ${theme.background.grey};
  width: 100%;
  padding: 50px 20px;
  border-radius: 5px;
  position: absolute;
  top: -120px;
  @media (min-width: 1200px) {
    padding: 50px 50px;
    top: 50px;
  }
`
const FormTitle = styled.h4``

const FormMessage = styled.p``

const BtnContainer = styled.div`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  & a {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    justify-content: center;
  }
`

const Tag = styled.p`
  margin-top: 26px;
  color: #5e7177;
`
