
import ring5c from '../../../images/cyber-security-circles/ca-ring5c.svg'

import dot from '../../../images/compliance-dots/ps-dot.svg'
import innerring from '../../../images/compliance-dots/ps-inner-ring.svg'
import middlering from '../../../images/compliance-dots/ps-middle-ring.svg'
import outerring from '../../../images/compliance-dots/ps-outer-ring.svg'
import outerborder from '../../../images/compliance-dots/ps-outer-border.svg'


import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export const RotatingDotsAnimation: FunctionComponent = ({ children }) => {
  return (
    <ImageContainer>
      <ImgDot src={dot} animate />   
     
      <Img src={innerring} animate delay="1.2s" timing="2s" />
      
      <Img src={middlering} animate delay="0.5s" timing="3s" />
    
      <Img src={outerring} animate delay="0.8s" timing="3.6s" />
           
      <Img src={outerborder} animate delay="1.8s" timing="4.2s" />
     
      <ImgHiddenOnlyForHeight src={ring5c} />
    </ImageContainer>
  )
}
const ImageContainer = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto 24px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 50%;
    margin: 0 auto;
  }
`

const ImgHiddenOnlyForHeight = styled.img`
  overflow: hidden;
  visibility: hidden;
`

const Img = styled.img<IImage>`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${(props) =>
    props.animate
      ? `animateBorderCyberAwarenessHeader ${props.timing} ease infinite alternate ${props.delay}`
      : 'none'};
`
const ImgDot = styled(Img)<IImage>`
  backface-visibility: hidden;
  animation: ${(props) =>
    props.animate ? 'pulsate 1s ease-in-out infinite alternate' : 'none'};
`

interface IImage {
  animate?: boolean
  delay?: string
  timing?: string
}
