import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

import { appendUrlPartnerTracking } from '../../util/url'
import arrow from '../../images/link-arrow.svg'
import MainGrid from '../styling/MainGrid'
import { Link } from 'gatsby'

interface Content {
  prevLink: string
  prevTitle: string
  nextLink: string
  nextTitle: string
}


const CoursePagination: FunctionComponent<Content> = ({
  prevLink,
  prevTitle,
  nextLink,
  nextTitle,

}) => {

  return (
    <MainGrid>

      <PaginationContainer>

        <LeftButton to={prevLink}>
          <BackArrow src={arrow} alt="Arrow" />
          <p >PREVIOUS</p>
          <h3>{prevTitle}</h3>

        </LeftButton>

        <RightButton to={nextLink}>
          <Arrow src={arrow} alt="Arrow" />
          <p>NEXT</p>
          <h3>{nextTitle}</h3>
        </RightButton>

      </PaginationContainer>
    </MainGrid>
  )
}
export default CoursePagination


const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  width: 100%;
 
  background-color: ${theme.background.grey};
  @media (max-width: 768px) {
    flex-direction: column
    
  }
  `

const LeftButton = styled(Link)`
  padding: 56px 80px 80px ;
  border-top: 1px solid ${theme.text.main};
  height: auto;
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction:column;  
  align-items: flex-end;
  text-align: right;
  border-right:  1px solid ${theme.grey.keyline};
  p{
    color:#9B9B9B;
  }
  :hover{
      background-color: ${theme.brand.blue};
      color: white;
        box-shadow: none;
        border:none;
        text-decoration: none;
      h3, p{
        color: white;
        text-decoration: none !important;
        box-shadow: none;
        border:none;
      }
  }
  @media (max-width: 768px) {
   padding: 26px px 40px ;
   height: 50%;
   align-items:flex-start;
   text-align: left;
  }
  `
const RightButton = styled(LeftButton)`
  align-items: flex-start;
  text-align: left;
  border-right:  none;
  border-left:  1px solid ${theme.grey.keyline};
  `

const Arrow = styled.img`
 margin-bottom: 17px;
  width: 50px;
  `
const BackArrow = styled(Arrow)`
    transform: rotate(180deg);
  `

const Direction = styled.p`
  
  :hover{
    color: inherit;
  }
  `