import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'

interface ThreeColumnTextProps {
  tag?: string
  title?: string
  paragraph?: Array<IParagraphs>
}

interface IParagraphs {
  heading: string
  body: string
  link?: string
}

const ThreeColumnText: FunctionComponent<ThreeColumnTextProps> = ({
  title,
  tag,
  paragraph,
}) => {
  return (
    <MainGrid>
      <Container>
        <Grid>
          <Col>
            {tag && <Tag className="small-p">{tag}</Tag>}
            {title && <h4>{title}</h4>}
          </Col>

          <ParagraphCol>
            {paragraph && paragraph.length > 0 && (
              <ParagraphGrid>
                {paragraph.map((item: any, key: number) => {
                  
                  return (
                      item.heading?(
                        
                        <Paragraph key={key}>
                            <h5>{item.heading} </h5>
                            <P>{item.body} </P>
                            {item.link && <RichTextContainer richText={item.link} />}
                        </Paragraph>
                        ):(null)
                  )
                 
                })}
              </ParagraphGrid>
            )}
          </ParagraphCol>
        </Grid>
      </Container>
    </MainGrid>
  )
}

export default ThreeColumnText

const Container = styled.div`
  padding: 40px 0 20px;
  margin-bottom: 0;
  @media (min-width: 768px) {
    padding: 6.5rem 0 1rem;
    margin-bottom: 50px;
    border-bottom: 1px solid ${theme.grey.keyline};
  }
`
const Grid = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const ParagraphGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  margin-bottom: 0px;
  @media (min-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    height: 100%;
    margin-bottom: 3.4rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  font-weight: 300;
  margin-bottom: 14px;
  padding-right: 20px;
`
const Paragraph = styled.div`
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`

const Col = styled.div`
  @media (min-width: 768px) {
    width: 70%;
    margin-right: 3rem;
  }
`
const ParagraphCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

const P = styled.p`
  font-weight: 300;
  color: ${theme.grey.inputHover};

  padding: 10px 10px 10px 0;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  max-width: 610px;

  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: left;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
`
