import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'

import { appendUrlPartnerTracking } from '../../util/url'

import { InPageBot } from "@leadoo/react-bot";

import PricingImg from '../../images/phone-option.png'
import ComplianceLogo from '../../buildAssets/logos/compliance/logo-compliance.png'
import CultureLogo from '../../buildAssets/logos/culture/logo-culture.png'
import PhishingLogo from '../../buildAssets/logos/phishing/logo-phishing.png'

interface IBtn {
  url: string
  title: string
}

interface SmallCardsGridProps {
  pricingIntro: string
  btns: Array<IBtn>
  complianceMonthlyPrice: string
  complianceAnnualPrice: string
  complianceTitle: string
  complianceBody: string
  cultureTitle: string
  cultureBody: string
  phishingTitle: string
  phishingBody: string
}

const PricingCards: FunctionComponent<SmallCardsGridProps> = ({
  pricingIntro,
  complianceMonthlyPrice,
  complianceAnnualPrice,
  complianceTitle,
  complianceBody,
  cultureTitle,
  cultureBody,
  phishingTitle,
  phishingBody,
  btns,
}) => {

  return (
    <Container>
      <MobileWrapper>
          <h1>Pricing</h1>
          <IncludedParagraph>{pricingIntro}.<br /><br /> Not sure which package to go for? use the button below to find out or take a look at our product comparison:</IncludedParagraph>
          <InPageBot code="AQJscoCT" />        
      </MobileWrapper>
      <MainGrid>
        <PriceRow>
          <InfoColumn>
            <DesktopWrapper>
              <h1>Pricing</h1>
              <IncludedParagraph>{pricingIntro}.<br /><br /> Not sure which package to go for? use the button below to find out:</IncludedParagraph>
              <InPageBot code="AQJscoCT" />
            </DesktopWrapper>
            <MobileWrapper2>
              <h2>Products</h2>
            </MobileWrapper2>
          </InfoColumn>
          <PricingColumn>
            <Card>
              <CardImgDiv>
                <CardImg src={ComplianceLogo} />
              </CardImgDiv>
              <CardTitle> {complianceTitle}</CardTitle>
              <CardBody>
                <CardTopSection>{complianceBody}</CardTopSection>
              </CardBody>
              {btns && (
                <ButtonContainer>
                  <A
                    onClick={() =>
                      window.open(
                        appendUrlPartnerTracking(btns[0].url),
                        '_blank'
                      )
                    }
                  >
                    <Button width={'100%'}>{btns[0].title}</Button>
                  </A>
                  <Gap />
                  <Button
                    href={appendUrlPartnerTracking(btns[1].url)}
                    width={'100%'}
                    secondary
                  >
                    {btns[1].title}
                  </Button>
                </ButtonContainer>
              )}
            </Card>
          </PricingColumn>
          <PricingColumn>
            <Card>
              <CardImgDiv>
                <CardImg src={PhishingLogo} />
              </CardImgDiv>
              <CardTitle> {phishingTitle}</CardTitle>
              <CardBody>
                <CardTopSection>{phishingBody}</CardTopSection>
              </CardBody>

              {btns && (
                <ButtonContainer>
                  <Button
                    href={appendUrlPartnerTracking(btns[4].url)}
                    width={'100%'}
                  >
                    {btns[4].title}
                  </Button>

                  <Gap />
                  <Button
                    href={appendUrlPartnerTracking(btns[5].url)}
                    width={'100%'}
                    secondary
                  >
                    {btns[5].title}
                  </Button>
                </ButtonContainer>
              )}
            </Card>
          </PricingColumn>
          <PricingColumn>
            <Card>
              <CardImgDiv>
                <CardImg src={CultureLogo} />
              </CardImgDiv>
              <CardTitle> {cultureTitle}</CardTitle>
              <CardBody>
                <CardTopSection>{cultureBody}</CardTopSection>
              </CardBody>

              {btns && (
                <ButtonContainer>
                  <Button
                    href={appendUrlPartnerTracking(btns[2].url)}
                    width={'100%'}
                  >
                    {btns[2].title}
                  </Button>

                  <Gap />
                  <Button
                    href={appendUrlPartnerTracking(btns[3].url)}
                    width={'100%'}
                    secondary
                  >
                    {btns[3].title}
                  </Button>
                </ButtonContainer>
              )}
            </Card>
          </PricingColumn>
        </PriceRow>
        </MainGrid>  
    </Container>
  )
}

export default PricingCards

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`
const PriceRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1.2fr 1.2fr;
  column-gap: 5px;
`

const DesktopWrapper = styled.div`
  @media (max-width: 768px) {
    display: none !important;
  }
`

const MobileWrapper = styled.div`
  @media (min-width: 768px) {
    display: none !important;
  }
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px
`

const MobileWrapper2 = styled.div`
  @media (min-width: 768px) {
    display: none !important;
  }
`


interface IGrid {
  columns: number
}

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 200px;
  padding-top: 20px;
`

const PricingColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 220px;
`
const IncludedParagraph = styled.p`
  margin: 10px 0;
`
const InfoIcon = styled.img`
  margin: 10px;
`

const IconPrice = styled.img`
  width: 60px;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  text-align: left;
  border: 2px solid ${theme.grey.keyline};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: none;
  padding: 20px 20px 0px;
  @media (min-width: 768px) {
  }
`

const CardTitle = styled.h4`
  margin-bottom: 8px !important;
  @media (min-width: 768px) {
    margin-bottom: 15px !important;
  }
`
const CardTopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
`

const CardBody = styled.div`
  //margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 300;
  color: ${theme.text.main};

  //height: 330px;
`

const CardImgDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`
const CardImg = styled.img`
  height: 60px;
  width: auto;
  object-fit: contain;
  margin-left: 0px;
  @media (min-width: 768px) {
    margin-bottom: 10px;
  }
`
const EditablePrice = styled.h4`
  color: ${theme.brand.blue};
`
const MonthlyPrice = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 90px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0px;

  @media (min-width: 768px) {
  }
`

const ButtonContainerA = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0px;

  & a:first-of-type {
    margin-bottom: 5px;
  }
  @media (min-width: 768px) {
  }
`
const A = styled.a`
  padding-bottom: -10px;

  background-color: yellow;
`
const Gap = styled.div`
  height: 10px;
  width: 100%;
`
