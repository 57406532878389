import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  getItem,
  validateEmail,
  validateOnBlur,
  validatePhoneNumber,
} from '../../util/functions'
import TrustBox from '../Header/Trustpilot'
import Button from '../shared/Button/Button'
import Input from '../shared/Input'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import NCSClogo from '../../images/national-cyber-security-centre-alt.png'
import CCSSlogo from '../../images/crown-commercial-service-supplier-alt.png'
import { LandingPageHeaderFormBookDemo } from './LandingPageHeaderFormBookDemo'

const LandingPageHeaderVFour = ({ data }: any) => {
 

  const headerImage = data.attrs['header-image']

  const headerImageRaw = decodeURIComponent(headerImage)
  const headerImageJson = JSON.parse(headerImageRaw)

  const headerData = {
    header: data.attrs['header-title'],
    content: data.attrs['header-content'],
    button: {
      label: data.attrs['button-text'],
      link: data.attrs['button-link'],
    },
    image: headerImageJson,
  }

  const logos = [
    {
      img: NCSClogo,
      alt: 'National Cyber Security Centre',
      url: '#',
    },
    {
      img: CCSSlogo,
      alt: 'Crown Commercial Service Supplier',
      url: '#',
    },
  ]

  

  return (
    <>
      <Container>
        <MainGrid>
          <Grid>
            <ContentWrapper>
              {headerData.header && <Heading>{headerData.header}</Heading>}
              {headerData.content && (
                <div className="LPv2_content">
                  <Content>{headerData.content}</Content>
                </div>
              )}
              <LogosContainer>
                {logos.map((logo) => (
                  <LogoMask href={logo.url} key={logo.img+"landing-page-header-v-four"}>
                    <Logo src={logo.img} alt={logo.alt} />
                  </LogoMask>
                ))}
                <TrustBox darkMode={true} />
              </LogosContainer>
            </ContentWrapper>
            <FormWrapper>
              <FormContainer>
                <LandingPageHeaderFormBookDemo/>
              </FormContainer>
            </FormWrapper>
            <ImageWrapper>
              <div
                style={{
                  backgroundSize:"cover",
                  backgroundImage: `url(${headerData.image.url})`,
                }}
              ></div>
            </ImageWrapper>
          </Grid>
        </MainGrid>
      </Container>
      <Container>
        <MobileImageWrapper>
          <div
            style={{
              backgroundSize:"cover",
              backgroundImage: `url(${headerData.image.url})`,
            }}
          ></div>
        </MobileImageWrapper>
      </Container>
    </>
  )
}

export default LandingPageHeaderVFour

const Container = styled.div`
  background: ${theme.text.main};
  @media (min-width: 1200px) {
    margin-bottom: 300px;
  }
`

const Grid = styled.div`
  color: white;
  display: grid;
  position: relative;
  grid-template-rows: repeat(2 1fr);
  @media (min-width: 1200px) {
    grid-template-rows: 1;
    grid-template-columns: repeat(2, 1fr);
  }
`
const ContentWrapper = styled.div``

const Heading = styled.h1`
  margin-top: 50px;
  margin-bottom: 30px;
  color: ${theme.text.white};

  @media (min-width: 1200px) {
    margin-top: 150px;
  }
`
const Content = styled.p`
  color: ${theme.text.white};

  & ul li {
    color: ${theme.text.white};
  }
`

const LogosContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;

  @media (min-width: 1200px) {
    margin-bottom: 220px;
  }
`

const LogoMask = styled.a`
  width: 30%;
  margin-right: 20px
  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    // border: 1px solid #4a4a4a;
    // border-radius: 3px;
    justify-content: center;
  }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`

const FormWrapper = styled.div`
  color: white;
`
const FormContainer = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: -30%;
    z-index: 30;

    & div {
      height: 400px;
      width: 1000px;
    }
  }
`
const MobileImageWrapper = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }

  & div {
    margin-top: -10%;
    width: 100%;
    height: 400px;
    background-position: center;
  }
`
