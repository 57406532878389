import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from './theme'

interface ISmallGrid {
  noPaddingMobile?: boolean
  noPaddingTablet?: boolean
  noPaddingDesktop?: boolean
  borderTop?: boolean
  centerText?: boolean
}

const SmallGrid: FunctionComponent<ISmallGrid> = ({
  children,
  noPaddingMobile,
  noPaddingTablet,
  noPaddingDesktop,
  borderTop,
  centerText,
}) => {
  return (
    <Container
      noPaddingMobile={noPaddingMobile}
      noPaddingTablet={noPaddingTablet}
      noPaddingDesktop={noPaddingDesktop}
      borderTop={borderTop}
      centerText={centerText}
    >
      {children}
    </Container>
  )
}
export default SmallGrid

const Container = styled.section<ISmallGrid>`
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  padding-top: 30px;
  text-align: ${(props) => props.centerText && 'center'};
  border-top: ${(props) =>
    props.borderTop && `1px solid ${theme.grey.keylineDark}`};
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 3%;
    padding: ${(props) => (props.noPaddingDesktop ? '0px' : '10px 3%')};
  }
  @media (max-width: 1024px) {
    padding: ${(props) => (props.noPaddingTablet ? '0px' : '10px 3%')};
  }
  @media (max-width: 767px) {
    padding: ${(props) => (props.noPaddingMobile ? '0px' : '10px 20px')};
  }
`
