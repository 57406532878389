import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import TopRings from '../../images/top-rings.svg'

const DownloadGuideBanner = ({ data }: any) => {
  const bannerData = {
    overLabel: data.attrs['over-label'],
    title: data.attrs.title,
    content: data.attrs.content,
    buttons: [
      {
        label: data.attrs['button-one-text'],
        link: data.attrs['button-one-link'],
        secondary: false,
      },
      {
        label: data.attrs['button-two-text'],
        link: data.attrs['button-two-link'],
        secondary: true,
      },
    ],
  }

  return (
    <MainGrid>
      <Container>
        <ContentContainer>
          {bannerData.overLabel && (
            <OverLabel>{bannerData.overLabel}</OverLabel>
          )}
          {bannerData.title && <Header>{bannerData.title}</Header>}
          {bannerData.content && <Content>{bannerData.content}</Content>}
        </ContentContainer>
        <ButtonsContainer>
          {bannerData.buttons.map((button) =>
            button != null ? (
              <Button href={button.link} secondary={button.secondary}>
                {button.label}
              </Button>
            ) : null
          )}
        </ButtonsContainer>
      </Container>
    </MainGrid>
  )
}

export default DownloadGuideBanner

const Container = styled.div`
  background: ${theme.text.main};
  background-image: url(${TopRings});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  color: white;
  border-radius: 5px;
  padding: 150px 20px 50px;

  @media (min-width: 1200px) {
    padding: 200px 50px 80px;
    text-align: center;
  }
`

const ContentContainer = styled.div`
  margin-bottom: 30px;

  @media (min-width: 1200px) {
    max-width: 80%;
    margin: 0 auto 30px;
  }
`

const OverLabel = styled.p`
  color: white;
  margin-bottom: 20px;
`

const Header = styled.h3`
  color: white;
  margin-bottom: 20px;
`

const Content = styled.p`
  color: white;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;

  & a {
    max-width: 100%;
  }

  & a:first-child {
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) {
    flex-flow: row;
    max-width: 50%;
    margin: 0 auto;
    & a:first-child {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
`
