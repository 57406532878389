import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import LargeGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Accordion from '../shared/Accordion'

interface IAccordion {
  title: string
  body: string
}

interface ImageAccordionProps {
  Image?: any
  mainText?: string
  AccordionContent: Array<IAccordion>
  featuredImage?: boolean
  imageLeft: boolean
}

const ImageAccordion: FunctionComponent<ImageAccordionProps> = ({
  AccordionContent,
  Image,
  mainText,
  featuredImage,
  imageLeft,
}) => {
  let jsonImg = null
  if (Image) {
    const decodedImg = decodeURIComponent(Image)
    jsonImg = JSON.parse(decodedImg)
  }
  return (
    <LargeGrid>
      <Container imageLeft={imageLeft}>
        {featuredImage ? (
          <Col imageLeft={imageLeft}>
            <MainImage src={jsonImg.url} alt={"img"}></MainImage>
          </Col>
        ) : (
          <Col imageLeft={imageLeft}>
            <MainTextContainer>
              <MainText>{mainText}</MainText>
            </MainTextContainer>
          </Col>
        )}
        <AccordionCol>
          {AccordionContent.length > 0 && (
            <Grid imageLeft={imageLeft}>
              {AccordionContent.map((item, key) => {
                return (
                  <Accordion
                    key={key}
                    title={item.title}
                    body={item.body}
                  ></Accordion>
                )
              })}
            </Grid>
          )}
        </AccordionCol>
      </Container>
    </LargeGrid>
  )
}

export default ImageAccordion

interface IAlignmentProps {
  imageLeft: boolean
}

const Container = styled.div<IAlignmentProps>`
  background: linear-gradient(
    to ${(props) => (props.imageLeft ? 'left' : 'right')},
    ${theme.background.product} 75%,
    white 25%
  );
  padding: 40px 0 50px 0;
  display: flex;
  flex-direction: ${(props) => (props.imageLeft ? 'row' : 'row-reverse')};
  margin: 0 0 30px;
  align-items: top;
  @media (max-width: 998px) {
    padding: 0 0 4rem;
    flex-direction: column;
    background: white;
    margin: 0;
  }
`
const Grid = styled.div<IAlignmentProps>`
  flex-direction: column;
  margin: ${(props) => (props.imageLeft ? '24px 80px 0 0' : '24px 0 0 80px')};
  @media (max-width: 998px) {
    margin: 0px;
  }
`

const Tag = styled.p`
  margin: 0 40px;
  color: ${theme.grey.inputHover};
  font-weight: 300;
  margin-bottom: 14px;
  text-align: center;
`
const MainImage = styled.img`
  max-width: 456px;
  margin-top: 20px;
  @media (max-width: 998px) {
    width: 100%;
  }
`

const MainTextContainer = styled.div`
  margin-top: 20px;
  width: 90%;
  background-color: ${theme.text.main};
  align-items: center;
  justify-content: left;
  padding: 8rem 6rem;
  @media (max-width: 998px) {
    width: 100%;
    margin-top: 0px;
    padding: 8rem 6rem 6rem 6rem;
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 0px;
    padding: 7rem 2rem 4rem 2rem;
    width: 100%;
  }
`

const MainText = styled.h4`
  margin: 0 20px;
  margin-bottom: 25px;
  text-align: center;
  color: white;
  text-align: left;
  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`

const Col = styled.div<IAlignmentProps>`
  width: 50%;
  display: flex;
  align-items: start;
  margin: ${(props) => (props.imageLeft ? '0 10px 0 0' : '0 0 0 10px')};
  justify-content: ${(props) => (props.imageLeft ? 'flex-start' : 'flex-end')};
  @media (max-width: 998px) {
    &:first-of-type {
      width: 100%;
      margin: 0;
      justify-content: center;
    }
`
const AccordionCol = styled.div`
  width: 50%;
  padding: 40px 0 40px 0;
  flex-direction: column;
  align-items: top;
  @media (max-width: 998px) {
    width: 100%;
    padding: 40px 0 0 0;
  }
`
