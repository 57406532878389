import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import SmallGrid from '../styling/SmallGrid'

import quote from '../../images/quote-marks.svg'
interface QuoteProps {
  AuthorName?: string
  AuthorPosition?: string
  body?: string
  AuthorImage?: any
}

const Quote: FunctionComponent<QuoteProps> = ({
  body,
  AuthorImage,
  AuthorName,
  AuthorPosition,
}) => {
  let imgJson = null
  if (AuthorImage) {
    const img = decodeURIComponent(AuthorImage)
    imgJson = JSON.parse(img)
  }
  return (
    <MainGrid>
      <Container>
        <BigCircle></BigCircle>
        <SmallGrid>
          <MainImage src={quote}></MainImage>
          <Tag>{body}</Tag>

          <AuthorSection>
            {AuthorImage && <MainImage src={imgJson.url} />}
            <NameSection>
              <b>{AuthorName}</b>
              <p>{AuthorPosition}</p>
            </NameSection>
          </AuthorSection>
        </SmallGrid>
      </Container>
    </MainGrid>
  )
}

export default Quote

interface ImageProps {
  LeftImage?: boolean
}

const Container = styled.div`
  //background-image: U;
  position: relative;
  padding: 40px 20px 120px 0;
  display: flex;
  overflow: hidden;
  flex-direction: ${(props) => (props.LeftImage ? 'row' : 'row-reverse')};
  @media (max-width: 1024px) {
    padding: 40px 20px 180px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 20px 40px 0;
  }
`

const Tag = styled.h3`
  margin: 0 20px;
  color: ${theme.grey.inputHover};
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  line-height: 1.5em;
  margin-bottom: 14px;
  text-align: left;
`
const MainImage = styled.img`
  width: 60px;
  margin: 20px;
`

const AuthorSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const NameSection = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`
const BigCircle = styled.div`
  width: 400px;
  height: 400px;
  border: 60px solid ${theme.brand.blue};
  opacity: 0.3;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: -1;
  @media screen and (min-width: 1024px) {
    top: 0px;
    height: 500px;
    width: 500px;
    margin: 0 100px;
    border: 70px solid ${theme.brand.blue};
  }
`
