import React from 'react'
import styled from 'styled-components'
import WireLineSVG from '../LandingPagesHeaders/Animations/WireLineSVG'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Padlock from '../../images/secure-padlock.svg'
import Circle from '../../images/naught.svg'
import Button from '../shared/Button/Button'

const DoubleSectionWiring = ({ data }: any) => {
  // console.log(data)

  const certImage = data.attrs['certification-image']
  const imageRaw = decodeURIComponent(certImage)
  const imageJson = JSON.parse(imageRaw)

  const sectionsData = {
    topSection: {
      overLabel: data.attrs['over-label'],
      title: data.attrs['top-header'],
      topContent: data.attrs['top-content'],
      scrollerData: [
        { text: data.attrs['pill-one'] },
        { text: data.attrs['pill-two'] },
        { text: data.attrs['pill-three'] },
        { text: data.attrs['pill-four'] },
        { text: data.attrs['pill-five'] },
      ],
    },
    bottomSection: {
      title: data.attrs['bottom-header'],
      bottomContent: data.attrs['bottom-content'],
      padlockCopy: data.attrs['padlock-copy'],
      certificationContent: {
        image: imageJson,
        header: data.attrs['certification-header'],
        content: data.attrs['certification-content'],
      },
      button: {
        label: data.attrs['button-label'],
        link: data.attrs['button-label'],
      },
    },
  }

  return (
    <Container>
      <Section>
        <MainGrid>
          <SectionInnerContainer>
            <ImgWrapper>
              <ScrollerWrapper>
                {sectionsData.topSection.scrollerData.map((data) => (
                  <ScrollerItem>{data.text}</ScrollerItem>
                ))}
              </ScrollerWrapper>
            </ImgWrapper>
            <TextContainer>
              <OverLabel>{sectionsData.topSection.overLabel}</OverLabel>
              <Heading>{sectionsData.topSection.title}</Heading>
              <UnderLabel>{sectionsData.topSection.topContent}</UnderLabel>
            </TextContainer>
          </SectionInnerContainer>
        </MainGrid>
      </Section>
      <Section>
        <MainGrid>
          <SectionTwoInnerContainer>
            <WireContainer>
              <TopAnimationWrapper>
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
              </TopAnimationWrapper>
              <KeylockContentWrapper>
                <PadlockICon src={Padlock} alt="Padlock icon" />

                <KeylockContent>
                  {sectionsData.bottomSection.padlockCopy}
                </KeylockContent>
              </KeylockContentWrapper>

              <CertificationContainer>
                <ContentImg
                  src={
                    sectionsData.bottomSection.certificationContent.image.url
                  }
                  alt={
                    sectionsData.bottomSection.certificationContent.image.alt
                  }
                />
                <CertificationHeader>
                  {sectionsData.bottomSection.certificationContent.header}
                </CertificationHeader>
                <CertificationContent>
                  {sectionsData.bottomSection.certificationContent.content}
                </CertificationContent>
              </CertificationContainer>
            </WireContainer>
            <TextContainerTwo>
              <BottomTitle>{sectionsData.bottomSection.title}</BottomTitle>
              <UnderLabel>
                {sectionsData.bottomSection.bottomContent}
              </UnderLabel>
              <Button
                href={sectionsData.bottomSection.button.link}
                width="200px"
              >
                {sectionsData.bottomSection.button.label}
              </Button>
            </TextContainerTwo>
          </SectionTwoInnerContainer>
        </MainGrid>
      </Section>
    </Container>
  )
}

export default DoubleSectionWiring

const Container = styled.div``

const Section = styled.div`
  padding-bottom: 50px;
  @media (min-width: 1200px) {
    padding-bottom: unset;
  }
  &:nth-of-type(2) {
    background: ${theme.background.grey};
    padding: 70px 0;
    @media (min-width: 1200px) {
      padding: unset;
    }
  }

  &:nth-of-type(2) section div {
    @media (min-width: 1200px) {
      flex-flow: row;
    }
  }
  &:nth-of-type(2) section div div {
    flex-flow: column;
  }
`

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 50px 0 0;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
    padding: 150px 0;
    align-items: center;
  }
  }

`
const SectionTwoInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 0;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
    padding: 150px 0;
    align-items: start;

    & h2 {
      margin-top: 0;
    }
  }
  }

`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-left: 5rem;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    margin-bottom: unset;
  }
`
const TextContainerTwo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-left: 2.5rem;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    margin-bottom: unset;
  }
`

const ImgWrapper = styled.div`
  background: url(${Circle}) no-repeat center;
  background-size: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  @media (min-width: 1200px) {
    max-width: 60%;
    margin-bottom: 0;
  }
`
const ScrollerWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const ScrollerItem = styled.div`
  color: ${theme.text.main};
  text-align: center;
  background: white;
  padding: 10px 20vw;
  width: 100%;
  border: 1px solid grey;
  border-radius: 30px;
  font-weight: 700;
  z-index: 50;
  margin-bottom: 10px;

  @media (min-width: 1200px) {
    padding: 15px 90px;
    margin-bottom: 20px;
  }

  &:nth-of-type(3) {
    color: white;
    background: ${theme.text.main};
    border-color: ${theme.text.main};
  }
`

const Heading = styled.h2`
  margin-bottom: 34px;
  color: ${theme.text.main};
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`

const OverLabel = styled.p``

const BottomTitle = styled.h2`
  margin-top: 10px;
  @media (min-width: 1200px) {
    margin-top: 20px;
  }
`

const UnderLabel = styled.p`
  margin-bottom: 30px;
`

const WireContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-self: end;
`

const KeylockContentWrapper = styled.div`
  background: white;
  padding: 40px 50px;
  max-width: 400px;
  max-height: 170px;
  border: 5px solid ${theme.brand.blue};
  z-index: 60;
  position: relative;
  margin-bottom: 50px;

  @media (min-width: 1200px) {
    margin-bottom: unset;
  }
`

const KeylockContent = styled.p`
  color: ${theme.brand.blue};
  font-weight: 700;
  text-align: center;
`
const TopAnimationWrapper = styled.div`
  & svg {
    top: -150px;
    position: absolute;
    right: 40%;
    z-index: 50;
    @media (min-width: 1200px) {
      top: -65%;
    }
  }
  & svg:nth-of-type(2) {
    right: 20%;
  }
  & svg:nth-of-type(3) {
    left: 20%;
  }
  & svg:nth-of-type(4) {
    left: 40%;
  }
`
const PadlockICon = styled.img`
  position: absolute;
  max-width: 25px;
  left: 50%;
  top: -17%;
  transform: translateX(-50%);
`
const CertificationContainer = styled.div`
  margin-top: 10px;
  background: ${theme.text.main};
  padding: 50px 40px;
  max-width: 400px;
`
const CertificationHeader = styled.h6`
  color: ${theme.text.white};
  padding-bottom: 10px;
`
const CertificationContent = styled.p`
  color: ${theme.text.white};
`
const ContentImg = styled.img``
