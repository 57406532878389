import { Container } from "@material-ui/core"
import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer/RichTextContainer"

interface CareerListingInterface {
    data: any
}

export const CareerListing:React.FC<CareerListingInterface> = ({data}) =>{
   const {subheading, title, description} = data.attrs
    return(
        <StyledContainer maxWidth={"md"}>
            <p>{subheading}</p>
            <h2>{title}</h2>
            <RichTextContainer richText={description}/>

        </StyledContainer>
    )
}

const StyledContainer = styled(Container)`
    text-align: center;
`

