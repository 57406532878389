import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

import Arrow from '../../images/nav-arrow.svg'

interface AccordionProps {
  title: string
  body: string
}

const BigAccordion: FunctionComponent<AccordionProps> = ({ title, body }) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {title && (
        <AccordionContainer>
          {title && (
            <AccordionTitleContainer onClick={toggleOpen}>
              <AccordionTitle>
                <QDiv>Q</QDiv>
                <AccordionTitleText>{title}</AccordionTitleText>
              </AccordionTitle>
              <StyledArrow src={Arrow} isOpen={isOpen} />
            </AccordionTitleContainer>
          )}
          {isOpen && <AccordionBody>{body}</AccordionBody>}
        </AccordionContainer>
      )}
    </div>
  )
}

export default BigAccordion

interface StyledArrowInterface {
  isOpen: boolean
}

const StyledArrow = styled.img<StyledArrowInterface>`
  height: 14px;
  width: 60px;
  margin-left: 20px;

  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : '')};
`

const AccordionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`
interface ICCContainer {
  isOpen: boolean
}
const AccordionContainer = styled.div`
  width: 100%;
  //margin-right: 20px;

  border-top: 1px solid ${theme.grey.scroll};
  &:last-of-type {
    border-bottom: 1px solid ${theme.grey.scroll};
  }
`

const AccordionBody = styled.p`
  text-align: left;
  color: ${theme.text.main};
  width: 100%;
  cursor: pointer;
  font-weight: 300;
  // margin-bottom: 14px;

  padding: 0px 40px 16px;
`

const AccordionTitleText = styled.h5`
  text-align: left;
  color: ${theme.brand.blue};
  margin: 18px 0;

  @media (min-width: 768px) {
    margin-bottom: 15px !important;
  }
`
const AccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const QDiv = styled.b`
  height: 22px;
  width: 22px;
  min-height: 22px;
  min-width: 22px;
  border-radius: 50%;
  background-color: ${theme.brand.blue};
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  vertical-align: center;
  margin: 5px;
  line-height: 20px;
`
