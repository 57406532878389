import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import Accordion from '../shared/BigAccordion'
import Image from '../../buildAssets/pricing/qa.svg'
interface IAccordion {
  title: string
  body: string
}

interface ImageAccordionProps {
  header?: string
  AccordionContent: Array<IAccordion>
}

const ImageAccordion: FunctionComponent<ImageAccordionProps> = ({
  AccordionContent,

  header,
}) => {
  return (
    <Container>
      <Col>
        <MainImage src={Image}></MainImage>
        <Header>{header}</Header>
        {AccordionContent.length > 0 && (
          <Grid>
            {AccordionContent.map((item, key) => {
              return (
                <Accordion
                  key={key}
                  title={item.title}
                  body={item.body}
                ></Accordion>
              )
            })}
          </Grid>
        )}
      </Col>
    </Container>
  )
}

export default ImageAccordion

const Container = styled.div`
  background: ${theme.background.product};
  padding: 40px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px;
  align-items: top;
  @media (max-width: 768px) {
    padding: 1rem 20px 2.2rem;
    flex-direction: column;
    margin: 0 0 44px;
  }
`
const Grid = styled.div`
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 768px) {
    display: flex;
  }
`

const Header = styled.h4`
  margin: 24px auto;
`
const MainImage = styled.img`
  width: 75px;
  margin: 35px auto 0;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1140px;
  align-content: center;
  text-align: center;
`
