import bluefill from '../../../images/policy-dots/pm-blue-fill.svg'
import bluering from '../../../images/policy-dots/pm-blue-outline.svg'
import whitering from '../../../images/policy-dots/pm-white-outline.svg'
import whitefill from '../../../images/policy-dots/pm-white-fill.svg'

import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

export const DotsRowAnimation: FunctionComponent = ({ children }) => {
  const rings = [bluefill, bluering, whitefill, whitering]
  const numberRows = 4
  const numberColumns = 5

  function RingAnimationRow(rowIndex) {
    const outMidpoint = Math.floor(Math.random() * 40 + 20)
    const startImage = rings[Math.floor(Math.random() * 4)]

    let finalImage
    if (rowIndex % 2 == 0) {
      finalImage = bluering
    } else finalImage = whitering

    return (
      <RingContainer>
        <RingImg src={startImage} alt="dot" midpoint={outMidpoint + '%'} />
        <RingImg2 src={finalImage} alt="dot" midpoint={outMidpoint + '%'} />
      </RingContainer>
    )
  }

  return (
    <ImageContainer>
      {[...Array(numberRows)].map((elementInArray, rowIndex) => (
        <ImageRow>
          {[...Array(numberColumns)].map((elementInArray, index) =>
            RingAnimationRow(rowIndex)
          )}
        </ImageRow>
      ))}
    </ImageContainer>
  )
}

interface IImage {
  animate?: boolean
  delay?: string
  timing?: string
}
const ImageContainer = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto 24px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 50%;
    margin: 0 auto;
  }
`

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
`
interface IAnimate {
  midpoint: string
}
const policydotsanimOut = (props) => keyframes`
0% {
      transform: scale(1);
      opacity: 1
    }    
20% {
      transform: scale(1);
      opacity: 1
    }
${props.midpoint}{
      transform: scale(0);
      opacity: 0
    } 
    97% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`
const policydotsanimIn = (props) => keyframes`
 0% {
        transform: scale(0);
        opacity: 0
      }

  20%{
        transform: scale(0);
        opacity: 0
  }
    ${props.midpoint}{
      transform: scale(0);
        opacity: 0
    }

    70%{
      transform: scale(1);
      opacity: 1;
    }
    97% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
}
`
const RingImg = styled.img<IAnimate>`
  position: absolute;
  left: 0;
  -webkit-animation: ${policydotsanimOut} 9s ease-in-out normal infinite;
`
const RingImg2 = styled.img<IAnimate>`
  -webkit-animation: ${policydotsanimIn} 9s normal infinite;
`

const RingContainer = styled.div`
  position: relative;
  margin: 10px auto 5px;
`
