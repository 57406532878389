import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'

import step1 from '../../images/continuous-learning/step1.svg'
import step2 from '../../images/continuous-learning/step2.svg'
import step3 from '../../images/continuous-learning/step3.svg'
import step4 from '../../images/continuous-learning/step4.svg'
import step5 from '../../images/continuous-learning/step5.svg'
import step6 from '../../images/continuous-learning/step6.svg'
import VertLine from '../../images/line.svg'
import AnimDotimg from '../../images/dot-b.svg'

interface IContent {
  heading: string
  body: string
}

interface IContinuous {
  content: Array<IContent>
  image?: string
}

const CultureCycle = ({ content, image }: IContinuous) => {
  let jsonImage = null
  if (image) {
    const decodeComponents = decodeURIComponent(image)
    jsonImage = JSON.parse(decodeComponents)
  }
  return (
    <MainGrid>
      <HorizLineTop>
        <HorizAnimImg src={VertLine}  alt="line"></HorizAnimImg>
        <AnimDotLeft src={AnimDotimg} />
      </HorizLineTop>

      <Container>
        <Col>
          <ContentBox1>
            <StepIcon src={step1} alt="step" />
            <h6>{content[0].heading}</h6>
            <p className="small-p">{content[0].body}</p>
          </ContentBox1>
          <ContentBox1>
            <StepIcon src={step2} alt="step"/>
            <h6>{content[1].heading}</h6>
            <p className="small-p">{content[1].body}</p>
          </ContentBox1>
          <ContentBox1>
            <StepIcon src={step3} alt="step"/>
            <h6>{content[2].heading}</h6>
            <p className="small-p">{content[2].body}</p>
          </ContentBox1>
        </Col>

        <VerticalAnim1>
          <AnimImg src={VertLine}  alt="line"></AnimImg>
          <AnimDotDown src={AnimDotimg} />
        </VerticalAnim1>

        <VerticalAnim2>
          <AnimImg src={VertLine}  alt="line"></AnimImg>
          <AnimDotUp src={AnimDotimg} />
        </VerticalAnim2>
        <Col>
          <IconMobile src={jsonImage.url} alt={jsonImage.alt} />
          <ContentBox1>
            <StepIcon src={step6} alt="step"/>
            <h6>{content[5].heading}</h6>
            <p className="small-p">{content[5].body}</p>
          </ContentBox1>

          <ContentBox1>
            <StepIcon src={step5} alt="step"/>
            <h6>{content[4].heading}</h6>
            <p className="small-p">{content[4].body}</p>
          </ContentBox1>

          <ContentBox1>
            <StepIcon src={step4}alt="step" />
            <h6>{content[3].heading}</h6>
            <p className="small-p">{content[3].body}</p>
          </ContentBox1>

          <IconDesktop src={jsonImage.url} alt={jsonImage.alt} />
        </Col>
      </Container>
      <HorizLineBottom>
        <HorizAnimImg src={VertLine} alt="line"></HorizAnimImg>
        <AnimDotRight src={AnimDotimg} />
      </HorizLineBottom>
    </MainGrid>
  )
}

export default CultureCycle

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  width: 50%;

  &:first-of-type {
    padding-right: 100px;
    @media (max-width: 768px) {
      padding-right: 0px;
    }
  }
  &:last-of-type {
    padding-left: 100px;

    @media (max-width: 768px) {
      padding-left: 0px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 768px) {
    padding-right: 0px;
    width: 100%;
  }
`

const HorizLineTop = styled.div`
  display: flex;
  width: 58%;
  height: 15px;
  margin: 0 auto;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  @media (max-width: 768px) {
    margin: 0 auto;

    align-items: bottom;
    justify-content: center;
    //margin-bottom: 30px;
    width: 75%;
  }
`

const HorizLineBottom = styled.div`
  display: flex;
  width: 58%;
  height: 15px;
  margin: 0 auto;
  margin-bottom: 50px;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  @media (max-width: 768px) {
    position: relative;
    top: -150px;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 75%;
  }
`

const VerticalAnim1 = styled.div`
  width: 25px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 20%;
  z-index: -1;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: show;
  @media (max-width: 768px) {
  
    left: 10%;
    /* margin-bottom:150px;
    padding-bottom: 80px; */
  }
`
const VerticalAnim2 = styled.div`
  width: 25px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 20%;
  z-index: -1;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: show;
  @media (max-width: 768px) {
    right: 10%;
  }
`

const AnimImg = styled.img`
  margin-top: -50px;
  width: 3px;
  margin-left: 8.5px;
  height: 100%;
  object-fit: fill;
`
const HorizAnimImg = styled.img`
  //object-fit: fill;
  //position: relative;
  margin-left: 400px;
  width: 3px;
  //height: 1200px;
  transform: rotate(-90deg);
  @media (max-width: 768px) {
    margin-left: 10%;
  }
`

const AnimDotDown = styled.img`
  width: 15px;
  margin: 0 0px 0 -8px;
  position: absolute;
  top: 0;
  left: -2;
  animation: linear infinite normal;
  animation-name: CultureDots;
  animation-duration: 2s;
`
const AnimDotUp = styled.img`
  width: 15px;
  margin: 0 0px 0 -8px;
  position: absolute;
  top: 0;
  animation: linear infinite reverse;
  animation-name: CultureDots;
  animation-duration: 2s;
  animation-delay: 1s;
`
const AnimDotLeft = styled.img`
  width: 15px;
  //margin: 0 0px 0 -8px ;
  position: relative;
  top: 0;
  left: 100%;
  animation: linear infinite normal;
  animation-name: CultureDotsHoriz;
  animation-duration: 2.1s;
`
const AnimDotRight = styled.img`
  width: 15px;
  //margin: 0 0px 0 -8px ;
  position: relative;

  animation: linear infinite reverse;
  animation-name: CultureDotsHoriz;
  animation-duration: 2.3s;
  animation-delay: 1.3s;
`

const ContentBox1 = styled.div`
  border-radius: 3px;
  background-color: ${theme.text.main};
  padding: 35px;
  width: 100%;
  margin-top: 85px;
  //max-width: 300px;
  position: relative;
  @media (min-width: 712px) {
    //max-width: 244px;
  }
  @media (min-width: 1024px) {
    // padding: 24px 26px 33px;
    // max-width: 292px;
  }
  & h6,
  & p {
    color: ${theme.text.white};
  }

  & h6 {
    margin-bottom: 16px;

    line-height: 24px;
  }

  & p {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }
`

const IconMobile = styled.img`
  margin-bottom: 14px;
  width: 50%;
  margin: 0 auto;
  z-index: 2;
  @media (min-width: 768px) {
    display: none;
  }
`
const IconDesktop = styled.img`
  margin-bottom: 14px;
  width: 100%;
  @media (max-width: 769px) {
    display: none;
  }
`

const StepIcon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: -20px;
  right: 20px;
`
