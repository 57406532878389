import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import { appendUrlPartnerTracking } from '../../util/url'

interface CardProps {
  tag?: string
  header?: string
  body?: string
  buttonText?: string
  buttonURL?: string
}

interface IGreyCards {
  cards: CardProps[]
}
const TwoGreyLoginCards = ({ cards }: IGreyCards) => {
  return (
    <Container>
      <MainGrid noPaddingMobile>
        <Grid>
          {cards.length > 0 &&
            cards.map((item: any, key: number) => {
              return (
                <Card key={key}>
                  {item.tag && <Tag className="small-p">{item.tag}</Tag>}
                  {item.header && <h3>{item.header}</h3>}
                  <Divider></Divider>
                  {item.body && <p>{item.body}</p>}

                  {item.buttonURL && (
                    <ButtonContainer>
                      <Button href={appendUrlPartnerTracking(item.buttonURL)}>
                        {item.buttonText}
                      </Button>
                    </ButtonContainer>
                  )}
                </Card>
              )
            })}
        </Grid>
      </MainGrid>
    </Container>
  )
}

export default TwoGreyLoginCards

const Container = styled.section`
  margin-bottom: 48px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  @media (min-width: 768px) {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: ${theme.background.grey};
  padding: 47px 20px 40px;
  @media (min-width: 768px) {
    border-radius: 4px;
  }
  @media (min-width: 1024px) {
    padding: 65px 64px 60px;
  }
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  margin-bottom: 26px;
  font-weight: 300;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 32px;
    max-width: 300px;
  }

  & a:first-of-type {
    margin-bottom: 11px;
  }
`
const Divider = styled.div`
  width: 100px;
  height: 4px;
  background-color: ${theme.text.main};
  margin: 26px 0;
`
