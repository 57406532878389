import React, { FunctionComponent, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Modal from '@material-ui/core/Modal';

import  closeIcon  from '../../buildAssets/pricing/close-btn.svg'
import checks from '../../buildAssets/compliance/tick.svg'
import line from '../../images/li-line.svg'
import question from '../../images/risk/question.svg'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '20%',
    minWidth: '300px',
    //minHeight: '300px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #00A3C7',
   
    boxShadow: theme.shadows[5],
    padding: ' 10px 20px 40px 40px',
  },
}));

interface PricingGridProps {
  compliance: boolean
  useComplianceText?: boolean
  complianceText?: string
  culture: boolean
  useCultureText?: boolean
  cultureText?: string
  phishing: boolean
  usePhishingText?: boolean
  phishingText?: boolean
  includedParagraph?: string
  helpText?: string 
}

const PricingGrid: FunctionComponent<PricingGridProps> = ({
  compliance,
  useComplianceText,
  complianceText,
  culture,
  useCultureText,
  cultureText,
  phishing,
  usePhishingText,
  phishingText,
  includedParagraph,
  helpText
}) => {
  const theme = useTheme()
  const [modalOpen, setModalOpen] = useState(false)
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  function getModalStyle() {
    const top = 50 
    const left = 50
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const classes = useStyles();

  
  return (
    <Container>
      <MainGrid>
        {helpText &&(

          <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          >
        <div style={modalStyle} className={classes.paper}>
          <CloseModalContainer>
            <CloseIcon src={closeIcon}  onClick={handleClose}/>
           </CloseModalContainer>
        <b style={{paddingRight:'10px'}}>{helpText}</b>
        </div>
        </Modal>
        )}
        
        {includedParagraph && (
          <PriceRow>
            <InfoColumn>
              <IncludedParagraph>{includedParagraph}</IncludedParagraph>
              {helpText &&(
                <InfoIcon src={question}  onClick={handleOpen}></InfoIcon>
              )}
            </InfoColumn>

            <PricingColumn>
              {useComplianceText ? (
                <b>{complianceText}</b>
              ) : (
                <IconIncluded src={compliance ? checks : line} />
              )}
            </PricingColumn>
            <PricingColumn>
              {usePhishingText ? (
                <b>{phishingText}</b>
              ) : (
                <IconIncluded src={phishing ? checks : line} />
              )}
            </PricingColumn>
            <PricingColumn>
              {useCultureText ? (
                <b>{cultureText}</b>
              ) : (
                <IconIncluded src={culture ? checks : line} />
              )}
            </PricingColumn>
          </PriceRow>
        )}
      </MainGrid>
    </Container>
  )
}

export default PricingGrid

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`
const PriceRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1.2fr 1.2fr;
  column-gap: 5px;
`

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15px;
  padding: 7px auto;
  align-items: center;

  //border-bottom: 1px solid #000;

  //line-height: 0.1em;
  //margin: 10px 0 20px;
`

const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: black;
`
const Heading = styled.h6`
  background-color: white;
  text-align: left;
  padding-right: 15px;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: left;
  min-width: 200px;
  margin: 10px 0;
`

const PricingColumn = styled.div`
  display: flex;
  border-left: 2px solid ${theme.grey.keyline};
  border-right: 2px solid ${theme.grey.keyline};
  align-items: center;
  justify-content: center;
  min-width: 220px;
`
const IncludedParagraph = styled.p`
  margin: 0px 0;
  //font-size: 1.2rem;
`
const InfoIcon = styled.img`
  margin: 10px;
  width: 15px;
  cursor: pointer;
`

const IconIncluded = styled.img`
  width: 30px;
`

const CloseModalContainer = styled.div`
text-align: right;
cursor: pointer;
width: 100%;
color: ${theme.brand.blue};
`
const CloseIcon = styled.img`
width: 20px;
height: 20px;
`