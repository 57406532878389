import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

interface seoInterface {
  //   title: string | null
  //   description: string | null
  //   image: any | null
  //   article: boolean | null
  data: any,
  featImg?: any
}

const SEO: React.FC<seoInterface> = ({
  //   title,
  //   description,
  //   image,
  //   article,
  data,
  featImg
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  let jsonImage: any = null
  if (data.attrs.image) {
    const decode = decodeURIComponent(data.attrs.image)
    jsonImage = JSON.parse(decode)
  }

  const seo = {
    title: data.attrs.title || defaultTitle,
    description: data.attrs.description || defaultDescription,
    image: jsonImage ? jsonImage.url : defaultImage,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(data.article ? true : null) && (
        <meta property="og:type" content="article" />
      )}

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (<meta property="og:description" content={seo.description} />)}
      {seo.image && <meta property="og:image" content={seo.image} />}
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="www.bobsbusiness.co.uk" />
      <meta name="twitter:creator" content="@BobsBusinessUK" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (<meta name="twitter:description" content={seo.description} />)}
      {featImg && <meta name="twitter:image" content={ featImg} />}
    </Helmet>
  )
}
///https://cms.dev-bb.co.uk/wp-content/2021/05/26/gdpr-jargon-buster-terminology-explained/gdpr-jargon-buster/
export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
