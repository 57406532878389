import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import theme from '../styling/theme'
// import boy from '../../images/hp-header/man.png'
// import girl from '../../images/hp-header/woman.png'
// import linesLeft from '../../images/hp-header/red-lines-left.png'
// import linesRight from '../../images/hp-header/red-lines-right.png'
// import mobImg from '../../images/hp-header/team.png'
import { appendUrlPartnerTracking } from '../../util/url'

import { graphql,useStaticQuery } from 'gatsby'

const circlesLeftNumber = [
  {
    width: 30,
    border: 5,
    top: 110,
    left: -10,
  },
  {
    width: 20,
    border: 3,
    top: 90,
    left: 6,
  },
  {
    width: 10,
    border: 2,
    top: 105,
    left: 0,
  },
  {
    width: 12,
    border: 3,
    top: 80,
    left: 22,
  },
  {
    width: 22,
    border: 4,
    top: 105,
    left: -10,
  },
  {
    width: 18,
    border: 4,
    top: 70,
    left: 5,
  },
]

const circlesRightNumber = [
  {
    width: 28,
    border: 5,
    top: 100,
    right: 0,
  },
  {
    width: 14,
    border: 3,
    top: 90,
    right: 5,
  },
  {
    width: 10,
    border: 2,
    top: 80,
    right: 8,
  },
  {
    width: 12,
    border: 3,
    top: 70,
    right: 12,
  },
  {
    width: 22,
    border: 4,
    top: 90,
    right: 6,
  },
  {
    width: 18,
    border: 4,
    top: 92,
    right: 0,
  },
]

interface Content {}
interface GetStartedBannerProps {
  heading: string
  subHeading: string
  body: string
  btnUrl: string
  btnTitle: string
}
const GetStartedBanner: FunctionComponent<GetStartedBannerProps> = ({
  heading,
  subHeading,
  body,
  btnUrl,
  btnTitle,
}) => {

  const imagesCollection = useStaticQuery(graphql`
    query img7{
      team: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "team.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      man: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "man.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1080,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }, 
      woman: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "woman.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1080,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },   
      linesLeft: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "red-lines-left.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1080,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      linesRight: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "red-lines-right.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1080,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }   
    }
  `)

const mobImg = imagesCollection.team.edges[0].node.childImageSharp.fluid.src
const boy = imagesCollection.man.edges[0].node.childImageSharp.fluid.src
const girl = imagesCollection.woman.edges[0].node.childImageSharp.fluid.src
const linesRight = imagesCollection.linesRight.edges[0].node.childImageSharp.fluid.src
const linesLeft = imagesCollection.linesLeft.edges[0].node.childImageSharp.fluid.src

  return (
    <Outer>
      <MainGrid>
        <Container>
          <Col>
            <Heading>{heading}</Heading>
            <SubHeading>{subHeading}</SubHeading>

            <Body>{body}</Body>
            <ButtonContainer>
              <Button href={appendUrlPartnerTracking(btnUrl)}>
                {btnTitle}
              </Button>
            </ButtonContainer>
          </Col>

          <ColAnimation>
            <AnimationContainer>
              <BigCircle />
              <GirlWrapper>
                <Girl src={girl} alt="Girl with laptop" />
                <LineLeft4 src={linesRight} isVisible={true} />
                <CirclesContainerRight>
                  {circlesRightNumber &&
                    circlesRightNumber.map((circle, key) => {
                      return (
                        <CircleSmall2
                          key={key}
                          isVisible={true}
                          width={circle.width}
                          border={circle.border}
                          top={circle.top}
                          right={circle.right}
                          delay={key + 1}
                        />
                      )
                    })}
                </CirclesContainerRight>
              </GirlWrapper>
              <BoyWrapper>
                <Boy src={boy} alt="Boy with laptop" />
                <LineLeft1 src={linesLeft} isVisible={true} />
                <CirclesContainerLeft>
                  {circlesLeftNumber &&
                    circlesLeftNumber.map((circle, key) => {
                      return (
                        <CircleSmall1
                          key={key}
                          isVisible={true}
                          width={circle.width}
                          border={circle.border}
                          top={circle.top}
                          left={circle.left}
                          delay={key + 1}
                        />
                      )
                    })}
                </CirclesContainerLeft>
              </BoyWrapper>
            </AnimationContainer>
            <MobileImageContainer>
              <MobileImage src={mobImg} />
            </MobileImageContainer>
            <CirclesContainerRightMob>
              {circlesRightNumber &&
                circlesRightNumber.map((circle, key) => {
                  return (
                    <CircleSmall2
                      key={key}
                      isVisible={true}
                      width={circle.width}
                      border={circle.border}
                      top={circle.top}
                      right={circle.right}
                      delay={key + 1}
                    />
                  )
                })}
            </CirclesContainerRightMob>
            <CirclesContainerLeftMob>
              {circlesLeftNumber &&
                circlesLeftNumber.map((circle, key) => {
                  return (
                    <CircleSmall1
                      key={key}
                      isVisible={true}
                      width={circle.width}
                      border={circle.border}
                      top={circle.top}
                      left={circle.left}
                      delay={key + 1}
                    />
                  )
                })}
            </CirclesContainerLeftMob>
          </ColAnimation>
        </Container>
      </MainGrid>
    </Outer>
  )
}

export default GetStartedBanner

const Outer = styled.section`
  overflow: hidden;
  padding: 50px 0 26px;
  @media (min-width: 1024px) {
    padding-bottom: 8rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 40px 0px 40px;
  background: ${theme.background.product};
  transition: background 1s ease;
  border-radius: 5px;
  position: relative;
  height: 70%;
  @media (max-width: 1024px) {
    padding: 65px 0px 0;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row-reverse;
    background: linear-gradient(
      to right,
      ${theme.background.product} 75%,
      white 25%
    );
    //padding: 4rem 6rem 6rem;
  }

  @media (min-width: 1199px) {
    //padding: 6.4rem 10rem 7rem;
    flex-direction: row-reverse;
  }
`

const Col = styled.div`
  // position: relative;
  background-color: ${theme.text.main};
  padding: 60px 40px;

  @media (min-width: 1024px) {
    width: 40%;
  }
  @media (min-width: 1199px) {
    //margin-right: 10rem;
  }
`

const ColAnimation = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  @media (min-width: 1024px) {
    margin-top: 28px;
    //  position: absolute;
    left: 0;
    top: -110px;
    left: auto;
    width: 60%;
    max-width: 470px;
    margin-top: 0;
    //background: red;
  }

  @media (min-width: 1199px) {
    max-width: 555px;
  }
`

const Heading = styled.h1`
  color: white;
`
const SubHeading = styled.h1`
  color: ${theme.brand.blue};
`
const Body = styled.p`
  margin: 16px 0;
  color: white;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 420px;
  & a:first-of-type {
    margin-right: 20px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    & a:first-of-type {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`
const AnimationContainer = styled.div`
  position: relative;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const BoyWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  height: 100%;
  position: relative;
  left: 20vw;
  top: 40px;
  max-width: 900px;
  z-index: 8;
  @media (min-width: 1024px) {
    top: 10px;
    position: absolute;
    left: 12%;
    height: 520px;
    animation: float 6s ease-in-out infinite 6s;
  }
`
const GirlWrapper = styled(BoyWrapper)`
  margin-top: 20px;

  left: -14vw;
  @media screen and (min-width: 1024px) {
    margin-top: 30px;
    animation: floatF 8s ease infinite 3s;
  }
  @media screen and (min-width: 1024px) {
    left: 33%;
  }
`

const Girl = styled.img`
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 1024px) {
    width: auto;
    max-height: 520px;
    margin-top: 18px;

    transform-style: preserve-3d;
    transform-origin: center 40%;
    animation: 'headerPopInF 1.8s forwards 1s';
  }
`

const Boy = styled.img`
  position: relative;
  z-index: 5;
  transform-style: preserve-3d;
  @media screen and (min-width: 1024px) {
    width: auto;
    max-height: 520px;

    transform-origin: 20% 40%;
    animation: 'headerPopInF 1.8s forwards 1.8s';
  }
`
const CirclesContainerRight = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 16%;
  left: 56%;
  transform-style: preserve-3d;
  animation: floatF 8s ease infinite 3s;
  @media (min-width: 1024px) {
    width: 20%;
    height: 14%;
    right: 42%;
    left: auto;
  }
`

const CirclesContainerLeft = styled(CirclesContainerRight)`
  top: 20%;
  left: 15%;
  z-index: 5;
  @media (min-width: 1024px) {
    top: 20%;
    left: 12%;
  }
`
interface CircleProp {
  width?: number
  border?: number
  top?: number
  isVisible?: boolean
  delay?: number
  left?: number
  right?: number
}
const CircleSmall1 = styled.div<CircleProp>`
  width: 30px;
  height: 30px;
  border: 8px solid ${theme.brand.blue};
  border-radius: 50%;
  position: absolute;
  transform: scale(0) perspective(200px) translateZ(50px);
  transition: all ease;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  top: 30%;
  left: 30%;
  animation: floatCirclesLeftMob 2s cubic-bezier(0, 0.55, 0.45, 1) infinite 3 s;

  z-index: 3;
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesLeft ${
            props.delay && props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${
            props.delay && props.delay + 3
          }s`
        : 'none'};
    transition: all cubic-bezier(0, 0.55, 0.45, 1);
  }
`

const CircleSmall2 = styled(CircleSmall1)`
  right: ${(props) => (props.right ? props.right : 30)}%;
  animation: ${(props) =>
    props.isVisible
      ? ` floatCirclesRightMob ${
          props.delay && props.delay + 2
        }s ease infinite ${props.delay && props.delay + 1.5}s`
      : 'none'};
  left: auto;
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesRight ${
            props.delay && props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${
            props.delay && props.delay + 1.5
          }s`
        : 'none'};
  }
`

const LineLeft1 = styled.img<GirlProps>`
  position: relative;
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2.5s ease infinite 3.5s' : 'none'};
  transform-origin: bottom left;
  transition: transform ease 0.2s;
  width: 100%;
  z-index: 5;
  position: absolute;
  left: 0;
  transform: scale(0);
  width: auto;
  max-height: 520px;
`

const LineLeft4 = styled(LineLeft1)`
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2s ease infinite 3s' : 'none'};
  transform-origin: bottom right;
`

const BigCircle = styled.div<GirlProps>`
  width: 60vw;
  height: 60vw;
  border: 6vw solid ${theme.brand.blue};
  opacity: 0.4;
  border-radius: 50%;
  transform: scale(0);
  position: relative;
  top: 0;
  transform-style: preserve-3d;
  animation: scaleInCircle 1.8s cubic-bezier(0.33, 1, 0.68, 1) forwards 0.2s;
  @media screen and (min-width: 1024px) {
    top: 0px;
    max-height: 380px;
    max-width: 380px;
    margin: 0 auto;
    border: 60px solid ${theme.brand.blue};
  }
`

const MobileImageContainer = styled.div`
  height: auto;
  min-height: 300px;
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const MobileImage = styled.img`
  display: block;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const CirclesContainerRightMob = styled(CirclesContainerRight)`
  height: 12%;
  width: 12%;
  top: 20vw;
  left: 67%;

  @media (min-width: 1024px) {
    display: none;
  }
`
const CirclesContainerLeftMob = styled(CirclesContainerLeft)`
  top: 20vw;
  left: 15%;
  height: 12%;
  width: 12%;

  @media (min-width: 1024px) {
    display: none;
  }
`
