import React from 'react'
import styled from 'styled-components'

interface RichTextContainerI {
  richText: string
}

export const RichTextContainer = ({ richText }: RichTextContainerI) => {
  //  console.log('richText')
  //  console.log(richText)

  //Find and replace https://bobsbusiness.co.uk and https://cms.dev-bb.co.uk with null
  let step1 = richText.replace('https://bobsbusiness.co.uk', '')
  let step2 = step1.replace('href="https://cms.dev-bb.co.uk"', '')


  return <Container dangerouslySetInnerHTML={{ __html: step2 }} />
}

const Container = styled.div`
  /* border: 1px solid red;  */
  width: 100%;
  word-wrap: break-word;

  > h1 {
    margin-bottom: 4rem;
    margin-top: 4rem;
    > strong {
      color: #1d253c !important;
    }
  }
  > h2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #1d253c !important;
    > strong {
      color: #1d253c !important;
    }
  }
  > h3 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #1d253c !important;
    > strong {
      color: #1d253c !important;
    }
  }
  > h4 {
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #1d253c !important;
    > strong {
      color: #1d253c !important;
    }
  }
  > h5 {
    font-size: 1.625rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    color: #1d253c !important;
    > strong {
      color: #1d253c !important;
    }
  }
  > h6 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    color: #1d253c !important;
    > strong {
      color: #1d253c !important;
    }
  }
  > p {
    margin-bottom: 1rem;
  }

  > hr {
    margin-bottom: 2rem;
  }
  > br {
    display: none;
  }
`
