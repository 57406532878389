import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import SmallGrid from '../styling/SmallGrid'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
import Video from '../shared/Video'

interface CardProps {
  videoLink?: string
  tag?: string
  videoPoster?: string
  icon?: string
  alt?: string
  heading?: string

  rightColumn: string
}



const ProductVideoCard: FunctionComponent<CardProps> = ({
  videoLink,
  videoPoster,
  tag,
  heading,
  rightColumn,
}) => {



  return (
    <SmallGrid>
      {tag && <Tag className="small-p">{tag}</Tag>}
      <Container>
        <Col>
          {heading && <h2>{heading}</h2>}
          <VideoContainer>
          <Video videoSrcURL={videoLink} videoTitle={""} />
          </VideoContainer>
         
        </Col>

        <Col2>
          <RichTextContainer richText={rightColumn}></RichTextContainer>
        </Col2>
      </Container>
    </SmallGrid>
  )
}

export default ProductVideoCard

const Container = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  width: 50%;
  align-items: left;
  padding-right: 10px;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
`
const Col2 = styled.div`
  width: 50%;
  align-items: left;
  padding-left: 10px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
`
const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  z-index: 2;
 
`

const Icon = styled.img`
  width: 100%;
  max-width: 170px;
  margin-bottom: 15px;
  margin-top: 26px;

  @media (min-width: 768px) {
    margin-top: 34px;
  }
`
const SubHeading = styled.h5`
  margin: 12px 0;
`

const Tag = styled.p`
  color: ${theme.grey.inputHover};
  margin-bottom: 26px;
  font-weight: 300;
  text-align: left;
`

// const Video = styled.video`
//   max-width: 443px;
//   outline: none;
//   width: 100%;
//   height: 300px;
//   object-fit: cover;
//   margin-bottom: 34px;
//   margin-top: 24px;
//   @media (max-width: 767px) {
//     max-width: 100%;
//     margin-bottom: 50px;
//   }
// `
