import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from './theme'

interface LargeGridProps {
  noPaddingMobile?: boolean
  noPaddingTablet?: boolean
  borderTop?: boolean
}

const LargeGrid: FunctionComponent<LargeGridProps> = ({
  children,
  noPaddingMobile,
  noPaddingTablet,
  borderTop,
}) => {
  return (
    <Container
      noPaddingMobile={noPaddingMobile}
      noPaddingTablet={noPaddingTablet}
      borderTop={borderTop}
    >
      {children}
    </Container>
  )
}
export default LargeGrid

const Container = styled.section<LargeGridProps>`
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  border-top: ${(props) =>
    props.borderTop && `1px solid ${theme.grey.keylineDark}`};
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 3%;
  }
  @media (max-width: 1024px) {
    padding: ${(props) => (props.noPaddingTablet ? '0px' : '0 3%')};
  }
  @media (max-width: 767px) {
    padding: ${(props) => (props.noPaddingMobile ? '0px' : '0 20px')};
  }
`
