import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SmallGrid from '../styling/SmallGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import { appendUrlPartnerTracking } from '../../util/url'
import arrhead from '../../images/arrHorizontal.svg'

interface BusinessProps {
  heading: string
  body: string
  blueText: string
  greyText: string
}
const BusinessImprovement: FunctionComponent<BusinessProps> = ({
  heading,
  body,
  blueText,
  greyText,
}) => {
  return (
    <SmallGrid>
      <Container>
        <Col>
          <BlueBox>
            <BlueText>{blueText}</BlueText>
            <LineContainer>
              <Arrow src={arrhead} alt="Arrow" />

              <Line />
            </LineContainer>
          </BlueBox>
          <GreyBox>{greyText}</GreyBox>
        </Col>

        <Col>
          <h4>{heading}</h4>
          <Content className="small-p">{body}</Content>
          <ButtonContainer>
            <Button href={appendUrlPartnerTracking('/pricing')}>
              Get started
            </Button>

            <Button href={appendUrlPartnerTracking('/contact-us/')} secondary>
              Get in touch
            </Button>
          </ButtonContainer>
        </Col>
      </Container>
    </SmallGrid>
  )
}

export default BusinessImprovement

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${theme.grey.keyline};
  padding: 50px 0;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 8rem 0;
  }
`

const Col = styled.div`
  width: 100%;

  &:nth-of-type(2) {
    margin-top: 30px;
  }
  @media (min-width: 768px) {
    width: 48%;

    &:nth-of-type(2) {
      padding-top: 30px;
      margin-top: 0px;
    }
  }

  & h4 {
    margin-bottom: 15px;
  }
`
const Content = styled.p`
  color: ${theme.grey.inputHover};
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-top: 15px;
  & a:first-of-type {
    margin-bottom: 10px;
  }
`
const BlueBox = styled.div`
  border: 5px solid ${theme.brand.blue};
  padding: 30px 32px 0;
  display: flex;
`
const BlueText = styled.h3`
  color: ${theme.brand.blue};
  padding-top: 8px;
`
const GreyBox = styled.div`
  border: 5px solid ${theme.grey.inputHover};
  padding: 24px 30px;
  color: ${theme.grey.inputHover};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 10px;
`
const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  width: 80px;
  margin-left: 10px;
`

const Arrow = styled.img`
  width: 80px;
  position: relative;
`

const Line = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  background: ${theme.brand.blue};
`
