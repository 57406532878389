import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

interface PillAnimationInterface {
  label: string
  position: number
  containerHeight: number
  numberOfItems: number
}

export const PillAnimationItem: React.FC<PillAnimationInterface> = ({
  label,
  position,
  containerHeight,
  numberOfItems,
}) => {
  // const stepHeight = 80
  // const [offset, setOffset] = useState(`${(position * (containerHeight / numberOfItems)+1)}px`)
  const [stepHeight, setStepHeight] = useState(80)
  const [offset, setOffset] = useState(`${position * stepHeight + 1}px`)

  useEffect(() => {
    // console.log("Container change")
    // setOffset(`${(position * (containerHeight / numberOfItems))}px`)
    if (containerHeight < 345) {
      setStepHeight(54)
    } else {
      setStepHeight(80)
    }
  }, [containerHeight])

  useEffect(() => {
    // setOffset(`${(position * (containerHeight / numberOfItems))}px`)
    setOffset(`${position * stepHeight + 1}px`)
  }, [position])

  const styles = useSpring({ y: offset })

  return (
    <animated.div
      style={{
        ...styles,
        position: 'absolute',
        opacity: position < 0 ? '0' : '1',
        width: '100%',
        // border: '1px solid red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Container isCenter={position === 2}>{label}</Container>
    </animated.div>
  )
}

interface ContainerProps {
  isCenter: boolean
}
const Container = styled.div<ContainerProps>`
  margin: auto 0px;
  display: block;
  font-weight: bold;
  max-width: 280px;
  font-size: 13px;

  padding: 9.5px 0px;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #1d253c;
  color: ${(props) => (!props.isCenter ? '#1D253C' : 'white')};

  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  background-color: ${(props) => (props.isCenter ? '#1D253C' : 'white')};

  @media (min-width: 1200px) {
    max-width: 360px;
    font-size: 18px;
    padding: 16.5px 0px;
  }
`
