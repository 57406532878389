import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Circle from '../../images/naught.svg'
import WireLineSVG from '../LandingPagesHeaders/Animations/WireLineSVG'
import { PillAnimationContainer } from '../PIllAnimation/PillAnimationContainer'

const LeftToRightWiring = ({ data }: any) => {


  const certImage = data.attrs.image
  const imageRaw = decodeURIComponent(certImage)
  const imageJson = JSON.parse(imageRaw)

  const compData = {
    scrollerData: [
      { text: data.attrs['pill-one'] },
      { text: data.attrs['pill-two'] },
      { text: data.attrs['pill-three'] },
      { text: data.attrs['pill-four'] },
      { text: data.attrs['pill-five'] },
    ],
    header: data.attrs.header,
    subHeader: data.attrs['sub-header'],
    content: data.attrs.content,
    image: imageJson,
  }

  return (
    <Container>
      <MainGrid>
        <Grid>
          {/* <ImgWrapper>
            <ScrollerWrapper>
              {compData.scrollerData.map((data) => (
                <ScrollerItem>{data.text}</ScrollerItem>
              ))}
            </ScrollerWrapper>
          </ImgWrapper> */}
          <div style={{alignSelf:"center", justifySelf:"center"}}>

         <PillAnimationContainer labels={[data.attrs['pill-one'],data.attrs['pill-two'],data.attrs['pill-three'],data.attrs['pill-four'],data.attrs['pill-five']]}/>
          </div>
          <WireContainer>
            <AnimationWrapper>
              <WireLineSVG direction="down" />
              <WireLineSVG direction="down" />
              <WireLineSVG direction="down" />
              <WireLineSVG direction="down" />
            </AnimationWrapper>
          </WireContainer>
          <ContentWrapper>
            <Heading>{compData.header}</Heading>
            <ContentImg src={compData.image.url} alt={compData.image.alt} />
            <CertificationHeader>{compData.subHeader}</CertificationHeader>
            <CertificationContent>{compData.content}</CertificationContent>
          </ContentWrapper>
        </Grid>
      </MainGrid>
    </Container>
  )
}

export default LeftToRightWiring

const Container = styled.div`
  padding: 110px 0;

`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const ImgWrapper = styled.div`
  background: url(${Circle}) no-repeat center;
  background-size: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  @media (min-width: 1200px) {
    // max-width: 60%;
    margin-bottom: 0;
    grid-column: 1;
  }
`
const ScrollerWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const ScrollerItem = styled.div`
  color: ${theme.text.main};
  text-align: center;
  background: white;
  padding: 10px 20vw;
  width: 100%;
  border: 1px solid grey;
  border-radius: 30px;
  font-weight: 700;
  z-index: 50;
  margin-bottom: 10px;

  @media (min-width: 1200px) {
    padding: 15px 90px;
    margin-bottom: 15px;
  }

  &:nth-of-type(3) {
    color: white;
    background: ${theme.text.main};
    border-color: ${theme.text.main};
  }
`
const ContentWrapper = styled.div`
  z-index: 50;
  background: white;
  border-top: 10px solid ${theme.brand.blue};
  padding-top: 30px;
  @media (min-width: 1200px) {
    grid-column: 3;
    border-top: none;
    border-left: 10px solid ${theme.brand.blue};
    padding: 20px 0 10px 37px;
  }
`

const Heading = styled.h2`
  margin-bottom: 34px;
  padding-bottom: 33px;
  border-bottom: 1px solid black;
  color: ${theme.text.main};
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`
const ContentImg = styled.img`
  margin: 20px 0 14px;
`
const CertificationHeader = styled.h6`
  padding-bottom: 10px;
`
const CertificationContent = styled.p``

const WireContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  /* border:2px solid red; */
  width: 100%;
  height: 200px;

  @media (min-width: 1200px) {
    height: 100%;
    bottom: -10px; 
    left: 0px;
    transform: rotate(270deg);
  }
`

const AnimationWrapper = styled.div`
/* border: 1px solid blue; */
width: 100%;
max-width: 360px;
position: relative;
  & svg {
    /* border: 1px solid black; */
    bottom: 0px;
    position: absolute;

    z-index: 50;
    @media (min-width: 1200px) {
      bottom: 100px;
  }
  }
  & svg:nth-of-type(1) {
    right: 20%;

  }
  & svg:nth-of-type(2) {
    right: 40%;

  }
  & svg:nth-of-type(3) {
    right: 60%;
 
  }
  & svg:nth-of-type(4) {
    right: 80%;
 
  }
`
