import React from 'react'
import styled from 'styled-components'
// buildassets/logos/compliance/logos/culture
import logo1 from '../../buildAssets/logos/culture/logo-culture.png'
// import logo1 from '../../images/product-header/product-bob-culture.png'
// import logo2 from '../../images/product-header/product-bob-phishing.png'
import logo2 from '../../buildAssets/logos/phishing/logo-phishing.png'
// import logo3 from '../../images/product-header/product-compliance.png'
import logo3 from '../../buildAssets/logos/compliance/logo-compliance.png'

import theme from '../styling/theme'
import { appendUrlPartnerTracking } from '../../util/url'
import { Link } from 'gatsby'
const bobLogos = [
  {
    img: logo1,
    alt: 'Bob Culture logo',
    url: '/products/bobs-culture',
  },
  {
    img: logo2,
    alt: 'Bob Phishing',
    url: '/products/bobs-phishing',
  },
  {
    img: logo3,
    alt: 'Bob Compliance',
    url: '/products/bobs-compliance',
  },
]

interface IIncludedIn {
  culture?: boolean
  phishing?: boolean
  compliance?: boolean
}
export const IncludedIn = ({ culture, phishing, compliance }: IIncludedIn) => {
  return (
    <Container>
      {(culture || phishing || compliance) && (
        <Included className="small-p">Included in ...</Included>
      )}

      <Grid>
        {culture && (
          <LogoMask href={appendUrlPartnerTracking(bobLogos[0].url)}>
            <Logo src={bobLogos[0].img} alt={bobLogos[0].alt} />
          </LogoMask>
        )}
        {phishing && (
          <LogoMask href={appendUrlPartnerTracking(bobLogos[1].url)}>
            <Logo src={bobLogos[1].img} alt={bobLogos[1].alt} />
          </LogoMask>
        )}
        {compliance && (
          <LogoMask href={appendUrlPartnerTracking(bobLogos[2].url)}>
            <Logo src={bobLogos[2].img} alt={bobLogos[2].alt} />
          </LogoMask>
        )}
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  margin: 0px auto;
  /* border: 1px solid red; */
`

const Included = styled.p`
  color: ${theme.text.main};
  font-weight: 300;
  margin: 30px 0 6px;
  text-align: center;
  /* margin: 0px auto; */
`
const LogoMask = styled(Link)`
  width: 30%;

  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    border: 1px solid #4a4a4a;
    border-radius: 3px;
    justify-content: center;
  }

  &:hover,
  &:active {
    border: 1px solid ${theme.brand.red};
  }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`

const Grid = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  //grid-template-columns: repeat(3, 100px);
  grid-column-gap: 10px;
  //margin: 0;
  @media (min-width: 768px) {
    margin: 0;
    /* justify-content: left; */
    // grid-template-columns: repeat(3, 132px);
  }
`
