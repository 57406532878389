import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import circleMob from '../../images/circle_m.svg'
import circleDesk from '../../images/circle_d.svg'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'
import lines from '../../images/empower-red-lines.png'

interface Content {
  title: string
  body: string
}
interface ProtectProps {
  img?: string
  altImg?: string
  cardsContent: Array<Content>
  heading: string
  isVisible?: boolean
  background?: string
  // blueText?: string
  // whiteText?: string
}

const EmpowerCards: FunctionComponent<ProtectProps> = ({
  img,
  altImg,
  cardsContent,
  // blueText,
  // whiteText,
  heading,
  isVisible,
  background,
}) => {
  const [showAllCards, toggleCards] = useState(false)
  const LoadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo('courses-container')
      }, 300)
    }
    toggleCards(!showAllCards)
  }

  const circlesRightNumber = [
    {
      width: 28,
      border: 5,
      top: 100,
    },
    {
      width: 14,
      border: 3,
      top: 90,
    },
    {
      width: 10,
      border: 2,
      top: 80,
    },
    {
      width: 12,
      border: 3,
      top: 70,
    },
    {
      width: 22,
      border: 4,
      top: 90,
    },
    {
      width: 18,
      border: 4,
      top: 92,
    },
  ]
  return (
    <Container background={background}>
      <MainGrid>
        <Inner>
          <BobWrapper>
            <ImageWrapper>
              <Img src={img} alt={altImg} />
              <CirclesContainerRight>
                {circlesRightNumber &&
                  circlesRightNumber.map((circle, key) => {
                    return (
                      <CircleSmall1
                        key={key}
                        isVisible={isVisible}
                        width={circle.width}
                        border={circle.border}
                        top={circle.top}
                        delay={key + 1}
                      />
                    )
                  })}
              </CirclesContainerRight>
              <RedLines src={lines} alt={'Red lines'} isVisible={isVisible} />
            </ImageWrapper>
          </BobWrapper>
          <CardsWrapperMobile id={'courses-container'}>
            {heading && <MainHeading>{heading}</MainHeading>}

            {cardsContent &&
              cardsContent.map((card, key) => {
                if (key < 1 || showAllCards) {
                  return (
                    <Card key={key}>
                      <Circle src={circleMob} alt="Circle" />
                      <CircleDesktop src={circleDesk} alt="Circle" />
                      <CardCol>
                        {card.title && <CardTitle>{card.title}</CardTitle>}
                        {card.body && <CardBody>{card.body}</CardBody>}
                      </CardCol>
                    </Card>
                  )
                }
              })}
            <BtnContainer onClick={LoadMore}>
              <Button >
                {!showAllCards ? 'Show more' : 'Show less'}
              </Button>
            </BtnContainer>
          </CardsWrapperMobile>
          <CardsWrapper id={'courses-container'}>
            {heading && <MainHeading>{heading}</MainHeading>}
            {cardsContent &&
              cardsContent.map((card, key) => {
                return (
                  <Card key={key}>
                    <Circle src={circleMob} alt="Circle" />
                    <CircleDesktop src={circleDesk} alt="Circle" />
                    <CardCol>
                      {card.title && <CardTitle>{card.title}</CardTitle>}
                      {card.body && <CardBody>{card.body}</CardBody>}
                    </CardCol>
                  </Card>
                )
              })}
          </CardsWrapper>
        </Inner>
      </MainGrid>
    </Container>
  )
}

export default EmpowerCards

interface ContainerProps {
  background?: string
}
const Container = styled.section<ContainerProps>`
  background: ${(props) =>
    props.background ? props.background : theme.background.courses};
  padding: 50px 0;
  @media (min-width: 768px) {
    padding: 10rem 0;
    border-bottom: 1px solid ${theme.grey.keylineDark};
  }
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

const BobWrapper = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
    margin-right: 10rem;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 6px;
  padding: 32px 0 20px;
  position: relative;
`

const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  display: none;
  @media (min-width: 768px) {
    width: 55%;
  }
  @media (min-width: 768px) {
    width: 60%;
    display: block;
  }
`
const CardsWrapperMobile = styled.div`
  display: block;
  margin-top: 25px;
  @media (min-width: 768px) {
    display: none;
  }
`
const Card = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 27px 28px 32px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${theme.grey.keylineDark};

  &:last-of-type {
    margin-bottom: 35px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 32px 52px 32px 37px;
  }
`
const CardCol = styled.div`
  @media (min-width: 768px) {
    margin-left: 26px;
  }
`

const Circle = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    display: none;
  }
`
const CircleDesktop = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: block;
    width: 60px;
    height: 60px;
  }
`
const CardTitle = styled.h6`
  color: ${theme.grey.inputHover};
  margin-bottom: 5px;
`

const CardBody = styled.p`
  color: ${theme.grey.inputHover};
`

const BtnContainer = styled.div`
  margin-top: 20px;
`

const Img = styled.img`
  display: block;
  margin: 0 auto;
`

const MainHeading = styled.h3`
  display: block;
  padding-bottom: 27px;
  @media (min-width: 768px) {
    padding-bottom: 34px;
    width: 100%;
    max-width: 90%;
  }
`
interface RedLinesProps {
  isVisible?: boolean
}
const RedLines = styled.img<RedLinesProps>`
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2.5s ease infinite 3.5s' : 'none'};
  transform-origin: bottom left;
  transition: transform ease 0.2s;
  width: 100%;
  z-index: 5;
  position: absolute;
  left: -10px;
  top: 0;
  transform: scale(0);
`
const CirclesContainerRight = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 34%;
  left: 56%;
  transform-style: preserve-3d;
  animation: floatF 8s ease infinite 3s;
  @media (min-width: 1024px) {
    width: 20%;
    height: 14%;
    top: 40%;
    left: 58%;
  }
`
interface CircleSmallProps {
  width?: number
  height?: number
  border?: number
  top?: number
  left?: number
  isVisible?: boolean
  delay?: number
}
const CircleSmall1 = styled.div<CircleSmallProps>`
  width: ${(props) => (props.width ? props.width : 30)}px;
  height: ${(props) => (props.width ? props.width : 30)}px;
  border: ${(props) => (props.border ? props.border : 8)}px solid
    ${theme.brand.blue};
  border-radius: 50%;
  position: absolute;
  transform: scale(0) perspective(200px) translateZ(50px);
  transition: all ease;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  top: ${(props) => (props.top ? props.top : 30)}%;
  left: ${(props) => (props.left ? props.left : 30)}%;
  animation: ${(props) =>
    props.isVisible
      ? ` floatCirclesRightMob ${
          props.delay && props.delay + 2
        }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${
          props.delay && props.delay + 3
        }s`
      : 'none'};
  z-index: 3;
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesRight ${
            props.delay && props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${
            props.delay && props.delay + 3
          }s`
        : 'none'};
    transition: all cubic-bezier(0, 0.55, 0.45, 1);
  }
`
