import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
// import icon from '../../images/continuous-learning/train-employees.png'
// import icon2 from '../../images/continuous-learning/reinforcement-packs.png'
// import icon3 from '../../images/continuous-learning/track-employee-progress.png'
import infinity from '../../images/continuous-learning/infinity.svg'
import step1 from '../../images/continuous-learning/step1.svg'
import step2 from '../../images/continuous-learning/step2.svg'
import step3 from '../../images/continuous-learning/step3.svg'
import { graphql,useStaticQuery } from 'gatsby'

interface RingArrangementInterface {
  card1Body: string
  card1Title: string
  card2Body: string
  card2Title: string
  card3Body: string
  card3Title: string
  centerImage: string
  centerText: string
  image1: any
  image2: any
  image3: any
}

const RingArrangement: React.FC<RingArrangementInterface> = ({
  card1Body,
  card1Title,
  card2Body,
  card2Title,
  card3Body,
  card3Title,
  centerImage,
  centerText,
  image1,
  image2,
  image3,
}) => {

  const imagesCollection = useStaticQuery(graphql`
  
    query img3 {
      icon: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "train-employees.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      icon2: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "reinforcement-packs.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      icon3: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "track-employee-progress.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const icon = imagesCollection.icon.edges[0].node.childImageSharp.fluid.src
  const icon2 = imagesCollection.icon2.edges[0].node.childImageSharp.fluid.src
  const icon3 = imagesCollection.icon3.edges[0].node.childImageSharp.fluid.src



  const image1Raw = decodeURIComponent(image1)
  const image1Json = JSON.parse(image1Raw)

  const image2Raw = decodeURIComponent(image2)
  const image2Json = JSON.parse(image2Raw)

  const image3Raw = decodeURIComponent(image3)
  const image3Json = JSON.parse(image3Raw)


  return (
    <MainGrid>
      <Container>
        <CircleMask>
          <CircleBorder />
          <DotWrapper>
            <Dot />
          </DotWrapper>
          {centerText && (
            <CenterContent>
              <Icon src={infinity} alt="infinity"/>
              <CenterBox className="small-p">{centerText}</CenterBox>
            </CenterContent>
          )}

          <ContentWrapper>
            <ContentBox1>
              <StepIcon src={step1} alt="step" />
              <h6>{card1Title}</h6>
              <p className="small-p">{card1Body}</p>
            </ContentBox1>
            <ContentBox2>
              <StepIcon src={step2} alt="step"/>
              <h6>{card2Title}</h6>
              <p className="small-p">{card2Body}</p>
            </ContentBox2>
            <ContentBox3>
              <StepIcon src={step3} alt="step"/>
              <h6>{card3Title}</h6>
              <p className="small-p">{card3Body}</p>
            </ContentBox3>
            <ContentImgWrapper1>
              <ContentImg1 src={image1Json.url} />
            </ContentImgWrapper1>
            <ContentImgWrapper2>
              <ContentImg1 src={image2Json.url} />
            </ContentImgWrapper2>
            <ContentImgWrapper3>
              <ContentImg1 src={image3Json.url} />
            </ContentImgWrapper3>
          </ContentWrapper>
        </CircleMask>

        <MobileAnimation>
          <LineMask>
            <DotMobile />
          </LineMask>
          <ContentWrapper>
            <ContentBox1>
              <StepIcon src={step1}alt="step" />
              <h6>{card1Title}</h6>
              <p className="small-p">{card1Body}</p>
            </ContentBox1>
            <ContentImgWrapper1>
              <ContentImg1 src={icon} alt="step" />
            </ContentImgWrapper1>
            <ContentBox2>
              <StepIcon src={step2} alt="step" />
              <h6>{card2Title}</h6>
              <p className="small-p">{card2Body}</p>
            </ContentBox2>
            <ContentImgWrapper2>
              <ContentImg1 src={icon2} alt="step"/>
            </ContentImgWrapper2>
            <ContentBox3>
              <StepIcon src={step3} alt="step"/>
              <h6>{card3Title}</h6>
              <p className="small-p">{card3Body}</p>
            </ContentBox3>

            <ContentImgWrapper3>
              <ContentImg1 src={icon3} alt="step"/>
            </ContentImgWrapper3>
          </ContentWrapper>
        </MobileAnimation>
      </Container>
    </MainGrid>
  )
}

export default RingArrangement

const Container = styled.section`
  @media (min-width: 712px) {
    padding: 12.6rem 0 14rem;
  }
`

const CircleMask = styled.div`
  height: 400px;
  width: 400px;
  margin: 0 auto;
  position: relative;
  display: none;
  @media (min-width: 712px) {
    display: block;
  }
  @media (min-width: 1024px) {
    height: 683px;
    width: 683px;
  }
`

const CircleBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px dashed ${theme.brand.blue};
  border-radius: 50%;
`
const DotWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: animateBorder 8s cubic-bezier(0.83, 0, 0.17, 1) infinite;
`

const Dot = styled.div`
  position: absolute;
  display: block;
  border-radius: 19px;
  content: '';
  background: ${theme.brand.blue};
  width: 15px;
  height: 15px;
  left: 50%;
  top: -2%;
  @media (min-width: 1024px) {
    top: -1%;
  }
`

const DotMobile = styled(Dot)`
  animation: animateDotMobileContinuousLearning 5s ease infinite alternate;
  transform: translateX(-50%);
  z-index: -2s;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ContentBox1 = styled.div`
  border-radius: 3px;
  background-color: ${theme.text.main};
  padding: 19px 26px 20px;
  width: 100%;
  max-width: 300px;
  position: relative;
  @media (min-width: 712px) {
    max-width: 244px;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  @media (min-width: 1024px) {
    padding: 24px 26px 33px;
    max-width: 292px;
  }
  & h6,
  & p {
    color: ${theme.text.white};
  }

  & h6 {
    margin-bottom: 3px;
    font-size: 16px;
    line-height: 24px;
  }

  & p {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }
`
const ContentBox2 = styled(ContentBox1)`
  @media (min-width: 712px) {
    top: 65%;
    left: 75%;
  }
`

const ContentBox3 = styled(ContentBox1)`
  @media (min-width: 712px) {
    top: 43%;
    left: -36%;
  }
  @media (min-width: 1024px) {
    top: 40%;
    left: -20%;
  }
`
const CenterContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Icon = styled.img`
  margin-bottom: 14px;
  width: 100%;
  max-width: 70px;
`

const CenterBox = styled.div`
  width: 100%;
  max-width: 140px;
  text-align: center;
  color: ${theme.grey.inputHover};
`
const ContentImgWrapper1 = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  margin-left: auto;
  margin-bottom: 64px;

  @media (min-width: 712px) {
    position: absolute;
    top: 0px;
    left: -10%;
    max-width: 200px;
  }
  @media (min-width: 1024px) {
    max-width: 322px;
  }
`
const ContentImgWrapper2 = styled(ContentImgWrapper1)`
  @media (min-width: 712px) {
    top: 20%;
    left: 72%;
  }
`
const ContentImgWrapper3 = styled(ContentImgWrapper1)`
  margin-bottom: 0;
  @media (min-width: 712px) {
    top: 78%;
    left: 8%;
  }
`

const ContentImg1 = styled.img`
  width: 100%;
  height: auto;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-top: 10px;
  @media (min-width: 712px) {
    margin-top: 20px;
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: 100%;
    margin-top: 10px;
  }
`

const MobileAnimation = styled.div`
  position: relative;
  padding-bottom: 77px;
  padding-top: 45px;

  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    z-index: -2;
    background-image: linear-gradient(
      to bottom,
      ${theme.brand.blue} 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: right;
    background-size: 1px 10px;
    background-repeat: repeat-y;
  }

  @media (min-width: 600px) {
    padding: 45px 8vw 77px;
  }
  @media (min-width: 712px) {
    display: none;
  }
`
const LineMask = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`
const StepIcon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: -20px;
  right: 20px;
`
