import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import NCSClogo from '../../images/national-cyber-security-centre-alt.png'
import CCSSlogo from '../../images/crown-commercial-service-supplier-alt.png'
import TrustBox from '../Header/Trustpilot'

const LandingPageHeaderVThree = ({ data }: any) => {
  const headerImage = data.attrs['header-image']

  const headerImageRaw = decodeURIComponent(headerImage)
  const headerImageJson = JSON.parse(headerImageRaw)

  const logos = [
    {
      img: NCSClogo,
      alt: 'National Cyber Security Centre',
      url: '#',
    },
    {
      img: CCSSlogo,
      alt: 'Crown Commercial Service Supplier',
      url: '#',
    },
  ]

  const headerData = {
    title: data.attrs['header-title'],
    content: data.attrs['header-content'],
    button: {
      label: data.attrs['button-text'],
      link: data.attrs['button-link'],
    },
    image: headerImageJson,
  }

  return (
    <>
      <MobileImageWrapper>
        <div
          style={{
            backgroundImage: `url(${headerData.image.url})`,
          }}
        ></div>
      </MobileImageWrapper>
      <MainGrid>
        <Grid>
          <div
            style={{
              backgroundImage: `url(${headerData.image.url})`,
              backgroundRepeat: 'no-repeat',
              minHeight: 800,
            }}
          ></div>
          <ContentContainer>
            {headerData.title && <Heading>{headerData.title}</Heading>}
            {headerData.content && <Content>{headerData.content}</Content>}
            {data.attrs['button-text'] && (
              <Button href={headerData.button.link}>
                {headerData.button.label}
              </Button>
            )}
            <LogosContainer>
              {logos.map((logo) => (
                <LogoMask href={logo.url}>
                  <Logo src={logo.img} alt={logo.alt} />
                </LogoMask>
              ))}
              <TrustBox darkMode={true} />
            </LogosContainer>
          </ContentContainer>
        </Grid>
      </MainGrid>
    </>
  )
}

export default LandingPageHeaderVThree

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  align-items: center;

  & div:first-child {
    display: none;
    @media (min-width: 1200px) {
      grid-row: 1;
      display: block;
      grid-column: 1/7;
    }
  }
`

const ContentContainer = styled.div`
  background: ${theme.text.main};
  height: fit-content;
  grid-row: 2;
  margin-top: -20%;
  margin-left: -20px;
  padding: 30px 20px;
  grid-column 1/12;
  
  @media (min-width:1200px) {
    grid-row:1;
    padding: 70px 50px;
    margin: unset;
    grid-column 6/13;
  }
`

const Heading = styled.h1`
  margin-bottom: 30px;
  color: ${theme.text.white};
`

const Content = styled.p`
  color: ${theme.text.white};
  margin-bottom: 30px;
`
const LogosContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid ${theme.grey.keylineDark};
`

const LogoMask = styled.a`
  width: 30%;

  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    // border: 1px solid #4a4a4a;
    // border-radius: 3px;
    justify-content: center;
  }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`

const MobileImageWrapper = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }

  & div {
    height: 400px;
    width: 100%;
    background-position: center;
  }
`
