import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import blueBorder from '../../images/spinner.svg'
import dotBlue from '../../images/dot-b.svg'
import dotGrey from '../../images/dot-g.svg'

interface ITrainingAnimation {
  tag?: string
  heading?: string
  content?: string
  imgSrc?: string
  animationHeading?: string
  animationContent?: string
}
const TrainingAnimation = ({
  tag,
  heading,
  content,
  imgSrc,
  animationHeading,
  animationContent,
}: ITrainingAnimation) => {
  const decoded = decodeURIComponent(imgSrc)
  const json = JSON.parse(decoded)
  return (
    <Container>
      {/* <MainGrid>
        <SmallGrid centerText noPaddingMobile noPaddingTablet noPaddingDesktop>
          {tag && <Tag className="small-p">{tag}</Tag>}
          {heading && <Heading>{heading}</Heading>}
          {content && <Body>{content}</Body>}
        </SmallGrid>
      </MainGrid> */}
      <AnimationContainer>
        <LeftCol>
          <ProfileWrapper>
            <Profile profile={json.url} />
            <ProfileBlueBorder src={blueBorder} />
          </ProfileWrapper>
          <b>Trained employee</b>
        </LeftCol>
        <LinesContainer>
          <GreyLine>
            <DotWrapperGrey
              delay={'3s'}
              timing={'5s'}
              transition="cubic-bezier(0.83, 0, 0.17, 1)"
            >
              <Dot src={dotGrey} />
            </DotWrapperGrey>
            <DotWrapperGrey
              delay="3.4s"
              timing="5s"
              transition="cubic-bezier(0.65, 0, 0.35, 1)"
            >
              <Dot src={dotGrey} />
            </DotWrapperGrey>
            <DotWrapperGrey delay={'5.2s'} timing={'5s'} transition="ease">
              <Dot src={dotGrey} />
            </DotWrapperGrey>
            <Pill background={theme.grey.inputHover}>
              <b>Recognise </b> threat
            </Pill>
          </GreyLine>

          <BlueLine>
            <DotWrapperBlue
              delay="0s"
              timing="5s"
              transition="cubic-bezier(0.65, 0, 0.35, 1)"
            >
              <Dot src={dotBlue} />
            </DotWrapperBlue>
            <DotWrapperBlue
              delay="0.7s"
              timing="5s"
              transition="cubic-bezier(0.83, 0, 0.17, 1)"
            >
              <Dot src={dotBlue} />
            </DotWrapperBlue>
            <DotWrapperBlue delay="2.2s" timing="5s" transition="ease">
              <Dot src={dotBlue} />
            </DotWrapperBlue>
            <Pill background={theme.brand.blue}>
              <b>Respond </b>to threat
            </Pill>
          </BlueLine>
        </LinesContainer>
        <AnimationContent>
          {animationHeading && (
            <AnimationHeading>{animationHeading}</AnimationHeading>
          )}
          {animationContent && <P>{animationContent}</P>}
        </AnimationContent>
      </AnimationContainer>
    </Container>
  )
}

export default TrainingAnimation

const Container = styled.div`
  padding: 0 0 74px;
  border-bottom: 1px solid ${theme.grey.keyline};
  @media (min-width: 1024px) {
    padding: 0 0 85px;
  }
`

const Tag = styled.p`
  font-weight: 300;
  color: ${theme.grey.inputHover};
  margin-bottom: 18px;
`
const Heading = styled.h2`
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    margin-bottom: 28px;
  }
`

const Body = styled.p`
  font-weight: 300;
`
const AnimationContainer = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  @media (min-width: 768px) {
    padding: 0 3% 0;
  }
  @media (min-width: 1024px) {
    width: 90%;
    margin: 0 auto;
  }
`
const LeftCol = styled.div`
  width: 106px;
  text-align: center;
  padding-top: 50px;
  background: white;
  z-index: 3;
  & b {
    display: block;
    width: 80%;
    margin: 13px auto 0;
  }

  @media (min-width: 768px) {
    width: 144px;
  }
`

const ProfileWrapper = styled.div`
  position: relative;
  width: 106px;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 144px;
    height: 144px;
  }
`
interface IProfile {
  profile?: string
}
const Profile = styled.div<IProfile>`
  background: url(${(props) => props.profile});
  background-repeat: no-repeat;
  background-size: cover;
  width: 90px;
  height: 90px;

  @media (min-width: 768px) {
    width: 130px;
    height: 130px;
  }
`
const ProfileBlueBorder = styled.img`
  position: absolute;
  width: 106px;
  height: 106px;
  animation: animateBorder 2.5s linear infinite;

  @media (min-width: 768px) {
    width: 144px;
    height: 144px;
  }
`
const AnimationContent = styled.div`
  width: 196px;
  padding: 23px 21px 40px;
  border: 1px solid ${theme.grey.heylineDots};
  border-radius: 4px;
  background-color: ${theme.background.grey};
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const AnimationHeading = styled.h6`
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 21px;
`

const P = styled.p`
  font-weight: 300;
  font-size: 13px;
  line-height: 21px;
`

const LinesContainer = styled.div`
  flex: 1;
  height: 117px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  position: relative;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 150px;
  }
`

const GreyLine = styled.div`
  height: 1.5px;
  width: 100%;
  top: 27px;
  background-color: ${theme.grey.inputHover};
  position: relative;
`
const BlueLine = styled(GreyLine)`
  background-color: ${theme.brand.blue};
  top: 76%;
`
interface IPill {
  background: string
}
const Pill = styled.div<IPill>`
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${(props) => props.background};
  color: ${theme.text.white};
  font-family: Montserrat;
  font-size: 11px;
  letter-spacing: -0.42px;
  line-height: 14px;
  text-align: center;
  border-radius: 10px;
  padding: 2px 10px;
  display: inline-block;
  font-weight: 300;
  z-index: 3;

  & b {
    font-weight: 600;
  }
`

interface IDot {
  background?: string
}

const Dot = styled.img<IDot>`
  height: 10px;
  width: 9px;
  position: absolute;
  top: 1px;
  left: -20%;
  transform: translate(0, -50%);
`
interface IDotWrapperGrey {
  delay: string
  timing: string
  transition: string
}

const DotWrapperGrey = styled.div<IDotWrapperGrey>`
  height: 14px;
  width: 100%;
  position: absolute;
  top: -50%;
  animation: trainingGreyDot 4s ${(props) => props.transition} infinite
    ${(props) => props.delay};

  @media (min-width: 768px) {
    animation: trainingGreyDot ${(props) => props.timing}
      ${(props) => props.transition} infinite ${(props) => props.delay};
  }
`
const DotWrapperBlue = styled(DotWrapperGrey)`
  animation: trainingBlueDot 4s ease infinite ${(props) => props.delay};
  @media (min-width: 768px) {
    animation: trainingBlueDot ${(props) => props.timing}
      ${(props) => props.transition} infinite ${(props) => props.delay};
  }
`
