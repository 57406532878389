import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

interface SmallCardsGridProps {
  sectionHeading?: string
}

const PricingGridHeader: FunctionComponent<SmallCardsGridProps> = ({
  sectionHeading,
}) => {
  return (
    <Container>
      <MainGrid>
        <PriceRow>
          <InfoColumn>
            <Heading>{sectionHeading}</Heading>
          </InfoColumn>

         
          <PricingColumn>
              <Line/>
          </PricingColumn>
          <PricingColumn>
              <Line/>
          </PricingColumn>
          <PricingColumn>
              <Line/>
          </PricingColumn>
        </PriceRow>
       
      </MainGrid>
    </Container>
  )
}

export default PricingGridHeader

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};

`
const Main= styled.div`
width:100%;
max-width: 1200px;
`
const PriceRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.2fr 1.2fr 1.2fr;
  column-gap: 5px;
`

const Line = styled.div`
  height: 1px;

  width: 130%;
  max-width: 1060px;
   position: relative;
  top: -20px;
  //left: 40px; 
  margin:  auto 0px 0 -50px;
  padding:0 40px;
  background-color: black;
`
const Heading = styled.h6`
  background-color: white;
  text-align: left;
  padding-right: 15px;
  width: auto;
  z-index: 1;
`
const InfoColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  text-align: left;
  min-width: 200px;
  margin: 10px 0;
`

const PricingColumn = styled.div`
  display: flex;
  /* position: sticky; */
 // left: 0;
  border-left: 2px solid ${theme.grey.keyline};
  border-right: 2px solid ${theme.grey.keyline};
  align-items: center;
  justify-content: center;
  min-width: 220px;
`
