import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SmallGrid from '../styling/SmallGrid'

import VertLine from '../../images/line.svg'
import TopImg from '../../images/li-line.svg'
import AnimDotimg from '../../images/dot-b.svg'

interface IUploadProps {
  title?: string
  paragraph?: string
  icon?: any
}

const UploadAnimation: FunctionComponent<IUploadProps> = ({
  icon,
  paragraph,
  title,
}) => {
  const iconDecoded = decodeURIComponent(icon)
  const iconJson = JSON.parse(iconDecoded)
  return (
    <SmallGrid>
      {icon && (
        <Container>
          <Grid>
            <Col>
              <TopIcon src={iconJson.url} alt="upload" />
              {title && <Heading>{title}</Heading>}
              {paragraph && <P>{paragraph}</P>}
            </Col>
            <Col>
              <AnimTopImg src={TopImg}></AnimTopImg>

              <AnimContainer>
                <AnimImg src={VertLine}></AnimImg>
                <AnimDot src={AnimDotimg} />
              </AnimContainer>
            </Col>
          </Grid>
        </Container>
      )}
    </SmallGrid>
  )
}

export default UploadAnimation

const Container = styled.div`
  padding: 40px 0 0px;
  border-top: 1px solid #ececec;
  @media (min-width: 768px) {
    padding: 2rem 0 1.2rem;
  }
`
const Grid = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`

const AnimContainer = styled.div`
  width: 200px;
  height: 250px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const AnimImg = styled.img`
  margin: 0 0px 0 100px;
  height: 600px;
`
const AnimTopImg = styled.img`
  height: 1px;
`
const AnimDot = styled.img`
  width: 15px;
  margin: 0 0px 0 -8px;
  position: relative;
  animation: linear infinite normal;
  animation-name: vertDots;
  animation-duration: 2s;
  &::last-of-type {
    margin: 0 20px 0 0px;
  }
`

const Heading = styled.h4`
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`
const Col = styled.div`

&:last-of-type {
      @media  (max-width: 768px){
        display: none;
      }
 &:first-of-type {
   @media  (max-width: 768px){
     width:100%;
   }
   }
 @media (min-width: 768px) {
    width: 50%;
    
   
    &:first-of-type {
      margin-right: 3rem;
     
    }
    &:last-of-type {
      
      display: flex;
      flex-direction: column;
   align-items:center;
  }
  }
`
const TopIcon = styled.img`
  display: none;
  height: 75px;
  margin-bottom: 12px;
  display: block;
`

const P = styled.p`
  font-weight: 300;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 22px;
  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 420px;
  }
`
