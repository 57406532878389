import axios from 'axios'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ErrorMessage from '../../shared/ErrorMessage'
import Input from '../../shared/Input'
import { validatePhoneNumber } from '../../../util/functions'

import theme from '../../styling/theme'
interface PhoneNumberInterface {
  phoneNumber?: string
  onPhoneNumberChange?: (phoneNumber: string) => void
  validated: boolean;
   setValidated: React.Dispatch<React.SetStateAction<boolean>>
}

export const PhoneNumberInput: React.FC<PhoneNumberInterface> = ({
  phoneNumber,
  onPhoneNumberChange,
  validated,
  setValidated
}) => {
  const [value, setValue] = useState(phoneNumber !== undefined ? phoneNumber : '')
  const [mount, setMount] = useState(false)
  // const [validated, setValidated] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setIsLoading] = useState(false)


  const validateNumberLoquate = async (
    phoneNumber: string,
    callBack: (response: any) => void
  ) => {
    const result = await axios.post(
      // `https://api.addressy.com/NumberValidation/Interactive/Validate/v2.00/json3.ws?Key=YE96-UB21-DY16-KH39&Number=`
      `https://api.addressy.com/PhoneNumberValidation/Interactive/Validate/v2.20/json3.ws?Key=YE96-UB21-DY16-KH39&Phone=${phoneNumber}&Country=GB`
    )
    try {
     
            callBack(result.data.Items)
        
    } catch {
      () => {
        callBack(false)
      }
    }
    return result
  }

  useEffect(() => {
    if(value=== ""){
      return
    }

    /*
    if (!mount) {
      setMount(true)
      return
    }
    setValidated(false)
    setError(false)
    setIsLoading(true)
    const delayDebounceFn = setTimeout(() => {
 
        // console.log(value)
        validateNumberLoquate(value, (response)=>{
            if(value=== ""){
                setError(true)
                setErrorMessage("Please enter a phone number")
            }
            console.log(response)
            setIsLoading(false)
            try{

                if(response[0].IsValid === "Yes"){
                    setValidated(true)
                    setError(false)
                    setErrorMessage("")
                }else{
                    setValidated(false)
                    setError(true)
                    setErrorMessage("Please enter a valid phone number.")
                }
            }catch{()=>{
                setError(true)
                setErrorMessage("Something went wrong!")
            }}
        })
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)*/

    // FOR NOT LOQATE
    if (!validatePhoneNumber(value)) {
      setValidated(false);
      setError(true);
      setErrorMessage("Please enter a valid phone number.")
    } else {
      setValidated(true);
      setError(false);
      setErrorMessage("");
    }


  }, [value])

  const validateBlur = () => {
    if(value=== ""){
        setError(true)
        setErrorMessage("Please enter a phone number.")
    }
  }

  return (
    <InputContainer>
      <label>Phone Number*</label>
      <div>

      <InputElement
      isValidated={validated}
      hasError={error}
        value={value}
        onChange={(e) => {
          const isNumberRe = /^[0-9\.\-\/ +]+$/;
          const value = e.target.value;
          if (isNumberRe.test(value) || value === "") {
            setValue(value);
            onPhoneNumberChange(value);
          }
        }}
        type={'text'}
        onBlur={validateBlur}
        name={"phone"}
        id={"phone"}
        />
        </div>
      {loading ? <p>Loading...</p> : <></>}
      {error  && <ErrorMessage title={""} subtitle={errorMessage} />} 
      {/* {validated ? <p>Validated!</p> : <></>} */}
    </InputContainer>
  )
}

interface InputElementProps {
    isValidated?: boolean
    hasError?: boolean
    borderRadius?: string
  }

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  width: 100%;

  position: relative;

  & label {

    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 4px;
  }
`


const InputElement = styled.input<InputElementProps>`
  height: 60px;
  width: 100%;
  flex-shrink: 0;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid ${theme.grey.product};
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.25);
   border-color: ${(props) =>
    props.isValidated
      ? theme.support.green
      : props.hasError
      ? theme.support.red
      : theme.grey.product} !important;
  color: ${(props) =>
    props.isValidated
      ? theme.support.green
      : props.hasError
      ? theme.support.red
      : theme.grey.inputHover}; 
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  background-color: #fff;
  font-size: 16px;
  line-height: 1.9rem;
  font-weight: 500;
  transition: background-color 200ms ease, border-color 200ms ease,
    box-shadow 200ms ease;
  ${(props) => props.width && `width ${props.width}px`};

  &:focus {
    box-shadow: none;
  }
  ${(props) =>
    props.isValidated &&
    `
  color: #4caf50;
  border-color: #4caf50;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
  outline: none;
  `}
  ${(props) =>
    props.hasError &&
    `
    border-color: #d0021b;
    background-color: #fff;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
    outline: none;
    color: #d0021b;
    `}
@media only screen and (max-width: 850px) {
    font-size: 16px;
    line-height: 26px;
  }

  ::placeholder {
    color: #aaa !important;
    font-weight: 300;

    ${(props) =>
      props.hasError &&
      `
      color: ${
        props.isValidated
          ? theme.support.green
          : props.hasError
          ? theme.support.red
          : theme.grey.inputHover
      };
      `}
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    ${(props) =>
      props.hasError
        ? `
    border-color: ${theme.support.red};
    `
        : `border-color: ${theme.brand.blue}`};
    background-color: ${(props) =>
      props.hasError ? '#FEF7F7' : props.isValidated ? '#F6FAF4' : '#fff'};
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
  }
`