import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from './Button/Button'

import { appendUrlPartnerTracking } from '../../util/url'

interface IBtn {
  title: string
  url: string
}
interface SmallCardsGridProps {
  btns: Array<IBtn>
}



const SmallCardsGrid: FunctionComponent<SmallCardsGridProps> = ({ btns }) => {
  
  var compliance = false;
  if (btns[0].title === 'Get Compliance'){
    compliance = true
  }
  return (
    <Container>
      <MainGrid>
        {btns && (
          <ButtonContainer>

              {compliance == true? (
                      

                      <a onClick={()=> window.open(appendUrlPartnerTracking(btns[0].url), "_blank")}>
                      <Button>
                      {btns[0].title}
                    </Button>
                    </a>
              ):(
                <Button href={appendUrlPartnerTracking(btns[0].url)}>
               {btns[0].title}
              </Button>
                )}
          

            <Button href={appendUrlPartnerTracking(btns[1].url)} secondary>
              {btns[1].title}
            </Button>
          </ButtonContainer>
        )}
      </MainGrid>
    </Container>
  )
}

export default SmallCardsGrid

const Container = styled.div`
  // border-bottom: 1px solid ${theme.grey.keyline};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  padding-bottom: 20px;
  & a{
  width: 100%;
}
  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
`
