import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import Arrow from '../../images/nav-arrow.svg'

export interface AccordionPropsOne {
  title: string
  body: string
  onToggle: any
  active: any
}

const LandingPageAccItem: FunctionComponent<AccordionPropsOne> = ({
  title,
  body,
  onToggle,
  active,
}) => {
  return (
    <div>
      {title && (
        <AccordionContainer active={active}>
          {title && (
            <AccordionTitleContainer onClick={onToggle}>
              <AccordionTitle>{title}</AccordionTitle>
              <StyledArrow src={Arrow} active={active} />
            </AccordionTitleContainer>
          )}
          {active && <AccordionBody>{body}</AccordionBody>}
        </AccordionContainer>
      )}
    </div>
  )
}

export default LandingPageAccItem

interface StyledArrowInterface {
  active: boolean
}

const StyledArrow = styled.img<StyledArrowInterface>`
  height: 14px;
  width: 15px;
  margin-left: 20px;

  transform: ${(props) => (props.active ? 'rotate(180deg)' : '')};
`

const AccordionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
interface ICCContainer {
  active: boolean
}
const AccordionContainer = styled.div<ICCContainer>`
  width: 100%;
  margin-top: -1px;
  cursor: pointer;
  padding: 0 30px;
  border-top: 1px solid ${theme.grey.scroll};
  background: ${(props) => (props.active ? 'white' : 'transparent')};
  &:last-of-type {
    border-bottom: 1px solid ${theme.grey.scroll};
  }
`

const AccordionBody = styled.p`
  text-align: left;
  color: ${theme.text.main};
  width: 100%;
  cursor: default;
  font-weight: 300;
  // margin-bottom: 14px;

  padding-bottom: 20px;
`

const AccordionTitle = styled.h5`
  text-align: left;
  color: ${theme.brand.blue};
  margin: 18px 0;

  @media (min-width: 768px) {
    margin-bottom: 15px !important;
  }
`
