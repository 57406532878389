import React from 'react'
import styled from 'styled-components'

interface RichTextContainerI {
  richText: string
}

export const RichTextContainer = ({ richText }: RichTextContainerI) => {
  let step1 = richText.replace('https://bobsbusiness.co.uk', '')
  let step2 = step1.replace('href="https://cms.dev-bb.co.uk"', '')

  return <Container dangerouslySetInnerHTML={{ __html: step2 }} />
  // return <Container dangerouslySetInnerHTML={{ __html: richText }} />
}

const Container = styled.div`
  /* border: 1px solid red; */
  overflow-wrap: break-word;
  width: 100%;
  > h1 {
    margin-bottom: 67px;
  }
  > h2 {
    padding-bottom: 0px;
    //margin-bottom: 5px !important;
  }
  > h3 {
    margin-bottom: 1rem;
  }
  > h4 {
    margin-bottom: 1rem;
  }
  > h5 {
    margin-bottom: 1rem;
  }
  > h6 {
    margin-bottom: 1rem;
  }
  > p {
    margin-bottom: 25px !important;
  }
  > hr {
    margin-bottom: 2rem;
  }
`
