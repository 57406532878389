import React from "react"
import styled from "styled-components"



function getId(url:string) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
  
    return match && match[2].length === 11 ? match[2] : null
  }


 const Video = ({ videoSrcURL, videoTitle, ...props }) =>{

    let videoEmbedSrc = ""
    const videoId = getId(videoSrcURL)
    videoEmbedSrc = "//www.youtube.com/embed/" + videoId

    return (
 <AspectRatio>


        <VideoContainer >
            <iframe className={'embedVideoContainer'}
            src={videoEmbedSrc}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            width='100%'
            height='100%'
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            />
        </VideoContainer>
        </AspectRatio>
  )
 }
export default Video

const AspectRatio = styled.div`
padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
/* border: 1px solid red; */
position:relative;
overflow: hidden;
margin-bottom: 20px;
`

const VideoContainer = styled.div`
position: absolute;
top: 0;
left: 0;
display: flex;
width: 100%;
min-height: 200px;
height:100%;

.embedVideo-container{
width: 100%;
height: 450px;


}


`