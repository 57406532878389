import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
import Video from '../shared/Video'
interface IVideoFlex {
  heading: string
  content: string
}

const VideoFlex: FunctionComponent<IVideoFlex> = ({ heading, content }) => {
  return (
    <MainGrid>
      <Container>
        <HeadingContainer><h4>{heading}</h4></HeadingContainer>
        {content && 
        <VideoContainer>
        <Video videoSrcURL={content} videoTitle={''} />
        </VideoContainer>
}
      </Container>
    </MainGrid>
  )
}

export default VideoFlex

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & h4 {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    max-width: 750px;
    margin: 50px auto 0px;

    & h4 {
      margin-right: 3rem;
      margin-bottom: 0px;
    }
  }
`
const HeadingContainer = styled.div`
width: 50%;
@media (max-width: 768px) {
  width: 100%;}
`
const VideoContainer = styled.div`
width: 50%;
@media (max-width: 768px) {
  width: 100%;}

`

