import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

const ImageWithTextBox = ({ data }: any) => {
  const Image = data.attrs['image']
  const ImageRaw = decodeURIComponent(Image)
  const ImageJson = JSON.parse(ImageRaw)

  const compData = {
    title: data.attrs.title,
    content: data.attrs.content,
    button: {
      text: data.attrs['button-text'],
      link: data.attrs['button-link'],
    },
    image: ImageJson,
  }

  return (
    <MainGrid>
      <Container>
        <Grid>
          {compData.image && (
            <LeftImage src={compData.image.url} alt={compData.image.alt} />
          )}
          <ContentBox>
            {compData.title && <Title>{compData.title}</Title>}
            {compData.content && <Content>{compData.content}</Content>}
            {compData.button.text && (
              <Button href={compData.button.link} secondary>
                {compData.button.text}
              </Button>
            )}
          </ContentBox>
        </Grid>
      </Container>
    </MainGrid>
  )
}

export default ImageWithTextBox

const Container = styled.div`
  padding: 50px 0;

  @media (min-width: 1200px) {
    padding: 100px 0;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(2 1fr);
  @media (min-width: 1200px) {
    grid-template-rows: 1;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    align-items: center;
  }
`

const LeftImage = styled.img`
  margin-bottom: -15%;
  @media (min-width: 1200px) {
    margin-bottom: unset;
    grid-column: 1/8;
  }
`
const ContentBox = styled.div`
  background: ${theme.text.main};
  padding: 50px 30px;
  border-radius: 7px;
  @media (min-width: 1200px) {
    border-radius: unset;
    grid-column: 8/13;
    padding: 80px 40px;
  }
`

const Title = styled.h3`
  color: white;
  padding-bottom: 20px;
`
const Content = styled.p`
  color: white;
  padding-bottom: 20px;
`
