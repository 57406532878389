import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import { TopHeading } from '../SolutionsCards/SolutionsCards'
import VerticalPadding from '../styling/VerticalPadding'

interface Card {
  number: string
  heading: string
  body: string
}

interface Content {
 
  cards: Array<Card>
}


const TheCourseNumbers: FunctionComponent<Content> = ({  cards }) => {


  const filteredCards = cards.filter(
    (element) => element.number !== undefined
  )
// console.log('length')
// console.log(cards)
// console.log(filteredCards)
  return (
    <MainGrid>
      <Container>
       
          
          <Grid columns={filteredCards.length}>
            {cards.length > 0 &&
              filteredCards.map((item, key) => {
                if (key < 4) {
                  return (
                    <>
                    {item.number?(

                      <Card key={key}>
                      {item.number && <CardNumber>{item.number}</CardNumber>}
                      {item.heading && (
                        <CardHeading>{item.heading}</CardHeading>
                        )}
                        {item.body && (
                          <CardBody>{item.body}</CardBody>
                          )}
                          </Card>
                          ):("")}
                          </>
                  )
                }
              })}
          </Grid>
      
      </Container>
    </MainGrid>
  )
}

export default TheCourseNumbers

const Container = styled.section`
  /* border-top: 1px solid ${theme.grey.keyline};
  border-bottom: 1px solid ${theme.grey.keyline}; */
`
const HeadingContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
  @media (min-width: 767px) {
    max-width: 750px;
    margin: 0 auto;
  }
`
const Heading = styled.h2`
  margin-bottom: 45px;
  @media (min-width: 1024px) {
    margin-bottom: 60px;
  }
`
interface gridprops{
  columns:number
}

const Grid = styled.div<gridprops>`

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 45px;
  width: ${(props) => (props.columns==2 ? '600px' : '')};
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Card = styled.div`
  border-top: 3px solid ${theme.brand.blue};
  padding: 34px 0 0;
max-width: 300px;
  @media (min-width: 1024px) {
    padding: 40px 0 0;
  }
`

const CardNumber = styled.p`
  font-size: 55px;
  font-weight: 300;
  line-height: 67px;
  margin-bottom: 14px;

  @media (min-width: 1024px) {
    font-size: 70px;
    line-height: 87px;
    margin-bottom: 5px;
  }
`

const CardHeading = styled.h5`
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    margin-bottom: 20px;
  }
`

const CardBody = styled.p`
  font-weight: 300;
`
