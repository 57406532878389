import React, { useState } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import LandingPageAccItem from './LandingPageAccItem'

const LandingPagesImageAccordion = ({ data }: any) => {
  const [clicked, setClicked] = useState(0)

  // console.log(data)

  const image = data.attrs['faq-image']
  const decodedImg = decodeURIComponent(image)
  const jsonImg = JSON.parse(decodedImg)

  const questionsArr = [
    {
      question: data.attrs['question-one'],
      answer: data.attrs['answer-one'],
    },
    {
      question: data.attrs['question-two'],
      answer: data.attrs['answer-two'],
    },
    {
      question: data.attrs['question-three'],
      answer: data.attrs['answer-three'],
    },
    {
      question: data.attrs['question-four'],
      answer: data.attrs['answer-four'],
    },
    {
      question: data.attrs['question-five'],
      answer: data.attrs['answer-five'],
    },
    {
      question: data.attrs['question-six'],
      answer: data.attrs['answer-six'],
    },
    {
      question: data.attrs['question-seven'],
      answer: data.attrs['answer-seven'],
    },
    {
      question: data.attrs['question-eight'],
      answer: data.attrs['answer-eight'],
    },
    {
      question: data.attrs['question-nine'],
      answer: data.attrs['answer-nine'],
    },
    {
      question: data.attrs['question-ten'],
      answer: data.attrs['answer-ten'],
    },
  ]

  const subHeader = data.attrs['faq-sub-header']
  const header = data.attrs['faq-header']

  const handleToggle = (key: any) => {
    if (clicked === key) {
      return setClicked(0)
    }

    setClicked(key)
  }

  return (
    <>
      <BgWraper>
        <MainGrid>
          <Container>
            <AccordSubHeader>{ subHeader }</AccordSubHeader>
            <AccordHeader>{ header }</AccordHeader>
            <Grid>
              <ImageContainer>
                {jsonImg && <Image src={jsonImg.url} alt={jsonImg.alt} />}
              </ImageContainer>
              <AccordionCol>
                {questionsArr.length > 0 && (
                  <FaqContainer>
                    {questionsArr.map((item, key) => (
                      <LandingPageAccItem
                        key={key}
                        title={item.question}
                        body={item.answer}
                        onToggle={() => handleToggle(key)}
                        active={clicked === key}
                      ></LandingPageAccItem>
                    ))}
                  </FaqContainer>
                )}
              </AccordionCol>
            </Grid>
          </Container>
        </MainGrid>
      </BgWraper>
    </>
  )
}

export default LandingPagesImageAccordion

const Container = styled.div`
  padding: 50px 0;
`

const BgWraper = styled.div`
  background: ${theme.background.grey};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2);

  @media (min-width: 1200px) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    align-items: center;
  }
`

const ImageContainer = styled.div``

const Image = styled.img`
  width: 100%;
`

const FaqContainer = styled.div`
  width: 100%;
`

const AccordionCol = styled.div`
  padding: 40px 0 40px 0;
  flex-direction: column;
  align-items: top;
  @media (max-width: 998px) {
    width: 100%;
    padding: 40px 0 0 0;
  }
`
const AccordHeader = styled.h3`
  text-align: center;
`

const AccordSubHeader = styled.p`
  text-align: center;
`