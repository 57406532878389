import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import arrow from '../../images/link-arrow.svg'
import { Link } from 'gatsby'
import { appendUrlPartnerTracking } from '../../util/url'

interface ICard {
  icon?: string
  heading?: string
  tag?: string
  body?: string
  link?: string
  leftAlign?: boolean
}

interface IBtn {
  url: string
  text: string
}

interface TrainProps {
  data: Array<ICard>
  buttons?: Array<IBtn>
}
const ThreeCardsLazyBlock: FunctionComponent<TrainProps> = ({ data }) => {
  const [charLimit, setCharLimit] = useState(240)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }
  const filteredData = data.filter(
    (element) => element.icon !== undefined
  )
  return (
    <Container>
      <MainGrid>
        {/* <TextDetails
          tag="Train your employees"
          heading="Phishing awareness training"
          content="Create a cyber security culture backed by science. At Bob’s Business, people science and elearning powerfully combine to create a human firewall where your employees train to become human sensors that identify and respond to threats."
        /> */}
        <Grid>
          {filteredData &&
            filteredData.map((card, key) => {
             
              const decodeIcon = decodeURIComponent(card.icon)
              const iconJson = JSON.parse(decodeIcon)
          
              return (
              
                  
                  
                  <Card key={key} leftAlign={data.leftAlign}>
                  {card.icon && <Icon src={iconJson.url} alt={iconJson.alt} />}
                  <Text>
                    {card.heading && <h5 style={{height:'50px'}}>{card.heading}</h5>}
                    {card.tag && <Blue>{card.tag}</Blue>}
                    {card.body && <p>{formatText(card.body)}</p>}
                  </Text>
                  {card.link && 
                   <LinkContainer
                   to={appendUrlPartnerTracking(card.link)}>
                   <b>Learn more </b>
                   <Arrow src={arrow} alt="Arrow link" />
                 </LinkContainer>}
                </Card>
             
               
         
             
              )
            })}
        </Grid>
        
      </MainGrid>
    </Container>
  )
}

export default ThreeCardsLazyBlock

const Container = styled.div`
  padding: 0 0 30px;

  @media (min-width: 1024px) {
    padding: 0 0 5rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
  }
`

interface ICardProps {
  leftAlign?: boolean
}

const Card = styled.div<ICardProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.leftAlign ? 'left' : 'center')};
  justify-content: space-between;
  background: ${theme.background.grey};
  padding: 40px 20px;
  border-bottom: 3px solid ${theme.text.main};
  margin-bottom: auto;
  height: 100%;
  
  text-align: ${(props) => (props.leftAlign ? 'left' : 'center')};
  @media (min-width: 1024px) {
    padding: 66px 27px 26px;
    margin-bottom: auto;
  }
`

const Icon = styled.img`
  max-height: 180px;
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  
  min-height: 200px;
  
  @media (min-width: 1024px) {
    margin: 37px auto 0;
  }

  & h5 {
    margin-bottom: 5px;
    
  }

  & p {
    margin-top: 14px;
  }
`

const Blue = styled.p`
  color: ${theme.brand.blue};
`


const Arrow = styled.img`
 margin-bottom: 17px;
  width: 50px;
  margin-left: 20px;
  `
  const LinkContainer = styled(Link)`
  
  &,
  &:link,
  &:visited {
    width: 100%;
    padding: 21px 0px 23px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 18px;
    color: ${theme.brand.blue};
    display: flex;
    align-items: right;
    justify-content: center;
   
    transition: background 0.2s ease;
    @media (min-width: 768px) {
    }
  }

  /* &:hover,
  &:active {
    background: ${theme.brand.blue10};
    color: ${theme.brand.blue};
    text-decoration: none;
  } */
`