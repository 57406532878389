import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopHeading } from '../SolutionsCards/SolutionsCards'
import SmallGrid from '../styling/SmallGrid'

interface TextProps {
  tag?: string
  heading: string
  content: string
  alignCenter?: boolean
}
const TextDetails: FunctionComponent<TextProps> = ({
  tag,
  heading,
  content,
  alignCenter,
}) => {
  return (
    <SmallGrid>
      <Container alignCenter={alignCenter}>
        {tag && (
          <TopHeading className="small-p" marginBottom="17px">
            {' '}
            {tag}
          </TopHeading>
        )}

        <Column alignCenter={alignCenter}>
          {heading && <Heading alignCenter={alignCenter}>{heading}</Heading>}
          {content && (
            <Body alignCenter={alignCenter} className="grey-p">
              {content}
            </Body>
          )}
        </Column>
      </Container>
    </SmallGrid>
  )
}

export default TextDetails

const Container = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.alignCenter ? 'center' : 'left')};
  padding: 50px 0;

  @media (max-width: 1024px) {
    padding: 5rem 0 5rem;
  }
  @media (max-width: 768px) {
    padding: 20px 0 30px 0;
  }
`

const Heading = styled.h2<ColumnProps>`
  margin-bottom: 14px;
  width: ${(props) => (props.alignCenter ? '100%' : '50%')};
  @media (min-width: 1024px) {
    margin-bottom: 28px;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const Body = styled.p<ColumnProps>`
  width: ${(props) => (props.alignCenter ? '100%' : '50%')};
  @media (max-width: 768px) {
    width: 100%;
  }
`

interface ColumnProps {
  marginBottom?: string
  alignCenter?: boolean
}
const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: ${(props) => (props.alignCenter ? 'column' : 'row')};
  column-gap: 10px;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: ${(props) => (props.alignCenter ? 'center' : 'left')};
    flex-direction: column;
    align-items: ${(props) => (props.alignCenter ? 'center' : 'left')};
    margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  }
`
