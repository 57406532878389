import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import LargeGrid from '../styling/LargeGrid'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import VerticalPadding from '../styling/VerticalPadding'
import arr from '../../images/link-arrow.svg'
import { appendUrlPartnerTracking } from '../../util/url'

interface iData {
  title: string
  body: string
  topIcon?: string
  topIconAlt?: string
  icon?: string
  iconAlt?: string
  ctaUrl: string
}
interface ProductsCardsProps {
  topHeadingMarginBottom: string
  topHeading?: string
  heading?: string
  data: Array<iData>
}

let jsonImg = null
function decodedImg(img) {
  const decodedImg = decodeURIComponent(img)
  jsonImg = JSON.parse(decodedImg)

  return (

    jsonImg
  )
}

const ProductsCards: FunctionComponent<ProductsCardsProps> = ({
  topHeadingMarginBottom,
  topHeading,
  heading,
  data,
}) => {

// console.log('image')
// console.log(data[1].topIcon)
 
  return (
    <Container>
      <VerticalPadding paddingTopDesktop={1.5}>
        <MainGrid>
          <HeadingContainer>
            {topHeading && (
              <TopHeading
                className="small-p"
                marginBottom={topHeadingMarginBottom}
              >
                {topHeading}
              </TopHeading>
            )}
            {heading && <Heading>{heading}</Heading>}
          </HeadingContainer>
        </MainGrid>
        <LargeGrid>
          <Grid templateDesktop={data.length > 4 ? 4 : data.length}>
            {data &&
              data.map((card, key) => {
                if (key + 1 <= 4) {
                  return (
                    <Card key={key}>
                      <CardTop>
                        {card.topIcon && (
                          <IconWrapper>
                            <TopIcon src={decodedImg(card.topIcon).url} alt={card.topIconAlt} />
                          </IconWrapper>
                        )}
                        {card.title && <CardTitle>{card.title}</CardTitle>}
                        {card.body && <CardBody>{card.body}</CardBody>}
                        {card.icon && (
                          <SupportIcon src={card.icon} alt={card.iconAlt} />
                        )}
                      </CardTop>
                      {card.ctaUrl && (
                        <LinkContainer
                          to={appendUrlPartnerTracking(card.ctaUrl)}
                        >
                          Learn more <Arrow src={arr} alt="Arrow link" />
                        </LinkContainer>
                      )}
                    </Card>
                  )
                }
              })}
          </Grid>
        </LargeGrid>
      </VerticalPadding>
    </Container>
  )
}

export default ProductsCards

const Container = styled.section``

interface TopHeadingProps {
  marginBottom?: string
}

export const TopHeading = styled.p<TopHeadingProps>`
  color: ${theme.grey.inputHover};
  font-weight: 300;

  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
`
const HeadingContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 460px;
  }
`

export const Heading = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 460px;
  }
`
interface GridProps {
  templateDesktop: number
}
const Grid = styled.div<templateDesktop>`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr;
  grid-column-gap: 0rem;
  grid-row-gap: 18px;
  margin: 24px auto 0;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin: 8rem auto 0;
  }
  @media screen and (min-width: 1024px) {
    grid-column-gap: 22px;
    grid-row-gap: 22px;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(${(props) => props.templateDesktop}, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
`

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.grey.heylineDots};
  transition: border 0.5s ease;
  &:before {
    content: '';
    height: 3px;
    width: calc(100% + 2px);
    position: relative;
    top: -1px;
    left: -1px;
  }

  &:first-of-type:before {
    background: ${theme.cards.green};
  }
  &:nth-of-type(2):before {
    background: ${theme.cards.red};
  }
  &:nth-of-type(3):before {
    background: ${theme.cards.yellow};
  }
  &:nth-of-type(4):before {
    background: ${theme.brand.blue};
  }

  &:first-of-type:hover {
    border: 1px solid ${theme.cards.green};
  }
  &:nth-of-type(2):hover {
    border: 1px solid ${theme.cards.red};
  }
  &:nth-of-type(3):hover {
    border: 1px solid ${theme.cards.yellow};
  }
  &:nth-of-type(4):hover {
    border: 1px solid ${theme.brand.blue};
  }
`
const CardTop = styled.div`
  padding: 30px 27px;
  height: 100%;

  @media (min-width: 768px) {
    padding: 60px 4rem 42px;
  }
`

const CardTitle = styled.h6`
  font-weight: bold;
  margin-bottom: 11px;
`

const CardBody = styled.p`
  color: ${theme.grey.inputHover};
`
const LinkContainer = styled(Link)`
  &,
  &:link,
  &:visited {
    border-top: 1px solid ${theme.grey.heylineDots};
    padding: 21px 27px 23px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 18px;
    color: ${theme.brand.blue};
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.2s ease;
    @media (min-width: 768px) {
      padding: 21px 4rem;
    }
  }

  &:hover,
  &:active {
    background: ${theme.brand.blue10};
    color: ${theme.brand.blue};
    text-decoration: none;
  }
`

const Arrow = styled.img`
  width: 25px;
  height: 20px;
`
const IconWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-bottom: 36px;
  }
`

const TopIcon = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 60px;
  }
`

const SupportIcon = styled.img`
  margin-top: 12px;
  height: 100%;
  max-height: 35px;
  @media (min-width: 768px) {
    margin-top: 14px;
    max-height: 67px;
  }
`
