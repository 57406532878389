
import theme from '../../styling/theme'
import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'




  const phishingdotsanimDot = (props) => keyframes`
  0% {
        
      }    
  40% {
    transform: translateX(100%);
      }
  48% {
    transform: translateX(100%);
  }
  80%{
    transform: translateX(0);  
  }   
  100% {        
    
    }
  `;
  const policydotsanimTail = (props) =>

  keyframes`
    
   0% {
      transform: translateX(0);  
        }
  
    40%{
      transform: translateX(100%); 
    }
    48%{
      transform: translateX(calc(100% + ${props.tailLength})); 
     
    }
    80%{
      
      transform: translateX(${props.tailLength});  
    }   
    88%{
      transform: translateX(0);  
    }   
    100% {
      transform: translateX(-${props.tailLength});  
      }
  
  `
  
  ;


export const PhishingLinesAnimation: FunctionComponent = ({ children }) => {

const [numberRows, setNumberOfRows] = useState(10)
const numberColumns = 1
useEffect(() => {
  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 767) {
          setNumberOfRows(6)
      }
    }
  }
  window.addEventListener("resize", handleResize); 
   handleResize();  
   return () => window.removeEventListener("resize", handleResize);
 }, []); // Empty array ensures that effect is only run on mount
 
function RingAnimationRow(rowIndex){
  const animTime = Math.random() * 4 + 4 ;   
  const tail =  (Math.random() * 300 + 100 ) + 'px'; 
  const delay = Math.random() + "s"
     return (
         <RingContainer>
                 <RingImageContainer  time={animTime + 's'} delay={delay} > <RingImg/> </RingImageContainer>
                   <TailContainer  time ={animTime  + 's'} delay={delay}  tailLength={tail}><Tail tailLength={tail} /></TailContainer>
                   <Line/>
        </RingContainer>
       ) 
    }

return (
    <MainContainer>
          { [...Array(numberRows)].map((elementInArray, rowIndex) => (                      
            <ImageRow>
                    { [...Array(numberColumns)].map((elementInArray, index) => ( 
                        RingAnimationRow(rowIndex)
                    ) )}
            </ImageRow>
                    ) )}
    </MainContainer>
  )
}

interface IImage {
  animate?: boolean
  delay?: string
  timing?: string
}
const MainContainer = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto 24px;
  position: relative;
 overflow: hidden;
  border-right: 4px solid white;
  padding: 10px 0 0px;
  @media (min-width: 768px) {
    max-width: 50%;
  padding: 40px 0 20px;
   
    margin: 0 auto;
  }
`

const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: left;
  
`
interface IAnimate { 
  time?: string
  tailLength?: string
  delay?: string
}

const RingImageContainer = styled.div<IAnimate>`
 position: absolute;
 left:-20px;  
 width: 100%;
 height: 20px;
  -webkit-animation: ${phishingdotsanimDot} ${props=>props.time} linear normal infinite;   
  -webkit-animation-delay: ${props=>props.delay} ;
  z-index: 5;
  `  
  const RingImg = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid ${theme.brand.blue};
  background-color: ${theme.text.main};
  border-radius: 50%;
  z-index: 20;
  `

const TailContainer = styled.div<IAnimate>`
position: absolute;
left:-${props=>props.tailLength};
width:100%;
  -webkit-animation: ${policydotsanimTail} ${props=>props.time}  linear normal infinite; 
  -webkit-animation-delay: ${props=>props.delay} ;
  z-index: 1;
`
const Tail = styled.div<IAnimate>`
width: ${props=>props.tailLength};
height: 3px;
margin-top: 8px;
 background-color: ${theme.brand.blue};
 z-index: 1;
`

const RingContainer = styled.div`
position: relative;
height: 40px;
width: 100%;
`
const Line = styled.div`
position: absolute;
left: 0;
width: 100%;
height: 2px;
margin-top: 8px;
opacity: 0.25;
//border-bottom: 1px dashed ${theme.grey.heylineDots};
background-image: linear-gradient(to right, white 53%, ${theme.text.main} 0%);
//background-position: bottom;
background-size: 15px 2px;
background-repeat: repeat-x;
z-index:0;
`
