import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import NCSClogo from '../../images/national-cyber-security-centre-alt.png'
import CCSSlogo from '../../images/crown-commercial-service-supplier-alt.png'
import Padlock from '../../images/secure-padlock.svg'
import Circle from '../../images/naught.svg'
import TrustBox from '../Header/Trustpilot'
import WireLineSVG from './Animations/WireLineSVG'
import { PillAnimationContainer } from '../PIllAnimation/PillAnimationContainer'

const LandingPageHeaderVOne = ({ data }: any) => {
  const logos = [
    {
      img: NCSClogo,
      alt: 'National Cyber Security Centre',
      url: '#',
    },
    {
      img: CCSSlogo,
      alt: 'Crown Commercial Service Supplier',
      url: '#',
    },
  ]

  const headerData = {
    title: data.attrs['header-title'],
    button: {
      text: data.attrs['button-text'],
      url: data.attrs['button-link'],
    },
    scrollerData: [
      { text: data.attrs['scroller-input-one'] },
      { text: data.attrs['scroller-input-two'] },
      { text: data.attrs['scroller-input-three'] },
      { text: data.attrs['scroller-input-four'] },
      { text: data.attrs['scroller-input-five'] },
    ],
    greySection: {
      overLabel: data.attrs['bottom-section-over-label'],
      title: data.attrs['bottom-section-title'],
      underlabel: data.attrs['bottom-section-under-label'],
      content: data.attrs['bottom-section-content'],
    },
  }

  return (
    <HeaderContainer>
      <Container>
        <MainGrid>
          <InnerContainer>
            <TextContainer>
              {headerData.title && <Heading>{headerData.title}</Heading>}
              {data.attrs['button-text'] && (
                <Button href={headerData.button.url}>
                  {headerData.button.text}
                </Button>
              )}
              <Grid>
                {logos.map((logo) => (
                  <LogoMask href={logo.url}>
                    <Logo src={logo.img} alt={logo.alt} />
                  </LogoMask>
                ))}
                <TrustBox darkMode={true} />
              </Grid>
            </TextContainer>
            {/* <ImgWrapper>
              <ScrollerWrapper>
                {headerData.scrollerData.map((data) => (
                  <ScrollerItem>{data.text}</ScrollerItem>
                ))}
              </ScrollerWrapper>
            </ImgWrapper> */}
            <div style={{ display: 'flex', justifyItems: 'center' }}>
              <PillAnimationContainer
                labels={[
                  'Malware',
                  'Phising',
                  'Data Breach',
                  'Remote Working',
                  'Data Breach',
                ]}
              />
            </div>
          </InnerContainer>
        </MainGrid>
      </Container>
      <Container>
        <MainGrid>
          <InnerContainer>
            <TextContainerTwo>
              {headerData.greySection.overLabel && (
                <OverLabel>{headerData.greySection.overLabel}</OverLabel>
              )}
              {headerData.greySection.title && (
                <BottomTitle>{headerData.greySection.title}</BottomTitle>
              )}
              {headerData.greySection.underlabel && (
                <UnderLabel>{headerData.greySection.underlabel}</UnderLabel>
              )}
            </TextContainerTwo>
            <RightSide>
              <TopAnimationWrapper>
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
                <WireLineSVG direction="down" />
              </TopAnimationWrapper>
              <KeylockContentWrapper>
                <PadlockICon src={Padlock} alt="Padlock icon" />
                {headerData.greySection.content && (
                  <KeylockContent>
                    {headerData.greySection.content}
                  </KeylockContent>
                )}
              </KeylockContentWrapper>
              <BottomAnimationWrapper>
                <WireLineSVG direction="up" />
                <WireLineSVG direction="up" />
                <WireLineSVG direction="up" />
                <WireLineSVG direction="up" />
              </BottomAnimationWrapper>
            </RightSide>
          </InnerContainer>
        </MainGrid>
      </Container>
    </HeaderContainer>
  )
}

export default LandingPageHeaderVOne

const HeaderContainer = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  background: ${theme.text.main};
  color: white;
  padding-bottom: 50px;
  @media (min-width: 1200px) {
    padding-bottom: unset;
  }
  &:nth-of-type(2) {
    background: ${theme.background.grey};
    padding: 70px 0;
    @media (min-width: 1200px) {
      padding: unset;
    }
  }

  &:nth-of-type(2) section div {
    flex-flow: column-reverse;
    @media (min-width: 1200px) {
      flex-flow: row;
    }
  }
  &:nth-of-type(2) section div div {
    flex-flow: column;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 0;
  align-items: center;
  @media (min-width: 1200px) {
    flex-direction: row;
    padding: 150px 0;
    align-items: center;
    height: 600px;
  }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-right: 5rem;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    margin-bottom: unset;

    & a {
      width: 200px;
    }
  }
`
const TextContainerTwo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-right: 0;
    max-width: 50%;
  }
  @media (min-width: 1200px) {
    margin-bottom: unset;

    & a {
      width: 200px;
    }
  }
`

const ImgWrapper = styled.div`
  background: url(${Circle}) no-repeat center;
  background-size: contain;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  @media (min-width: 1200px) {
    max-width: 60%;
    margin-bottom: 0;
  }
`
const ScrollerWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const ScrollerItem = styled.div`
  color: ${theme.text.main};
  text-align: center;
  background: white;
  padding: 10px 20vw;
  width: 100%;
  border: 1px solid grey;
  border-radius: 30px;
  font-weight: 700;
  z-index: 50;
  margin-bottom: 10px;

  @media (min-width: 1200px) {
    padding: 15px 90px;
    margin-bottom: 20px;
  }

  &:nth-of-type(3) {
    color: white;
    background: ${theme.text.main};
    border-color: ${theme.text.main};
  }
`

const Heading = styled.h1`
  margin-bottom: 34px;
  color: ${theme.text.white};

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`

const Grid = styled.div`
  display: flex;
  grid-column-gap: 10px;
  margin-top: 20px;
  align-items: center;
  @media (min-width: 768px) {
    justify-content: left;
  }
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
`

const LogoMask = styled.a`
  width: 30%;

  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    // border: 1px solid #4a4a4a;
    // border-radius: 3px;
    justify-content: center;
  }

  // &:hover,
  // &:active {
  //   border: 1px solid ${theme.brand.red};
  // }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`
const OverLabel = styled.p``

const BottomTitle = styled.h2`
  margin-top: 10px;
  @media (min-width: 1200px) {
    margin-top: 20px;
  }
`

const UnderLabel = styled.p``

const RightSide = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-self: end;
`

const KeylockContentWrapper = styled.div`
  background: white;
  padding: 40px 50px;
  max-width: 400px;
  max-height: 170px;
  border: 5px solid ${theme.brand.blue};
  z-index: 60;
  position: relative;
  margin-bottom: 50px;

  @media (min-width: 1200px) {
    margin-bottom: unset;
  }
`

const KeylockContent = styled.p`
  color: ${theme.brand.blue};
  font-weight: 700;
  text-align: center;
`
const TopAnimationWrapper = styled.div`
  & svg {
    top: -150px;
    position: absolute;
    right: 40%;
    z-index: 50;
    @media (min-width: 1200px) {
      top: -203%;
    }
  }
  & svg:nth-of-type(2) {
    right: 20%;
  }
  & svg:nth-of-type(3) {
    left: 20%;
  }
  & svg:nth-of-type(4) {
    left: 40%;
  }
`
const BottomAnimationWrapper = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
  & svg {
    top: -100px;
    position: absolute;
    right: 40%;
    z-index: 50;
    @media (min-width: 1200px) {
      top: 10%;
    }
  }
  & svg:nth-of-type(2) {
    right: 20%;
  }
  & svg:nth-of-type(3) {
    left: 20%;
  }
  & svg:nth-of-type(4) {
    left: 40%;
  }
`
const PadlockICon = styled.img`
  position: absolute;
  max-width: 25px;
  left: 50%;
  top: -14%;
  transform: translateX(-50%);
`
