import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { appendUrlPartnerTracking } from '../../../util/url'
import theme from '../../styling/theme'

export interface BreadCrumbItem {
  title: string
  url: string
}
interface IBreadcrumb {
  data: Array<BreadCrumbItem>
  color?: string
}
const Breadcrumb = ({ data, color }: IBreadcrumb) => {
  return (
    <Container color={color}>
      {data &&
        data.map((item, key: number) => {
          return (
            <React.Fragment key={key}>
              <LinkBreadcrumb
                href={
                  key !== data.length - 1
                    ? appendUrlPartnerTracking(item.url)
                    : ''
                }
                key={key}
                color={color}
              >
                {item.title}
              </LinkBreadcrumb>
              <span>/</span>
            </React.Fragment>
          )
        })}
    </Container>
  )
}

export default Breadcrumb

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  margin: 0 20px;
  position: relative;
  & span {
    margin: 0 8px;
    font-size: 9px;
    line-height: 11px;
    @media (min-width: 1024px) {
      color: ${(props) => (props.color ? props.color : theme.text.main)};
    }

    &:last-of-type {
      display: none;
    }

    @media (min-width: 1024px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &::after {
    content: '';
    display: none;
    height: 1px;
    border-top: 1px solid ${theme.brand.blue};
    position: absolute;
    bottom: 0;
    left: 20px;
    margin: 0 auto;
    @media (min-width: 1024px) {
      width: 30%;
      max-width: 263px;
      margin: 0;
      left: 0;
      display: block;
    }
  }
  @media (min-width: 768px) {
    margin: 0 3%;
  }
  @media (min-width: 1024px) {
    padding: 20px 0;
    margin: 0;
  }
`

const LinkBreadcrumb = styled(Link)`
  &,
  &:link,
  &:visited {
    color: ${(props) => (props.color ? props.color : theme.text.main)};

    font-size: 9px;
    line-height: 11px;
    display: inline;

    @media (min-width: 1024px) {
      font-size: 12px;
      line-height: 15px;
      color: ${(props) => (props.color ? props.color : theme.text.main)};
    }
  }

  &:hover,
  &:active {
    color: ${theme.brand.blue};
  }
  &:last-of-type {
    &:hover,
    &:active {
      text-decoration: none;
      cursor: default;
      color: ${theme.text.main};
      @media (min-width: 1024px) {
        color: ${(props) => (props.color ? props.color : theme.text.main)};
      }
    }
  }
`
