import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

const IconsRow = ({ data }: any) => {
  const logo1 = data.attrs['logo-one']
  const logo2 = data.attrs['logo-two']
  const logo3 = data.attrs['logo-three']
  const logo4 = data.attrs['logo-four']
  const logo5 = data.attrs['logo-five']

  const logo1Raw = decodeURIComponent(logo1)
  const logo1Json = JSON.parse(logo1Raw)

  const logo2Raw = decodeURIComponent(logo2)
  const logo2Json = JSON.parse(logo2Raw)

  const logo3Raw = decodeURIComponent(logo3)
  const logo3Json = JSON.parse(logo3Raw)

  const logo4Raw = decodeURIComponent(logo4)
  const logo4Json = JSON.parse(logo4Raw)

  const logo5Raw = decodeURIComponent(logo5)
  const logo5Json = JSON.parse(logo5Raw)

  const logos = [
    {
      image: logo1Json,
      alt: logo1Json.alt,
    },
    {
      image: logo2Json,
      alt: logo2Json.alt,
    },
    {
      image: logo3Json,
      alt: logo3Json.alt,
    },
    {
      image: logo4Json,
      alt: logo4Json.alt,
    },
    {
      image: logo5Json,
      alt: logo5Json.alt,
    },
  ]

  return (
    <MainGrid>
      <Container>
        <Header>We are proud to work with</Header>
        <LogoContainer>
          {logos.map((logo) =>
            logo != null ? (
              <Mask key={logo.image.url +"icon-rows-icons"}>
                <Logo src={logo.image.url} alt={logo.alt} />
              </Mask>
            ) : null
          )}
        </LogoContainer>
      </Container>
    </MainGrid>
  )
}

export default IconsRow

const Container = styled.div`
  padding: 50px 0;
`

const Header = styled.p`
  padding-bottom: 30px;

  @media (min-width: 1200px) {
    padding-bottom: 0;
  }
`

const LogoContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  padding: 1px 0;
  align-items: center;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  background: ${theme.grey.keyline};

  & img {
  }

  & div:last-child {
    display: none;
  }

  @media (min-width: 1200px) {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(5, 1fr);
    background: white;
    grid-column-gap: 9rem;
    border-bottom: 1px solid black;
    & div:last-child {
      display: flex;
    }
  }
`

const Mask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  padding: 30px 30px;

  @media (min-width: 1200px) {
    padding: 30px 0;
  }
`

const Logo = styled.img`
  max-width: 90px;

  @media (min-width: 1200px) {
    max-width: 150px;
  }
`
