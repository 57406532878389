import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import circle from '../../images/email-circle.svg'
import MainGrid from '../styling/MainGrid'
import dots from '../../images/3dots.svg'
import dotBlue from '../../images/dot-b.svg'
import { FunctionComponent } from 'react'

interface PhishingProps {
  heading1: string
  heading2: string
  heading3: string
  subHeading2: string
  subHeading3: string
  body2: string
  body3: string
  image: string
}

const PhishingEmailAnimationSec: FunctionComponent<PhishingProps> = ({
  heading1,
  heading2,
  heading3,
  subHeading2,
  subHeading3,
  body2,
  body3,
  image,
}) => {
  const decodedImage = decodeURIComponent(image)
  const parsedImage = JSON.parse(decodedImage)
  return (
    <Container>
      <MainGrid>
        <Row>
          <FirstCircleandAnimContainer>
            <CircleContainer>
              <b>3.4</b>
              <p>billion</p>
            </CircleContainer>
            <Animation1>
              <LineContainer>
                <Line />
                <DotsMask>
                  <Dots
                    src={dots}
                    alt="3 Green Dots moving continuous from left to right"
                  />
                </DotsMask>
              </LineContainer>

              <LineContainer2>
                <Line />
                <DotsMask>
                  <Dots
                    src={dots}
                    alt="3 Green Dots moving continuous from left to right"
                  />
                </DotsMask>
              </LineContainer2>

              <LineContainer3>
                <Line />
                <DotsMask>
                  <Dots
                    src={dots}
                    alt="3 Green Dots moving continuous from left to right"
                  />
                </DotsMask>
              </LineContainer3>
            </Animation1>
          </FirstCircleandAnimContainer>
          <VerticalLine />

          <Animation2>
            <LineContainer4>
              <Line />
              <DotsMask>
                <Dots
                  src={dotBlue}
                  alt="3 Green Dots moving continuous from left to right"
                />
              </DotsMask>
            </LineContainer4>

            <LineContainer5>
              <Line />
              <DotsMask>
                <Dots
                  src={dotBlue}
                  alt="3 Green Dots moving continuous from left to right"
                />
              </DotsMask>
            </LineContainer5>
          </Animation2>

          <VerticalLine2 />

          <GridPix src={parsedImage.url}></GridPix>
        </Row>

        <BottomRow>
          <Column>
            <Heading>{heading1}</Heading>
            <SmallHeading></SmallHeading>
            <Text></Text>
          </Column>
          <Column2>
            <Heading>{heading2}</Heading>
            <SmallHeading>{subHeading2}</SmallHeading>
            <Text>{body2}</Text>
          </Column2>
          <Column3>
            <Heading>{heading3}</Heading>
            <SmallHeading>{subHeading3}</SmallHeading>
            <Text>{body3}</Text>
          </Column3>
        </BottomRow>
      </MainGrid>
    </Container>
  )
}

export default PhishingEmailAnimationSec

const Container = styled.section`
  max-width: 100%;
`

const Row = styled.div`
  display: flex;
  height: 240px;
  margin-top: 24px;
  @media (max-width: 1000px) {
    margin-top: 0px;
  }
  @media (max-width: 480px) {
    height: 180px;
  }
`

const BottomRow = styled.div`
  display: flex;
  margin-top: 24px;
  border-bottom: 1px solid ${theme.grey.keyline};
  padding-bottom: 48px;
  @media (max-width: 1000px) {
    margin-top: 0px;
  }
`

const FirstCircleandAnimContainer = styled.div`
  width: 40%;
  display: flex;
  @media (max-width: 1000px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const CircleContainer = styled.div`
  width: 50%;

  height: 200px;
  background: url(${circle});
  background-repeat: no-repeat;
  background-attachment: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 40%;
  }
  @media (max-width: 480px) {
    height: 150px;
  }
  & b {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 44px;
    color: white;
  }
  & p {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    color: white;
  }
`

const Animation1 = styled.div`
  position: relative;
  width: 50%;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 60%;
  }
`

const Animation2 = styled.div`
  position: relative;
  display: flex;
  height: 240px;
  width: 30%;
  overflow: hidden;
  @media (max-width: 1000px) {
    display: none;
  }
`

const GridPix = styled.img`
  height: auto;
  width:30%;
  position: relative;
  transform: translateY(-15px);
  padding: 20px;
  @media (max-width: 1000px) {
    width: auto;
    height: 240px;
    padding-right: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

const LineContainer = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 37%;
  //left: 20%;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  @media (max-width: 768px) {
    display: block;
    transform: rotate(0deg);
    width: 100%;
    top: 40%;
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #00a3c7 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const LineContainer2 = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 12%;
  //left: 20%;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  @media (max-width: 768px) {
    display: block;
    transform: rotate(0deg);
    width: 100%;
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #00a3c7 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const LineContainer3 = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 62%;
  //left: 20%;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  @media (max-width: 768px) {
    display: block;
    transform: rotate(0deg);
    width: 100%;
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #00a3c7 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const LineContainer4 = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 48%;
  //left: 28%;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #00a3c7 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const LineContainer5 = styled.div`
  height: 6px;
  width: 100%;
  position: relative;
  top: 25%;
  //left: 28%;
  transform: rotate(90deg);
  z-index: -1;
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    transform: rotate(0);
  }
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0%;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #00a3c7 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 1px;
    background-repeat: repeat-x;
  }
`

const VerticalLine = styled.div`
  border: 1px solid #00a3c7;
  position: relative;
  //left: 6%;
  transform: translateY(-15px);

  @media (max-width: 768px) {
    display: none;
  }
`

const VerticalLine2 = styled.div`
  border: 1px solid black;
  position: relative;
  // left: 8%;
  transform: translateY(-15px);
  @media (max-width: 1000px) {
    display: none;
  }
`

const Line = styled.div`
  width: 100%;
  height: 5px;
  background: repeating-linear-gradient(
    45deg,
    rgba(216, 216, 216, 0.2),
    rgba(216, 216, 216, 0.2) 2px,
    #ffffff 2px,
    #ffffff 4px
  );
  @media (max-width: 1000px) {
    height: 3px;
  }
`

const DotsMask = styled.div`
  height: 9px;
  display: flex;
  position: relative;
  top: -8px;

  @media (max-width: 768px) {
    display: flex;

    transform: rotate(-90deg);
  }
  animation: animateDots 3s ease-in-out infinite;
  @keyframes animateDots {
    0% {
      transform: translateX(-10%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`

const Dots = styled.img`
  width: 22px;
  height: 9px;
  transform: rotate(0deg);
  @media (min-width: 768px) {
    transform: rotate(90);
    width: 20px;
    height: 6px;
  }
`

const Column = styled.div`
  margin-top: 50px;
  width: 40%;

  @media (max-width: 1000px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    width: 100%;
    /* transform: translateY(-270px); */
  }
`

const Column2 = styled.div`
  margin-top: 50px;
  width: 30%;
  padding: 0 20px;
  & h6 {
    color: #00a3c7;
    margin: 5px 0 15px 0;
  }
  @media (max-width: 1000px) {
    display: none;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`

const Column3 = styled.div`
  margin-top: 50px;
  width: 30%;
  padding: 0 0px 0 20px;
  & h6 {
    color: #00a3c7;
    margin: 5px 0 15px 0;
  }
  & p {
    padding-right: 0px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1000px) {
    width: 40%;
    height: 240px;
    padding-right: 20px;
  }
`

const Heading = styled.h5``

const SmallHeading = styled.h6``

const Text = styled.p``
