import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
interface ImageTextProps {
  Image?: any
  Content: string
  LeftImage?: boolean
  blueText?: string
}

const UseCasesImageText: FunctionComponent<ImageTextProps> = ({
  Content,
  Image,

  LeftImage,
}) => {
  var imageJson;

  if (Image != undefined){
    const imageDecoded = decodeURIComponent(Image)
      imageJson = JSON.parse(imageDecoded)

  }
  return (
    
      <Container>
        <ImageContainer>
          {Image &&(
            <MainImage src={imageJson.url}></MainImage>
          )}
        </ImageContainer>

        <Col>
          <RichTextContainer richText={Content} />
        </Col>
      </Container>
   
  )
}

export default UseCasesImageText


const Container = styled.div`
 
  padding: 0;
  display: flex;
  flex-direction:row;
  margin: 0;

 
`

const MainImage = styled.img`
  height: auto;
  width: auto;

  
`
const Col = styled.div`
  width: 60%;
  
  flex-direction: column;
  //padding-right: 40px;
  align-items: center;
  vertical-align: center;
 
`
const ImageContainer = styled.div`
  display: flex;
  width: 40%;

  flex-direction: column;
  //padding-right: 40px;
  align-items: flex-start;
  justify-content: center;
 //vertical-align: center; 

`
