import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import Dialog from '@material-ui/core/Dialog'
import theme from '../styling/theme'

import tableInfo from '../../buildAssets/compare-table/table-info.svg'
import tableTick from '../../buildAssets/compare-table/table-tick.svg'
import tableCross from '../../buildAssets/compare-table/table-cross.svg'

interface CompareTableProps {
  content: string
  helpText: string
  bobs: string
  compOne: boolean
  compTwo: boolean
  compThree: boolean
  compFour: boolean
}

const CompareTable = ({ data }: any) => {
  const bobsLogo = data.attrs['bobs-business']
  const imageRaw = decodeURIComponent(bobsLogo)
  const bobJson = JSON.parse(imageRaw)

  // console.log(data)

  const tableData: any = {
    competitors: [
      data.attrs['competitor-1'],
      data.attrs['competitor-2'],
      data.attrs['competitor-3'],
      data.attrs['competitor-4'],
    ],
    rows: [
      {
        content: data.attrs['row-one-content'],
        helpText: data.attrs['row-one-help-text'],
        bobs: data.attrs['row-one-included-in-bobs'],
        compOne: data.attrs['row-one-included-in-competitor-one'],
        compTwo: data.attrs['row-one-included-in-competitor-two'],
        compThree: data.attrs['row-one-included-in-competitor-three'],
        compFour: data.attrs['row-one-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-two-content'],
        helpText: data.attrs['row-two-help-text'],
        bobs: data.attrs['row-two-included-in-bobs'],
        compOne: data.attrs['row-two-included-in-competitor-one'],
        compTwo: data.attrs['row-two-included-in-competitor-two'],
        compThree: data.attrs['row-two-included-in-competitor-three'],
        compFour: data.attrs['row-two-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-three-content'],
        helpText: data.attrs['row-three-help-text'],
        bobs: data.attrs['row-three-included-in-bobs'],
        compOne: data.attrs['row-three-included-in-competitor-one'],
        compTwo: data.attrs['row-three-included-in-competitor-two'],
        compThree: data.attrs['row-three-included-in-competitor-three'],
        compFour: data.attrs['row-three-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-four-content'],
        helpText: data.attrs['row-four-help-text'],
        bobs: data.attrs['row-four-included-in-bobs'],
        compOne: data.attrs['row-four-included-in-competitor-one'],
        compTwo: data.attrs['row-four-included-in-competitor-two'],
        compThree: data.attrs['row-four-included-in-competitor-three'],
        compFour: data.attrs['row-four-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-five-content'],
        helpText: data.attrs['row-five-help-text'],
        bobs: data.attrs['row-five-included-in-bobs'],
        compOne: data.attrs['row-five-included-in-competitor-one'],
        compTwo: data.attrs['row-five-included-in-competitor-two'],
        compThree: data.attrs['row-five-included-in-competitor-three'],
        compFour: data.attrs['row-five-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-six-content'],
        helpText: data.attrs['row-six-help-text'],
        bobs: data.attrs['row-six-included-in-bobs'],
        compOne: data.attrs['row-six-included-in-competitor-one'],
        compTwo: data.attrs['row-six-included-in-competitor-two'],
        compThree: data.attrs['row-six-included-in-competitor-three'],
        compFour: data.attrs['row-six-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-seven-content'],
        helpText: data.attrs['row-seven-help-text'],
        bobs: data.attrs['row-seven-included-in-bobs'],
        compOne: data.attrs['row-seven-included-in-competitor-one'],
        compTwo: data.attrs['row-seven-included-in-competitor-two'],
        compThree: data.attrs['row-seven-included-in-competitor-three'],
        compFour: data.attrs['row-seven-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-eight-content'],
        helpText: data.attrs['row-eight-help-text'],
        bobs: data.attrs['row-eight-included-in-bobs'],
        compOne: data.attrs['row-eight-included-in-competitor-one'],
        compTwo: data.attrs['row-eight-included-in-competitor-two'],
        compThree: data.attrs['row-eight-included-in-competitor-three'],
        compFour: data.attrs['row-eight-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-nine-content'],
        helpText: data.attrs['row-nine-help-text'],
        bobs: data.attrs['row-nine-included-in-bobs'],
        compOne: data.attrs['row-nine-included-in-competitor-one'],
        compTwo: data.attrs['row-nine-included-in-competitor-two'],
        compThree: data.attrs['row-nine-included-in-competitor-three'],
        compFour: data.attrs['row-nine-included-in-competitor-four'],
      },
      {
        content: data.attrs['row-ten-content'],
        helpText: data.attrs['row-ten-help-text'],
        bobs: data.attrs['row-ten-included-in-bobs'],
        compOne: data.attrs['row-ten-included-in-competitor-one'],
        compTwo: data.attrs['row-ten-included-in-competitor-two'],
        compThree: data.attrs['row-ten-included-in-competitor-three'],
        compFour: data.attrs['row-ten-included-in-competitor-four'],
      },
    ],
  }

  return (
    <TableContainer>
      <MainGrid>
        <Table>
          <THead>
            <tr>
              <th></th>
              <th>
                <ContentImg src={bobJson.url} alt={bobJson.alt} />
              </th>
              {tableData.competitors.map((competitor) => (
                <th>{competitor}</th>
              ))}
            </tr>
          </THead>

          <TBody>
            {tableData.rows.map((row) => {
              if (row.content) {
                return (
                  <tr key={row.content}>
                    <td>
                      <div>
                        <p>{row.content} </p>
                        <DialogInfoIcon text={row.helpText} />
                      </div>
                    </td>

                    <TableCellIcon
                      value={row.bobs}
                      style={{ backgroundColor: '#F5F7FA' }}
                    />
                    <TableCellIcon value={row.compOne} />
                    <TableCellIcon value={row.compTwo} />
                    <TableCellIcon value={row.compThree} />
                    <TableCellIcon value={row.compFour} />
                  </tr>
                )
              }
            })}
          </TBody>
        </Table>
      </MainGrid>
    </TableContainer>
  )
}

const TableCellIcon = ({ value, style }: { value: boolean; style: any }) => {
  return (
    <td style={style}>
      <img src={value ? tableTick : tableCross} />
    </td>
  )
}

const DialogInfoIcon = ({ text }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <InfoImg src={tableInfo} onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogueText>{text}</DialogueText>
      </Dialog>
    </>
  )
}

export default CompareTable

const TableContainer = styled.div`
  padding: 50px 0;
  overflow-x: auto;
`
const Table = styled.table`
  width: 100%;

  @media only screen and (min-width: 800px) {
    table-layout: fixed;
  }
`
const THead = styled.thead`
  background: ${theme.text.main};
  text-align: center;

  tr th:first-of-type {
    width: 250px;
  }

  th {
    color: ${theme.text.white};
    vertical-align: middle;
    min-width: 100px;
    border: 1px solid #83838a;
  }
`
const TBody = styled.tbody`
  width: 100%;

  td {
    min-width: 100px;
    border-collapse: collapse;
    border: 1px solid #e8e8e8;
  }

  td:first-of-type div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  td:first-of-type {
    padding-top: 25px;
    padding-right: 10px;

    color: #1d253c;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;

    border-left: 0px;
  }

  td:last-of-type {
    border-right: 0px;
  }

  tr:last-of-type td {
    border-bottom: 1px solid #2e2e3a;
  }

  td + td img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  }
`

const ContentImg = styled.img`
  width: 150px;
  min-width: 100px;
`

const InfoImg = styled.img`
  width: 23px;
  min-width: 23px;
  transition: opacity 0.1s;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

const DialogueText = styled.div`
  padding: 100px;
  font-size: 18px;
`
