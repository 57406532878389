import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'

import cyberImg from '../../images/solutions/header-cyber.svg'
import phishingImg from '../../images/solutions/header-phishing-simulations.svg'
import policyImg from '../../images/solutions/header-policy-management.svg'
import complianceImg from '../../images/solutions/header-compliance.svg'
import { appendUrlPartnerTracking } from '../../util/url'

import logo1 from '../../images/product-header/product-bob-culture.png'
import logo2 from '../../images/product-header/product-bob-phishing.png'
import logo3 from '../../images/product-header/product-compliance.png'
import { RotatingRingsAnimation } from './ServiceHeaderAnimations/RotatingRings'
import { RotatingDotsAnimation } from './ServiceHeaderAnimations/RotatingDots'
import Breadcrumb, { BreadCrumbItem } from '../shared/Breadcrumb/Breadcrumb'
import { DotsRowAnimation } from './ServiceHeaderAnimations/DotsRowAnimation'
import { PhishingLinesAnimation } from './ServiceHeaderAnimations/PhishingLinesAnimation'
import DotsAnimation from '../DotsAnimation/DotsAnimation'

const bobLogos = [
  {
    img: logo1,
    alt: 'Bob Culture logo',
    url: appendUrlPartnerTracking('/products/bobs-culture'),
  },
  {
    img: logo2,
    alt: 'Bob Phishing',
    url: appendUrlPartnerTracking('/products/bobs-phishing'),
  },
  {
    img: logo3,
    alt: 'Bob Compliance',
    url: appendUrlPartnerTracking('/products/bobs-compliance'),
  },
]

interface IBread {
  title: string
  url: string
}

interface ILogo {
  img: string
  alt: string
  url: string
}
interface IProductHeader {
  tag?: string
  title?: string
  ctaUrl1: string
  ctaText1?: string
  ctaUrl2: string
  ctaText2?: string
  animationType: string
  breadcrumb?: string
  culture?: boolean
  phishing?: boolean
  compliance?: boolean
}
const ServiceHeader: React.FC<IProductHeader> = ({
  tag,
  title,
  ctaUrl1,
  ctaText1,
  ctaUrl2,
  ctaText2,
  animationType,
  breadcrumb,
  phishing,
  culture,
  compliance,
}) => {
  const BreadCrumData: BreadCrumbItem[] = [
    { url: '/', title: 'Home' },
    { url: '', title: breadcrumb ? breadcrumb : '' },
  ]

  return (
    <Container>
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={BreadCrumData} color="white" />
      </MainGrid>
      <MainGrid>
        <InnerContainer>
          <TextContainer>
            {tag && <Tag className="small-p">{tag}</Tag>}
            {title && <Heading>{title}</Heading>}
            {ctaText1 && (
              <ButtonContainer>
                <Button href={appendUrlPartnerTracking(ctaUrl1)}>
                  {ctaText1}
                </Button>

                {ctaText2 && (
                  <Button href={appendUrlPartnerTracking(ctaUrl2)} secondary>
                    {ctaText2}
                  </Button>
                )}
              </ButtonContainer>
            )}

            {(culture || phishing || compliance) && (
              <Included className="small-p">Included in ...</Included>
            )}

            <Grid>
              {culture && (
                <LogoMask href={bobLogos[0].url}>
                  <Logo src={bobLogos[0].img} alt={bobLogos[0].alt} />
                </LogoMask>
              )}
              {phishing && (
                <LogoMask href={bobLogos[1].url}>
                  <Logo src={bobLogos[1].img} alt={bobLogos[1].alt} />
                </LogoMask>
              )}
              {compliance && (
                <LogoMask href={bobLogos[2].url}>
                  <Logo src={bobLogos[2].img} alt={bobLogos[2].alt} />
                </LogoMask>
              )}
            </Grid>
          </TextContainer>

                
          {animationType == 'RotatingRings' && (          
            <RotatingRingsAnimation/>
            )}
          {animationType == 'DottedLines' && (
            <PhishingLinesAnimation/>         
          )}
          {animationType == 'DotsRow' && (       
            <DotsRowAnimation/>
          )}
          {animationType == 'Bearing' && (           
              <RotatingDotsAnimation />       
          )}
        </InnerContainer>
      </MainGrid>
    </Container>
  )
}

export default ServiceHeader

const Container = styled.div`
  background: ${theme.text.main};
  /* border: 1px solid red; */
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 34px 0 50px;
  margin-bottom: 40px;
  overflow: hidden;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 34px 0 75px;
  }
  @media (min-width: 1024px) {
    padding: 18px 0 75px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  @media (min-width: 768px) {
    text-align: left;
    margin-right: 5rem;
    max-width: 50%;
  }
`

const Tag = styled.p`
  margin-bottom: 10px;
  color: ${theme.text.white};
  font-weight: 300;
  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`

const Heading = styled.h1`
  margin-bottom: 34px;
  color: ${theme.text.white};

  @media (min-width: 768px) {
    margin-bottom: 44px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 420px;
  }
`

const ImageContainer = styled.div`
  width: 60%;
  height: 60%;
  margin: 0 auto 24px;
  position: relative;
  @media (min-width: 768px) {
    max-width: 50%;
    margin: 0 auto;
  }
`

const Grid = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  //grid-template-columns: repeat(3, 100px);
  grid-column-gap: 10px;
  //margin: 0;
  @media (min-width: 768px) {
    justify-content: left;
    // grid-template-columns: repeat(3, 132px);
  }
`
const LogoMask = styled.a`
  width: 30%;

  &,
  &:link,
  &:visited {
    display: flex;
    padding: 10px 0px 10px;
    width: auto;
    border: 1px solid #4a4a4a;
    border-radius: 3px;
    justify-content: center;
  }

  &:hover,
  &:active {
    border: 1px solid ${theme.brand.red};
  }
`

const Logo = styled.img`
  width: auto;
  padding: 0 10px;
  max-height: 50px;
`

const Included = styled.p`
  color: white;
  font-weight: 300;
  margin: 30px 0 6px;
`
const PhishingAnimationContainer = styled(ImageContainer)``

const PhishImg = styled.img``
const CyberImg = styled.img`
 Width: 100%;
`
