import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Breadcrumb, { BreadCrumbItem } from '../shared/Breadcrumb/Breadcrumb'
import MainGrid from '../styling/MainGrid'
import imageDesktop from '../../buildAssets/logos/general/generice-background-lg.png'
import imageHeaderMob from '../../buildAssets/logos/general/generice-background-sm.png'
import Button from '../../components/shared/Button/Button'
import { appendUrlPartnerTracking } from '../../util/url'
import { ContactlessOutlined } from '@material-ui/icons'

interface ProductsSubHeaderProps {
  smallText: string
  heading: string
  subheading: string
  productLogo: string
  ctaUrl1: string
  ctaText1?: string
  ctaUrl2: string
  ctaText2?: string
  breadcrumb: string
}

const ProductsSubHeader: FunctionComponent<ProductsSubHeaderProps> = ({
  productLogo,
  smallText,
  heading,
  subheading,
  ctaUrl1,
  ctaText1,
  ctaUrl2,
  ctaText2,
  breadcrumb,
}) => {
  const BreadCrumData: BreadCrumbItem[] = [
    { url: '/', title: 'Home' },
    { url: '', title: breadcrumb ? breadcrumb : '' },
  ]
  let productJson = null
  if (productLogo) {
    const productRaw = decodeURIComponent(productLogo)
    productJson = JSON.parse(productRaw)
  }


var compliance = false;

if (breadcrumb === 'Bob’s Compliance'){
compliance = true

}


               
  // decodeURIproductLogo
  
  return (
    <Container imageMob={imageHeaderMob} imageDesktop={imageDesktop}>
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={BreadCrumData} color={'white'} />
      </MainGrid>
      <MainGrid>
        <TextContainer>
          {productLogo && <ProductLogo src={productJson.url} />}
          {smallText && <SmallText className="small-p">{smallText}</SmallText>}

          {heading && <Heading>{heading}</Heading>}

          {subheading && <Body>{subheading}</Body>}
          {ctaText1 && (
            <ButtonContainer>
  
              {compliance == true? (
         

                      <a onClick={()=> window.open(appendUrlPartnerTracking(ctaUrl1), "_blank")}>
                      <Button>
                        {ctaText1}
                    </Button>
                    </a>
              ):(
                <Button href={appendUrlPartnerTracking(ctaUrl1)}>
                {ctaText1}
              </Button>
                )}
           

              {ctaUrl2 && (
                <Button href={appendUrlPartnerTracking(ctaUrl2)} secondary>
                  {ctaText2}
                </Button>
              )}
            </ButtonContainer>
          )}
        </TextContainer>
      </MainGrid>
    </Container>
  )
}

export default ProductsSubHeader

interface ContainerProps {
  imageDesktop: string
  imageMob: string
}

const Container = styled.div<ContainerProps>`
  margin-bottom: 40px;
  background-image: url(${(props) => props.imageMob});
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 1024px) {
    background-image: url(${(props) => props.imageDesktop});
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 0 65px;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 0 84px;
    max-width: 56rem;
  }
`
const ProductLogo = styled.img`
  width: 150px;
`

const SmallText = styled.p`
  margin-bottom: 15px;
  font-weight: 300;
  color: white;
`

const Heading = styled.h1`
  margin-bottom: 16px;
  color: white;
  @media (min-width: 1024px) {
    margin-bottom: 11px;
  }
`

const Body = styled.p`
  color: white;
  margin-bottom: 22px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
& a{
  width: 100%;
}
  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 420px;
  }
`
