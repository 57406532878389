import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PillAnimationItem } from './PillAnimationItem'
import { useWindowSize } from '../../util/useWindowSize'
import { v4 as uuidv4 } from 'uuid'
interface PillsAnimationContainerInterface {
  labels: string[]
}

export const PillAnimationContainer: React.FC<PillsAnimationContainerInterface> = ({
  labels,
}) => {
  let posCount = -(labels.length - 1)
  const pillsInit = []
  labels.forEach((label) => {
    pillsInit.push({
      label: label,
      position: posCount,
      key: uuidv4(),
    })
    posCount = posCount + 1
  })
  labels.forEach((label) => {
    pillsInit.push({
      label: label,
      position: posCount,
      key: uuidv4(),
    })
    posCount = posCount + 1
  })

  const [count, setCount] = useState(0)
  const [containerHeight, setContainerHeight] = useState(385)

  // console.log(containerHeight)

  const windowSize = useWindowSize()

  useEffect(() => {
    // console.log(windowSize)
    if (windowSize[0] > 1200) {
      setContainerHeight(385)
    } else {
      setContainerHeight(270)
    }
  }, [windowSize])

  const [pills, setPills] = useState(pillsInit)
  const rotateItems = () => {
    setPills((pills) => {
      const newPositions = pills.map((v) => {
        const position =
          v.position > (pills.length - 1) / 2
            ? -(pills.length / 2) + 1
            : v.position + 1
        const newItem = { ...v, position }
        return newItem
      })

      return newPositions
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // if(count===0) return
      rotateItems()
    }, 2000)
    return () => clearInterval(interval)
  }, [count])

  return (
    <BackgroundContainer>
      <PillContainer height={containerHeight}>
        {pills.map((v) => {
          return (
            <PillAnimationItem
              key={v.key}
              numberOfItems={(pills.length - 1) / 2}
              label={v.label}
              position={v.position}
              containerHeight={containerHeight}
            />
          )
        })}
      </PillContainer>
    </BackgroundContainer>
  )
}

interface IPillContainer {
  height: number
}

const PillContainer = styled.div<IPillContainer>`
  position: relative;
  height: ${(props) => `${props.height}px`};
  /* border: 1px solid red; */
  overflow: hidden;
  top: 39px;
  // margin-top:400px;
`

const BackgroundContainer = styled.div`
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDU4IiBoZWlnaHQ9IjQ1OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDI3LjI5NiAxMTQuMDVjLTIwLjQ3OS0zNS4wODMtNDguMjU2LTYyLjg2LTgzLjM0LTgzLjMzOEMzMDguODY0IDEwLjIzNiAyNzAuNTU4IDAgMjI5LjAwNiAwYy00MS41NDcgMC03OS44NjQgMTAuMjM2LTExNC45NDkgMzAuNzEyLTM1LjA4NiAyMC40NzUtNjIuODY0IDQ4LjI1Mi04My4zNDIgODMuMzM4QzEwLjIzNiAxNDkuMTQgMCAxODcuNDU2IDAgMjI5YzAgNDEuNTQ2IDEwLjI0IDc5Ljg1NyAzMC43MTIgMTE0Ljk0OCAyMC40NzggMzUuMDgzIDQ4LjI1NiA2Mi44NiA4My4zNDIgODMuMzRDMTQ5LjE0MyA0NDcuNzYzIDE4Ny40NTcgNDU4IDIyOS4wMDQgNDU4YzQxLjU0NSAwIDc5Ljg2NC0xMC4yMzcgMTE0Ljk1LTMwLjcxMiAzNS4wODUtMjAuNDcyIDYyLjg2MS00OC4yNTcgODMuMzM4LTgzLjM0QzQ0Ny43NjUgMzA4Ljg2IDQ1OCAyNzAuNTQ0IDQ1OCAyMjljLjAwMS00MS41NDgtMTAuMjM1LTc5Ljg2Ny0zMC43MDQtMTE0Ljk1em0tNTcuODUgMTk2LjM1Yy0xNC41MSAyNC44NDgtMzQuMTkgNDQuNTI2LTU5LjAzNyA1OS4wNDQtMjQuODQ3IDE0LjUxLTUxLjk4IDIxLjc1OS04MS40MDUgMjEuNzU5LTI5LjQxNyAwLTU2LjU1My03LjI0OC04MS40MDMtMjEuNzU5LTI0Ljg0Ny0xNC41MTgtNDQuNTI2LTM0LjE5Ni01OS4wMzYtNTkuMDQzQzc0LjA1IDI4NS41NTQgNjYuNzk2IDI1OC40MTkgNjYuNzk2IDIyOWMwLTI5LjQyMSA3LjI1OC01Ni41NTYgMjEuNzY5LTgxLjQwMyAxNC41MDctMjQuODQ3IDM0LjE5LTQ0LjUzIDU5LjAzNi01OS4wNCAyNC44NDctMTQuNTEgNTEuOTgyLTIxLjc2NCA4MS40MDMtMjEuNzY0IDI5LjQyNCAwIDU2LjU1OCA3LjI1MyA4MS40MDUgMjEuNzY1IDI0Ljg0OCAxNC41MSA0NC41MjcgMzQuMTkyIDU5LjAzNyA1OS4wNCAxNC41MTIgMjQuODQ2IDIxLjc2MyA1MS45OCAyMS43NjMgODEuNDAyIDAgMjkuNDItNy4yNTEgNTYuNTU0LTIxLjc2MyA4MS40eiIgZmlsbD0iIzAwQTNDNyIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+)
    no-repeat center;
  background-size: contain;
  height: 335px;
  width: 335px;

  @media (min-width: 1200px) {
    height: 458px;
    width: 458px;
  }
`
