import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

import Arrow from '../../images/nav-arrow.svg'

export interface AccordionProps {
  title: string
  body: string
  isFirstOpen?: boolean
}

const ImageAccordion: FunctionComponent<AccordionProps> = ({
  title,
  body,
  isFirstOpen,
}) => {
  const [isOpen, setIsOpen] = useState(isFirstOpen || false)

  function toggleOpen() {
    setIsOpen(!isOpen)
  }

  return (
    <div>
      {title && (
        <AccordionContainer>
          {title && (
            <AccordionTitleContainer onClick={toggleOpen}>
              <AccordionTitle>{title}</AccordionTitle>
              <StyledArrow src={Arrow} isOpen={isOpen} />
            </AccordionTitleContainer>
          )}
          {isOpen && <AccordionBody>{body}</AccordionBody>}
        </AccordionContainer>
      )}
    </div>
  )
}

export default ImageAccordion

interface StyledArrowInterface {
  isOpen: boolean
}

const StyledArrow = styled.img<StyledArrowInterface>`
  height: 14px;
  width: 15px;
  margin-left: 20px;

  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : '')};
`

const AccordionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
interface ICCContainer {
  isOpen: boolean
}
const AccordionContainer = styled.div`
  width: 100%;
  margin-top: -1px;
  cursor: pointer;

  border-top: 1px solid ${theme.grey.scroll};
  &:last-of-type {
    border-bottom: 1px solid ${theme.grey.scroll};
  }
`

const AccordionBody = styled.p`
  text-align: left;
  color: ${theme.text.main};
  width: 100%;
  cursor: default;
  font-weight: 300;
  // margin-bottom: 14px;

  padding-bottom: 20px;
`

const AccordionTitle = styled.h5`
  text-align: left;
  color: ${theme.brand.blue};
  margin: 18px 0;

  @media (min-width: 768px) {
    margin-bottom: 15px !important;
  }
`
