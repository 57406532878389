import React from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import MainGrid from '../styling/MainGrid'
import ArrowIcon from '../../images/percentage-arrow.svg'
import ArrowUpIcon from '../../images/percentages-up-arrow.svg'
import CornerRings from '../../images/corner-rings.svg'

const RiskReductionBanner = ({ data }: any) => {
  const bannerCards = [
    { 
      perc:        data.attrs['percentage-one'], 
      content:     data.attrs['content-one'],
      arrowToggle: data.attrs['toggle-arrow-one'],
    },
    { 
      perc:        data.attrs['percentage-two'], 
      content:     data.attrs['content-two'],
      arrowToggle: data.attrs['toggle-arrow-two'],
    },
    {
      perc:        data.attrs['percentage-three'],
      content:     data.attrs['content-three'],
      arrowToggle: data.attrs['toggle-arrow-three'],
    },
    {
      perc:        data.attrs['percentage-four'],
      content:     data.attrs['content-four'],
      arrowToggle: data.attrs['toggle-arrow-four'],
    },
    {
      perc:        data.attrs['percentage-five'],
      content:     data.attrs['content-five'],
      arrowToggle: data.attrs['toggle-arrow-five'],
    },
  ]
  return (
    <MainGrid>
      <Grid>
        {bannerCards.map((card, index) => (
          <BannerCard>
            <CardTitle>
              <CardPercentage>{card.perc}</CardPercentage>
              <Arrow
                src={card.arrowToggle === true ? ArrowUpIcon : ArrowIcon}
                alt="arrow icon"
              />
            </CardTitle>
            <CardContent>
              <CardCopy>{card.content}</CardCopy>
            </CardContent>
            {index === 2 ? (
              <ButtonContainer>
                <Button href={data.attrs['demo-link']}>Get a demo</Button>
              </ButtonContainer>
            ) : null}
          </BannerCard>
        ))}
      </Grid>
    </MainGrid>
  )
}

export default RiskReductionBanner

const Grid = styled.div`
  display: grid;
  padding: 50px 0;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  @media (min-width: 1200px) {
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
`
const BannerCard = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding-top: 40px;
  justify-content: start;
  &:nth-of-type(3) {
    background-color: #1d253c;
    background-image: url(${CornerRings});
    background-repeat: no-repeat;
    background-position: top right;
    grid-row: span 2;
    padding: 70px 30px 50px;
    justify-content: end;
    order: 5;
    @media (min-width: 1200px) {
      order: unset;
      padding-bottom: 70px;
    }
  }
  &:nth-of-type(3) p {
    color: white;
  }
  &:nth-of-type(3) div {
    flex-flow: column-reverse;
    align-items: start;
  }
  &:nth-of-type(3) img {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 20px;
  }
  &:nth-of-type(4),
  &:nth-of-type(5) {
    padding-bottom: 0;
  }
`

const CardTitle = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`
const CardContent = styled.div`
  padding-bottom: 20px;
`
const CardPercentage = styled.p`
  color: #00a3c7;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;

  @media (min-width: 1200px) {
    font-size: 70px;
    line-height: 86px;
  }
`
const CardCopy = styled.p`
  font-weight: 700;
`
const ButtonContainer = styled.div``

const Arrow = styled.img`
  max-width: 40px;
  max-height: 40px;
`
