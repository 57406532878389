import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import { appendUrlPartnerTracking } from '../../util/url'
import { RichTextContainer } from '../RichTextContainer/RichTextContainer'
import Video from '../shared/Video'

interface CardProps {
  content?: string
  primaryLabel?: string
  primaryURL?: string
  secondaryURL?: string
  secondaryLabel?: string
  videoUrl?: string
}

interface ButtonProps {
  url?: string
  text?: string
}
interface IGreyCards {
  cards: CardProps[]
}
export const GreyCardsRich = ({ cards }: IGreyCards) => {
  // console.log(cards)
  return (
    <Container>
      <MainGrid noPaddingMobile>
        <Grid>
          {cards.length > 0 &&
            cards.map((item: any, key: number) => {
              return (
                <Card key={'two-grey-cards-' + key}>
                    {item.videoUrl &&(
                      <VideoContainer>

                      <Video videoSrcURL={item.videoUrl} videoTitle={''} />
                      </VideoContainer>
                    )}  
                  {item.content && (
                    <RichTextContainer richText={item.content} />
                  )}

                  <ButtonContainer>
                    {item.primaryURL && (
                      <Button href={appendUrlPartnerTracking(item.primaryURL)}>
                        {item.primaryLabel}
                      </Button>
                    )}
                    {item.secondaryURL && (
                      <Button
                        href={appendUrlPartnerTracking(item.secondaryURL)}
                        secondary
                      >
                        {item.secondaryLabel}
                      </Button>
                    )}
                  </ButtonContainer>
                </Card>
              )
            })}
        </Grid>
      </MainGrid>
    </Container>
  )
}

const Container = styled.section`
  margin-bottom: 48px;
  /* border: 1px solid red; */
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  @media (min-width: 768px) {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
  }
`
const Card = styled.div`
  background: ${theme.background.grey};
  padding: 47px 20px 40px;
  @media (min-width: 768px) {
    border-radius: 4px;
  }
  @media (min-width: 1024px) {
    padding: 65px 64px 60px;
  }
`
const VideoContainer = styled.div`

width: 100%;



`
const Icon = styled.img`
  width: 100%;
  max-width: 170px;
  margin-bottom: 15px;
  margin-top: 26px;

  @media (min-width: 768px) {
    margin-top: 34px;
  }
`

const Section = styled.div`
  & h1,
  & h2,
  & h3 {
    margin-bottom: 21px;
  }

  & h4,
  & h5,
  & h6 {
    margin-bottom: 7px;
    @media (min-width: 768px) {
      margin-bottom: 14px;
    }
  }

  & h1::after,
  & h2::after,
  & h3::after {
    content: '';
    width: 162px;
    height: 3px;
    background: ${theme.text.main};
    display: block;
    margin-top: 14px;

    @media (min-width: 768px) {
      margin-bottom: 21px;
    }
  }
  & p {
    font-weight: 300;
  }
`
const Tag = styled.p`
  color: ${theme.grey.inputHover};
  margin-bottom: 26px;
  font-weight: 300;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 32px;
    max-width: 300px;
  }

  & a:first-of-type {
    margin-bottom: 11px;
  }
`
// const Video = styled.video`
//   max-width: 443px;
//   outline: none;
//   width: 100%;
//   height: 300px;
//   object-fit: cover;
//   margin-bottom: 34px;
//   @media (max-width: 767px) {
//     max-width: 100%;
//     margin-bottom: 50px;
//   }
// `
