import React from 'react'
import ServiceHeader from '../Header/ServiceHeader'
import TrackVisibility from 'react-on-screen'
import { parse } from '@wordpress/block-serialization-default-parser'
import RingArrangement from '../ContinuousLearningAnimation/RingArrangement'
import SmallCardsGrid from '../SmallCardsGrid/SmallCardsGrid'
import ThreeColumnText from '../ThreeColumnText/ThreeColumnText'
import ProductSubPageHeader from '../Header/ProductSubPageHeader'
import ButtonPair from '../shared/ButtonPair'
import TextDetails from '../TextDetails/TextDetails'
import ThreeCardsLazyBlock from '../TrainYourEmployees/ThreeCardsLazyBlock'
import ImageText from '../ImageText/ImageText'
import ProductVideoCard from '../ProductVideoCard/ProductVideoCard'
import UploadAnimation from '../UploadAnimation/UploadAnimation'
import BusinessImprovement from '../BusinessImprovement/BusinessImprovement'
import PhishingEmailAnimationSec from '../PhishingEmailAnimation/PhishingEmailAnimationSec'
import CultureCycle from '../CultureCycle/CultureCycle'
import ImageAccordion from '../ImageAccordian/ImageAccordian'
import { GreyCardsRich } from '../GreyCards/GreyCardsRich'
import WhiteCardsLazyBlock from '../WhiteCards/WhiteCardsLazyBlock'
import TabbedInfo from '../TabbedInfo/TabbedInfo'
import TrainingAnimation from '../TrainingAnimation/TrainingAnimation'
// import protectImg from '../../images/protect-your-business.png'
import EmpowerCards from '../Empower/EmpowerCards'
import { IncludedIn } from '../IncludedIn/IncludedIn'
import Quote from '../Quote/Quote'
import TwoGreyLoginCards from '../TwoGreyloginCards/TwoGreyLogInCards'

import GetStartedBanner from '../GetStartedBanner/GetStartedBanner'

import VideoFlex from '../VideoFlex/VideoFlex'

import PricingCards from '../PricingCards/PricingCards'
import PricingGridHeader from '../PricingGrid/PricingGridHeader'
import PricingGrid from '../PricingGrid/PricingGrid'
import BespokePricing from '../BespokePricing/BespokePricing'
import QandA from '../Q&A/QandA'
import { RichTextContainer } from './RichTextContainer'
import SEO from '../seo'
import { CareerListing } from '../CareerListing'
import ProductsCards from '../ProductsCards/ProductsCards'
import TheCourseNumbers from '../TheCourseNumbers/TheCourseNumbers'
import CoursePagination from '../CoursePagination/CoursePagination'
import UseCaseImageText from '../UseCases/UseCasesImageText'
import { VideoLazyBlock } from './VideoLazyBlock'

import { graphql, useStaticQuery } from 'gatsby'
import Nikos_test from '../Nikos_test/Nikos_test'
import TrainingCards from '../TrainingCards/TrainingCards'
import RiskReductionBanner from '../RiskReductionBanner/RiskReductionBanner'
import LandingPageHeaderVOne from '../LandingPagesHeaders/LandingPageHeaderVOne'
import LandingPageHeaderVTwo from '../LandingPagesHeaders/LandingPageHeaderVTwo'
import LandingPageHeaderVThree from '../LandingPagesHeaders/LandingPageHeaderVThree'
import LandingPageHeaderVFour from '../LandingPagesHeaders/LandingPageHeaderVFour'
import IconsRow from '../IconsRow/IconsRow'
import DownloadGuideBanner from '../DownloadGuideBanner/DownloadGuideBanner'
import DownloadGuideBannerNoMargin from '../DownloadGuideBanner/DownloadGuideBannerNoMargin'
import LandingPagesImageAccordion from '../LandingPagesImageAccordion/LandingPagesImageAccordion'
import LandingPagePricingSlider from '../LandingPagePricingSlider/LandingPagePricingSlider'
import ImageWithTextBox from '../ImageWithTextBox/ImageWithTextBox'
import CompareTable from '../CompareTable/CompareTable'
import IsolatedArticlesGrid from '../IsolatedArticlesGrid'
import DoubleSectionWiring from '../DoubleSectionWiring/DoubleSectionWiring'
import LeftToRightWiring from '../LeftToRightWiring/LeftToRightWiring'
import { InPageBot, VisualBot } from "@leadoo/react-bot";

export const LazyBlocksFactory = (
  lazyBlocksInfo: any,
  pageTitle: string,
  featImg?: any
) => {
  const lazyBlocks: any = parse(lazyBlocksInfo)



  const seo = lazyBlocks.map((v) => {
    return v.blockName === 'lazyblock/seo'
  })

  if (seo.length > 0) {
    // console.log('There is a seo block')
  } else {
    // console.log('There is no seo block')
    lazyBlocks.push({
      blockName: 'lazyblock/seo',
      attrs: {},
    })
  }

  return LazyBlocksFactoryNoParse(lazyBlocks, pageTitle, featImg)
}

export const LazyBlocksFactoryNoParse = (
  lazyBlocks: any,
  pageTitle: string,
  featImg?: any
) => {
  const imagesCollection = useStaticQuery(graphql`
    query img9 {
      protectImg: allFile(
        filter: {
          childrenImageSharp: {
            elemMatch: {
              fluid: { originalName: { eq: "protect-your-business.png" } }
            }
          }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660, toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const protectImg =
    imagesCollection.protectImg.edges[0].node.childImageSharp.fluid.src
  const blocks = lazyBlocks.map((block, index) => {
    switch (block.blockName) {
      case 'lazyblock/service-header':
        return (
          <ServiceHeader
            breadcrumb={pageTitle}
            key={'lazy-block-product-header-' + index}
            tag={block.attrs.subtitle}
            title={block.attrs.title}
            ctaText1={block.attrs['primary-button-text']}
            ctaUrl1={block.attrs['primary-button-url']}
            ctaText2={block.attrs['secondary-button-text']}
            ctaUrl2={block.attrs['secondary-button-url']}
            culture={block.attrs['included-in']}
            phishing={block.attrs['included-in-bobs-phishing']}
            compliance={block.attrs['included-in-bobs-compliance']}
            animationType={block.attrs['animation-type']}
          />
        )

      case 'lazyblock/secondary-header':
        return (
          <ProductSubPageHeader
            breadcrumb={pageTitle}
            key={'lazy-block-product-header-' + index}
            smallText={block.attrs['tag']}
            heading={block.attrs['main-heading']}
            subheading={block.attrs['sub-heading']}
            ctaText1={block.attrs['primary-button-text']}
            ctaUrl1={block.attrs['primary-button-url']}
            ctaText2={block.attrs['secondary-button-text']}
            ctaUrl2={block.attrs['secondary-button-url']}
            productLogo={block.attrs['top-logo']}
          />
        )

      case 'lazyblock/title-subheading-body':
        return (
          <TextDetails
            key={'title-subheading-body-' + index}
            tag={block.attrs.title}
            heading={block.attrs.subtitle}
            content={block.attrs.body}
            alignCenter={block.attrs['align-center']}
          />
        )

      case 'lazyblock/button-pair':
        return (
          <ButtonPair
            key={'button-pair-' + index}
            btns={[
              {
                title: block.attrs['button-primary-title'],
                url: block.attrs['primary-button-url'],
              },
              {
                title: block.attrs['secondary-button-title'],
                url: block.attrs['secondary-button-url'],
              },
            ]}
          />
        )

      case 'lazyblock/two-grey-cards-rich':
        return (
          <GreyCardsRich
            cards={[
              {
                content: block.attrs['left-contents'],
                primaryLabel: block.attrs['left-primary-button-label'],
                primaryURL: block.attrs['left-primary-button-url'],
                secondaryURL: block.attrs['left-secondary-button-url'],
                secondaryLabel: block.attrs['left-secondary-button-label'],
              },
              {
                content: block.attrs['right-contents'],
                primaryLabel: block.attrs['right-primary-button-label'],
                primaryURL: block.attrs['right-primary-button-url'],
                secondaryURL: block.attrs['right-secondary-button-url'],
                secondaryLabel: block.attrs['right-secondary-button-label'],
                videoUrl: block.attrs['video'],
              },
            ]}
          />
        )
      case 'lazyblock/ring-arrangement':
        return (
          <RingArrangement
            card1Body={block.attrs['card-1-body']}
            card1Title={block.attrs['card-1-title']}
            card2Body={block.attrs['card-2-body']}
            card2Title={block.attrs['card-2-title']}
            card3Body={block.attrs['card-3-body']}
            card3Title={block.attrs['card-3-title']}
            centerImage={block.attrs['center-image']}
            centerText={block.attrs['center-text']}
            image1={block.attrs['image-1']}
            image2={block.attrs['image-2']}
            image3={block.attrs['image-3']}
          />
        )
      case 'lazyblock/training-animation':
        return (
          <TrainingAnimation
            tag={block.attrs.tag}
            heading={block.attrs.heading}
            content={block.attrs.content}
            imgSrc={block.attrs.image}
            animationHeading={block.attrs['animation-heading']}
            animationContent={block.attrs['animation-content']}
          />
        )
      case 'lazyblock/empower-graphic-with-cards':
        return (
          <TrackVisibility partialVisibility offset={50}>
            <EmpowerCards
              background="white"
              cardsContent={[
                {
                  title: block.attrs['card-1-title'],
                  body: block.attrs['card-1-body'],
                },
                {
                  title: block.attrs['card-2-title'],
                  body: block.attrs['card-2-body'],
                },
                {
                  title: block.attrs['card-3-title'],
                  body: block.attrs['card-3-body'],
                },
              ]}
              img={protectImg}
              heading={block.attrs.heading}
            />
          </TrackVisibility>
        )
      case 'lazyblock/three-cards':
        return (
          <ThreeCardsLazyBlock
            data={[
              {
                icon: block.attrs['card-1-image'],
                heading: block.attrs['card-1-title'],
                tag: block.attrs['card-1-tag'],
                body: block.attrs['card-1-body'],
                link: block.attrs['card-1-link'],
              },
              {
                icon: block.attrs['card-2-image'],
                heading: block.attrs['card-2-title'],
                tag: block.attrs['card-2-tag'],
                body: block.attrs['card-2-body'],
                link: block.attrs['card-2-link'],
              },
              {
                icon: block.attrs['card-3-image'],
                heading: block.attrs['card-3-title'],
                tag: block.attrs['card-3-tag'],
                body: block.attrs['card-3-body'],
                link: block.attrs['card-3-link'],
              },
            ]}
          />
        )
      case 'lazyblock/included-in':
        return (
          <IncludedIn
            culture={block.attrs['included-in-bobs-culture']}
            phishing={block.attrs['included-in-bobs-phishing']}
            compliance={block.attrs['included-in-bobs-compliance']}
          />
        )
      case 'lazyblock/image-with-text':
        return (
          <ImageText
            Content={block.attrs['content']}
            LeftImage={block.attrs['align-image-left']}
            Image={block.attrs['main-image']}
          />
        )
      case 'lazyblock/use-case-image-with-text':
        return (
          <UseCaseImageText
            Content={block.attrs['content']}
            Image={block.attrs['main-image']}
          />
        )
      case 'lazyblock/quote':
        return (
          <Quote
            body={block.attrs['quote']}
            AuthorName={block.attrs['author-name']}
            AuthorPosition={block.attrs['author-position']}
            AuthorImage={block.attrs['author-image']}
          />
        )
      case 'lazyblock/six-cards-grid':
     
        return (
          <SmallCardsGrid
            leftAlign={block.attrs['align-items-left']}
            cardsContent={[
              {
                image: block.attrs['card-1-image'],
                title: block.attrs['card-1-title'],
                tag: block.attrs['card-1-tag'],
                body: block.attrs['card-1-body'],
                ctaUrl: block.attrs['card-1-cta'],
              },
              {
                image: block.attrs['card-2-image'],
                title: block.attrs['card-2-title'],
                body: block.attrs['card-2-body'],
                tag: block.attrs['card-2-tag'],
                ctaUrl: block.attrs['card-2-cta'],
              },
              {
                image: block.attrs['card-3-image'],
                title: block.attrs['card-3-title'],
                body: block.attrs['card-3-body'],
                tag: block.attrs['card-3-tag'],
                ctaUrl: block.attrs['card-3-cta'],
              },
              {
                image: block.attrs['card-4-image'],
                title: block.attrs['card-4-title'],
                body: block.attrs['card-4-body'],
                tag: block.attrs['card-4-tag'],
                ctaUrl: block.attrs['card-4-cta'],
              },
              {
                image: block.attrs['card-5-image'],
                title: block.attrs['card-5-title'],
                body: block.attrs['card-5-body'],
                tag: block.attrs['card-5-tag'],
                ctaUrl: block.attrs['card-5-cta'],
              },
              {
                image: block.attrs['card-6-image'],
                title: block.attrs['card-6-title'],
                body: block.attrs['card-6-body'],
                tag: block.attrs['card-6-tag'],
                ctaUrl: block.attrs['card-6-cta'],
              },
            ]}
          />
        )
      case 'lazyblock/four-cards-grid':
        return (
          <SmallCardsGrid
            columns={4}
            leftAlign={block.attrs['align-items-left']}
            cardsContent={[
              {
                image: block.attrs['card-1-image'],
                title: block.attrs['card-1-title'],
                body: block.attrs['card-1-body'],
                ctaUrl: block.attrs['card-1-cta'],
                icon: block.attrs['card-1-bottom-icon'],
              },
              {
                image: block.attrs['card-2-image'],
                title: block.attrs['card-2-title'],
                body: block.attrs['card-2-body'],
                ctaUrl: block.attrs['card-2-cta'],
                icon: block.attrs['card-2-bottom-icon'],
              },
              {
                image: block.attrs['card-3-image'],
                title: block.attrs['card-3-title'],
                body: block.attrs['card-3-body'],
                ctaUrl: block.attrs['card-3-cta'],
                icon: block.attrs['card-3-bottom-icon'],
              },
              {
                image: block.attrs['card-4-image'],
                title: block.attrs['card-4-title'],
                body: block.attrs['card-4-body'],
                ctaUrl: block.attrs['card-4-cta'],
                icon: block.attrs['card-4-bottom-icon'],
              },
            ]}
          />
        )

      case 'lazyblock/three-column-text':
        return (
          <ThreeColumnText
            tag={block.attrs['main-tag']}
            title={block.attrs['main-title']}
            paragraph={[
              {
                heading: block.attrs['item-1-title'],
                body: block.attrs['item-1-body'],
              },
              {
                heading: block.attrs['item-2-title'],
                body: block.attrs['item-2-body'],
              },
              {
                heading: block.attrs['item-3-title'],
                body: block.attrs['item-3-body'],
              },
              {
                heading: block.attrs['item-4-title'],
                body: block.attrs['item-4-body'],
              },
              {
                heading: block.attrs['item-5-title'],
                body: block.attrs['item-5-body'],
              },
              {
                heading: block.attrs['item-6-title'],
                body: block.attrs['item-6-body'],
              },
            ]}
          />
        )
      case 'lazyblock/login-cards':
        return (
          <TwoGreyLoginCards
            cards={[
              {
                tag: block.attrs['sign-up-tag'],
                header: block.attrs['sign-up-header'],
                body: block.attrs['sign-up-body'],
                buttonText: block.attrs['sign-up-button-text'],
                buttonURL: block.attrs['sign-up-button-url'],
              },
              {
                tag: block.attrs['sign-in-tag'],
                header: block.attrs['sign-in-header'],
                body: block.attrs['sign-in-body'],
                buttonText: block.attrs['sign-in-button-text'],
                buttonURL: block.attrs['sign-in-button-url'],
              },
            ]}
          />
        )

      case 'lazyblock/double-white-cards':
        return (
          <WhiteCardsLazyBlock
            background={block.attrs.background}
            heading={block.attrs.heading}
            tag={block.attrs.tag}
            subheading={block.attrs.subheading}
            contentLeft={block.attrs['content-left']}
            contentRight={block.attrs['content-right']}
          />
        )

      case 'lazyblock/get-started-banner':
        return (
          <GetStartedBanner
            heading={block.attrs['heading']}
            subHeading={block.attrs['sub-heading']}
            body={block.attrs['body']}
            btnUrl={block.attrs['button-url']}
            btnTitle={block.attrs['button-text']}
          />
        )

      case 'lazyblock/upload-animation':
        return (
          <UploadAnimation
            icon={block.attrs['icon']}
            title={block.attrs['title']}
            paragraph={block.attrs['body']}
          />
        )

      case 'lazyblock/video-with-title':
        return (
          <VideoFlex
            heading={block.attrs['title']}
            content={block.attrs['video']}
          />
        )

      case 'lazyblock/arrow-composition':
        return (
          <BusinessImprovement
            heading={block.attrs['heading']}
            body={block.attrs['body']}
            blueText={block.attrs['blue-text']}
            greyText={block.attrs['grey-text']}
          />
        )

      case 'lazyblock/phishing-email-animation':
        return (
          <PhishingEmailAnimationSec
            heading1={block.attrs['heading-1']}
            heading2={block.attrs['heading-2']}
            heading3={block.attrs['heading-3']}
            subHeading2={block.attrs['sub-heading-2']}
            subHeading3={block.attrs['sub-heading-3']}
            body2={block.attrs['body-2']}
            body3={block.attrs['body-3']}
            image={block.attrs['image']}
          />
        )
      case 'lazyblock/info-tabs':
        return (
          <TabbedInfo
            title1={block.attrs['title-tab-1']}
            title2={block.attrs['title-tab-2']}
            title3={block.attrs['title-tab-3']}
            title4={block.attrs['title-tab-4']}
            leftColumn1={block.attrs['left-column-1']}
            leftColumn2={block.attrs['left-column-2']}
            leftColumn3={block.attrs['left-column-3']}
            leftColumn4={block.attrs['left-column-4']}
            rightColumn1={block.attrs['right-column-1']}
            rightColumn2={block.attrs['right-column-2']}
            rightColumn3={block.attrs['right-column-3']}
            rightColumn4={block.attrs['right-column-4']}
            ctaText1={block.attrs['primary-button-text']}
            ctaText2={block.attrs['secondary-button-text']}
            ctaUrl1={block.attrs['primary-button-url']}
            ctaUrl2={block.attrs['secondary-button-url']}
            image1={block.attrs['image-1']}
            image2={block.attrs['image-2']}
            image3={block.attrs['image-3']}
            image4={block.attrs['image-4']}
          />
        )

      case 'lazyblock/steps-cycle':
        return (
          <CultureCycle
            image={block.attrs['image']}
            content={[
              {
                heading: block.attrs['step-1-title'],
                body: block.attrs['step-1-body'],
              },
              {
                heading: block.attrs['step-2-title'],
                body: block.attrs['step-2-body'],
              },
              {
                heading: block.attrs['step-3-title'],
                body: block.attrs['step-3-body'],
              },
              {
                heading: block.attrs['step-4-title'],
                body: block.attrs['step-4-body'],
              },
              {
                heading: block.attrs['step-5-title'],
                body: block.attrs['step-5-body'],
              },
              {
                heading: block.attrs['step-6-title'],
                body: block.attrs['step-6-body'],
              },
            ]}
          />
        )

      case 'lazyblock/product-video-card':
        return (
          <ProductVideoCard
            tag={block.attrs['tag']}
            heading={block.attrs['main-heading']}
            rightColumn={block.attrs['right-column-content']}
            videoLink={block.attrs['video']}
          />
        )

      case 'lazyblock/image-with-accordion':
        return (
          <ImageAccordion
            Image={block.attrs['image']}
            featuredImage={block.attrs['featured-image']}
            mainText={block.attrs['main-text']}
            imageLeft={block.attrs['image-left']}
            AccordionContent={[
              {
                title: block.attrs['accordion-title-1'],
                body: block.attrs['accordion-body-1'],
              },
              {
                title: block.attrs['accordion-title-2'],
                body: block.attrs['accordion-body-2'],
              },
              {
                title: block.attrs['accordion-title-3'],
                body: block.attrs['accordion-body-3'],
              },
              {
                title: block.attrs['accordion-title-4'],
                body: block.attrs['accordion-body-4'],
              },
              {
                title: block.attrs['accordion-title-5'],
                body: block.attrs['accordion-body-5'],
              },
              {
                title: block.attrs['accordion-title-6'],
                body: block.attrs['accordion-body-6'],
              },
            ]}
          />
        )

      case 'lazyblock/pricing-cards':
        return (
          <PricingCards
            pricingIntro={block.attrs['pricing-info']}
            complianceTitle={block.attrs['compliance-card-title']}
            complianceBody={block.attrs['compliance-card-body']}
            complianceMonthlyPrice={block.attrs['compliance-monthly-price']}
            complianceAnnualPrice={block.attrs['compliance-annual-price']}
            cultureTitle={block.attrs['culture-title']}
            cultureBody={block.attrs['culture-body']}
            phishingTitle={block.attrs['phishing-title']}
            phishingBody={block.attrs['phishing-body']}
            btns={[
              {
                url: block.attrs['compliance-button-url'],
                title: block.attrs['compliance-button-title'],
              },
              {
                url: block.attrs['compliance-get-in-touch-url'],
                title: block.attrs['compliance-get-in-touch-title'],
              },
              {
                url: block.attrs['culture-button-url'],
                title: block.attrs['culture-button-title'],
              },
              {
                url: block.attrs['culture-get-in-touch-url'],
                title: block.attrs['culture-get-in-touch-title'],
              },
              {
                url: block.attrs['phishing-button-url'],
                title: block.attrs['phishing-button-title'],
              },
              {
                url: block.attrs['phishing-get-in-touch-url'],
                title: block.attrs['phishing-get-in-touch-title'],
              },
            ]}
          />
        )

      case 'lazyblock/pricing-grid-section-header':
        return <PricingGridHeader sectionHeading={block.attrs['heading']} />

      case 'lazyblock/pricing-grid-item':
        return (
          <PricingGrid
            includedParagraph={block.attrs['item-heading']}
            useComplianceText={block.attrs['use-compliance-text']}
            complianceText={block.attrs['compliance-text']}
            useCultureText={block.attrs['use-culture-text']}
            cultureText={block.attrs['culture-text']}
            usePhishingText={block.attrs['use-phishing-text']}
            phishingText={block.attrs['phishing-text']}
            compliance={block.attrs['included-in-compliance']}
            culture={block.attrs['included-in-culture']}
            phishing={block.attrs['included-in-phishing']}
            helpText={block.attrs['help-text']}
          />
        )

      case 'lazyblock/bespoke-pricing':
        return (
          <BespokePricing
            item1={block.attrs['item-1']}
            item2={block.attrs['item-2']}
            item3={block.attrs['item-3']}
            heading={block.attrs['heading']}
            body={block.attrs['body']}
            ctaText1={block.attrs['primary-button-text']}
            ctaText2={block.attrs['secondary-button-text']}
            ctaUrl1={block.attrs['primary-button-url']}
            ctaUrl2={block.attrs['secondary-button-url']}
          />
        )

      case 'lazyblock/q-and-a':
        return (
          <QandA
            header={block.attrs['header']}
            AccordionContent={[
              {
                title: block.attrs['accordion-title-1'],
                body: block.attrs['accordion-body-1'],
              },
              {
                title: block.attrs['accordion-title-2'],
                body: block.attrs['accordion-body-2'],
              },
              {
                title: block.attrs['accordion-title-3'],
                body: block.attrs['accordion-body-3'],
              },
              {
                title: block.attrs['accordion-title-4'],
                body: block.attrs['accordion-body-4'],
              },
              {
                title: block.attrs['accordion-title-5'],
                body: block.attrs['accordion-body-5'],
              },
              {
                title: block.attrs['accordion-title-6'],
                body: block.attrs['accordion-body-6'],
              },
            ]}
          />
        )

      case 'lazyblock/product-cards':
        return (
          <ProductsCards
            topHeadingMarginBottom={'100px'}
            data={[
              {
                title: block.attrs['card-1-title'],
                body: block.attrs['card-1-body'],
                topIcon: block.attrs['card-1-image'],
                topIconAlt: block.attrs['title'],
                ctaUrl: block.attrs['card-1-url'],
              },
              {
                title: block.attrs['card-2-title'],
                body: block.attrs['card-2-body'],
                topIcon: block.attrs['card-2-image'],
                topIconAlt: block.attrs['card-2-title'],
                ctaUrl: block.attrs['card-2-url'],
              },
              {
                title: block.attrs['card-3-title'],
                body: block.attrs['card-3-body'],
                topIcon: block.attrs['card-3-image'],
                topIconAlt: block.attrs['card-3-title'],
                ctaUrl: block.attrs['card-3-url'],
              },
              {
                title: block.attrs['card-4-title'],
                body: block.attrs['card-4-body'],
                topIcon: block.attrs['card-4-image'],
                topIconAlt: block.attrs['card-4-title'],
                ctaUrl: block.attrs['card-4-url'],
              },
            ]}
          />
        )

      case 'lazyblock/course-number-cards':
        return (
          <TheCourseNumbers
            topHeading={block.attrs['top-heading']}
            heading={block.attrs['heading']}
            cards={[
              {
                number: block.attrs['card-1-top-number'],
                heading: block.attrs['card-1-heading'],
                body: block.attrs['card-1-body'],
              },
              {
                number: block.attrs['card-2-top-number'],
                heading: block.attrs['card-2-heading'],
                body: block.attrs['card-2-body'],
              },
              {
                number: block.attrs['card-3-top-number'],
                heading: block.attrs['card-3-heading'],
                body: block.attrs['card-3-body'],
              },
              {
                number: block.attrs['card-4-top-number'],
                heading: block.attrs['card-4-heading'],
                body: block.attrs['card-4-body'],
              },
            ]}
          />
        )

      case 'lazyblock/get-in-touch-links':
        return (
          <ThreeColumnText
            tag={block.attrs['main-tag']}
            title={block.attrs['main-title']}
            paragraph={[
              {
                heading: block.attrs['item-1-title'],
                body: block.attrs['item-1-body'],
                link: block.attrs['item-1-links'],
              },
              {
                heading: block.attrs['item-2-title'],
                body: block.attrs['item-2-body'],
                link: block.attrs['item-2-links'],
              },
              {
                heading: block.attrs['item-3-title'],
                body: block.attrs['item-3-body'],
                link: block.attrs['item-3-links'],
              },
              {
                heading: block.attrs['item-4-title'],
                body: block.attrs['item-4-body'],
                link: block.attrs['item-4-links'],
              },
            ]}
          />
        )
      case 'lazyblock/career-listing':
        return <CareerListing data={block} />

      case 'lazyblock/course-pages-navigation':
        return (
          <CoursePagination
            prevLink={block.attrs['previous-page-url']}
            prevTitle={block.attrs['previous-page-title']}
            nextLink={block.attrs['next-page-url']}
            nextTitle={block.attrs['next-page-title']}
          />
        )
      case 'core/heading':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/separator':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/paragraph':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/html':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/list':
        return (
          <span>
          {block.attrs.ordered && block.innerBlocks.length > 0 ?
            <ol>
            {block.innerBlocks?.map((insideBlock, index) => {
              return <RichTextContainer richText={insideBlock.innerHTML} />
            })}
            </ol> : !block.attrs.ordered && block.innerBlocks.length > 0 ?
            <ul>
            {block.innerBlocks?.map((insideBlock, index) => {
              return <RichTextContainer richText={insideBlock.innerHTML} />
            })}
            </ul> : <RichTextContainer richText={block.innerHTML} />
            }
          </span>
        )
      case 'core/classic':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/image':
        return <RichTextContainer richText={block.innerHTML} />
      case 'core/embed':
        return <VideoLazyBlock data={block} />
      case 'lazyblock/seo':
        return <SEO data={block} featImg={featImg} />
      case 'lazyblock/leadoo-bot':
        return <InPageBot code={block.attrs.botcode}/>
      case 'lazyblock/leadoo-visual-bot':
        return <VisualBot code={block.attrs.botcode}/>
      case 'lazyblock/nikos-test-component':
        return <Nikos_test data={block.inn} />
      case 'lazyblock/training-cards':
        return <TrainingCards data={block} />
      case 'lazyblock/risk-reduction-banner':
        return <RiskReductionBanner data={block} />
      case 'lazyblock/landing-page-header-v1':
        return <LandingPageHeaderVOne data={block} />
      case 'lazyblock/landing-page-header-v2':
        return <LandingPageHeaderVTwo data={block} />
      case 'lazyblock/landing-page-header-v3':
        return <LandingPageHeaderVThree data={block} />
      case 'lazyblock/landing-page-header-v4':
        return <LandingPageHeaderVFour data={block} />
      case 'lazyblock/icons-row':
        return <IconsRow data={block} />
      case 'lazyblock/download-guide-banner':
        return <DownloadGuideBanner data={block} />
      case 'lazyblock/download-guide-banner-no-margin':
        return <DownloadGuideBannerNoMargin data={block} />
      case 'lazyblock/landing-page-faqs':
        return <LandingPagesImageAccordion data={block} />
      case 'lazyblock/landing-page-pricing-slider':
        return <LandingPagePricingSlider data={block} />
      case 'lazyblock/image-with-text-box':
        return <ImageWithTextBox data={block} />
      case 'lazyblock/landing-page-blogs-grid':
        return <IsolatedArticlesGrid data={block} />
      case 'lazyblock/compare-table':
        return <CompareTable data={block} />
      case 'lazyblock/double-section-wiring':
        return <DoubleSectionWiring data={block} />
      case 'lazyblock/left-to-right-wiring':
        return <LeftToRightWiring data={block} />
      case null:
        return <RichTextContainer richText={'<br/>'} />
      default:
        // code block - uncomment to figure out block name
        // console.log(block.blockName);
        break
    }
  })

  return blocks
}
