import React, { useRef } from 'react'
import styled from 'styled-components'
import SmallGrid from '../styling/SmallGrid'
import theme from '../styling/theme'

const Nikos_test = ({ data }: any) => {
  const scrollArr = [
    { value: 'Malware' },
    { value: 'Phishing' },
    { value: 'Data breach' },
    { value: 'Remote working' },
    { value: 'Data breaching' },
  ]

  const scrollItems = useRef(scrollArr.map(() => React.createRef()))

  const scroll = () => {
    console.log('scrolling')
    scrollItems.current.map((refItem, i) => console.log(refItem.current))
  }

  return (
    <SmallGrid>
      Nikos_test
      <h2>{data?.attrs.title}</h2>
      <ScrollerWrapper>
        {scrollArr.map((data, i) => (
          <ScrollerItem
            onClick={scroll}
            ref={scrollItems.current[i]}
            key={data.value}
          >
            {data.value}
          </ScrollerItem>
        ))}
      </ScrollerWrapper>
    </SmallGrid>
  )
}

export default Nikos_test

const ScrollerWrapper = styled.div`
  display: flex;
  flex-flow: column;

  max-height: 380px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollerItem = styled.div`
  color: ${theme.text.main};
  text-align: center;
  background: white;
  padding: 10px 20vw;
  width: 100%;
  border: 1px solid grey;
  border-radius: 30px;
  font-weight: 700;
  z-index: 50;
  margin-bottom: 10px;

  @media (min-width: 1200px) {
    padding: 15px 90px;
    margin-bottom: 20px;
  }

  &:nth-of-type(3) {
    color: white;
    background: ${theme.text.main};
    border-color: ${theme.text.main};
  }
`
